import { EarlyAccessFeature } from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";

import {
  useExperimentalFeatures,
  usePostExperimentalFeatureEnrollment,
} from "@/hooks/queries/features";
import { AppLink } from "@/hooks/queries/team";

import CanisterCreationWizard from "./canister-create/canister-creation-wizard";
import useCanisterCreationStore from "./canister-create/canister-creation-wizard-store";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Switch } from "./ui/switch";

export function ExperimentalFeatures() {
  const features = useExperimentalFeatures();

  return (
    <div className="flex flex-col gap-2">
      {features.data?.map((feature) => (
        <FeatureToggle key={feature.flagKey} feature={feature} />
      ))}
      <ExperimentalCanisterCreation />
    </div>
  );
}

function ExperimentalCanisterCreation() {
  const {
    dialog: { open },
  } = useCanisterCreationStore();
  const flag = useFeatureFlagEnabled("canister-creation-internal");
  if (!flag) return null;
  return (
    <Card className="flex flex-col gap-2 p-4 border">
      <span>Canister Creation</span>
      <div className="text-sm text-muted-foreground">
        Experimental feature allowing you to create canisters in CycleOps.
      </div>
      <CanisterCreationWizard />
      <Button className="self-start mt-2" onClick={() => open()}>
        Create canister
      </Button>
    </Card>
  );
}

function FeatureToggle({ feature }: { feature: EarlyAccessFeature }) {
  const mutation = usePostExperimentalFeatureEnrollment();
  const flag = useFeatureFlagEnabled(feature.flagKey!);

  const handleToggle = (key: string | null) => {
    mutation.mutate({ name: feature.flagKey!, active: !flag });
  };

  return (
    <Card className="flex flex-col space-y-2 p-4 border">
      <div className="flex items-center justify-between ">
        <span>{feature.name}</span>
        <Switch
          checked={flag}
          onCheckedChange={() => handleToggle(feature.flagKey)}
        />
      </div>
      <div className="text-sm text-muted-foreground">{feature.description}</div>
    </Card>
  );
}
