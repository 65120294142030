import { Principal } from "@dfinity/principal";
import { KeyRound, UserRound, UsersRound } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCurrentTeamQuery, useTeamMembersQuery } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import useCanisterCreationStore from "../canister-creation-wizard-store";

export default function ControllerAssignment() {
  return (
    <div className="flex flex-col gap-6 h-full">
      <CustomControllersForm />
      <div className="flex-1 h-full">
        <Controllers />
      </div>
      <div className="flex gap-2 items-center">
        <Submit />
        <Back />
      </div>
    </div>
  );
}

function Submit() {
  const machine = useCanisterCreationStore((s) => s.machine);
  return (
    <Button
      size="lg"
      className="w-full max-w-[200px] h-12"
      onClick={machine.next}
    >
      Continue
    </Button>
  );
}

function Back() {
  const machine = useCanisterCreationStore((s) => s.machine);
  return (
    <Button size="lg" className="h-12" variant="outline" onClick={machine.back}>
      Back
    </Button>
  );
}

function CustomControllersForm() {
  const controllers = useCanisterCreationStore((s) => s.controllerAssignment);
  const [input, setInput] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(() => {
    setError(null);
    if (!input) return;
    try {
      const principal = Principal.fromText(input);
      controllers.setOthers([...controllers.others, principal]);
      setInput("");
    } catch (e) {
      setError("Invalid Principal");
    }
  }, [input]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
    setError(null);
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm">Add Controllers</div>
      <div className="flex flex-col gap-1">
        <form
          className="flex gap-2"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Input
            value={input}
            onChange={handleChange}
            className=""
            placeholder="Ex. your dfx principal"
          />
          <Button type="submit">Add</Button>
        </form>
        {error && (
          <div className="text-xs italic text-destructive">{error}</div>
        )}
      </div>
    </div>
  );
}

function Row({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <div
      className="flex gap-2 px-2 h-10 items-center w-full border-b border-border/50 hover:bg-muted/25 transition-colors"
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function IconCell({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-6 h-6 border border-muted rounded-full flex items-center justify-center">
      {children}
    </div>
  );
}

function PrincipalCell({ principal }: { principal: Principal }) {
  return (
    <div className="flex-1 px-4 pt-1 font-mono text-xs">
      {principal.toText().slice(0, 23)}...
    </div>
  );
}

function LabelCell({ children }: { children: React.ReactNode }) {
  return (
    <div className="font-normal text-sm flex items-baseline gap-2 cursor-default">
      {children}
    </div>
  );
}

function Selected({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Checkbox>) {
  return <Checkbox className={cn("w-5 h-5", className)} {...props} />;
}

function TeamMembersRows() {
  const { principal } = useIdp();
  const controllers = useCanisterCreationStore((s) => s.controllerAssignment);
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);

  const clickHandler = useCallback(
    (p: Principal) => {
      return () => {
        if (
          controllers.teamMembers.map((x) => x.toText()).includes(p.toText())
        ) {
          return controllers.setTeamMembers(
            controllers.teamMembers.filter((x) => x.toText() !== p.toText())
          );
        }
        return controllers.setTeamMembers([...controllers.teamMembers, p]);
      };
    },
    [controllers.teamMembers]
  );

  if (!team) return null;

  return (
    <>
      {members.data
        ?.filter((m) => ![principal.toText()].includes(m.principal.toText()))
        .map((member) => (
          <Row
            key={member.principal.toText()}
            onClick={clickHandler(member.principal)}
          >
            <IconCell>
              <UsersRound className="w-3 h-3" />
            </IconCell>
            <LabelCell>
              {member.displayName}{" "}
              <span className="text-muted-foreground text-xs">
                {member.role}
              </span>
            </LabelCell>
            <PrincipalCell principal={member.principal} />
            <Selected
              checked={controllers.teamMembers.includes(member.principal)}
              onCheckedChange={(checked) => {
                if (checked) {
                  controllers.setTeamMembers([
                    ...controllers.teamMembers,
                    member.principal,
                  ]);
                } else {
                  controllers.setTeamMembers(
                    controllers.teamMembers.filter(
                      (p) => p !== member.principal
                    )
                  );
                }
              }}
            />
          </Row>
        ))}
    </>
  );
}

function OtherControllersRows() {
  const controllers = useCanisterCreationStore((s) => s.controllerAssignment);
  return (
    <>
      {controllers.others.map((controller) => (
        <Row key={controller.toText()}>
          <IconCell>
            <KeyRound className="w-3 h-3" />
          </IconCell>
          <LabelCell>Custom</LabelCell>
          <PrincipalCell principal={controller} />
          <Selected
            checked
            onCheckedChange={() => {
              controllers.setOthers(
                controllers.others.filter((p) => p !== controller)
              );
            }}
          />
        </Row>
      ))}
    </>
  );
}

function Controllers() {
  const { principal } = useIdp();
  const controllers = useCanisterCreationStore((s) => s.controllerAssignment);
  const all = useMemo(() => {
    return [...controllers.teamMembers, ...controllers.others];
  }, [controllers.teamMembers, controllers.others]);
  return (
    <div className="flex-1 h-full flex flex-col gap-2 overflow-hidden">
      <div className="flex-1 flex flex-col border border-border rounded-md overflow-y-auto">
        <Row>
          <IconCell>
            <UserRound className="w-3 h-3" />
          </IconCell>
          <LabelCell>You</LabelCell>
          <PrincipalCell principal={principal} />
          <Tooltip>
            <TooltipTrigger>
              <Checkbox disabled checked={true} className="w-5 h-5" />
            </TooltipTrigger>
            <TooltipContent side="bottom" align="end" className="max-w-[400px]">
              You must always be controller of a newly created canister. You can
              change this later.
            </TooltipContent>
          </Tooltip>
        </Row>
        <TeamMembersRows />
        <OtherControllersRows />
      </div>
      <div className="text-xs text-muted-foreground">
        Canister will be deployed with {all.length + 1} controllers
      </div>
    </div>
  );
}
