import { CanisterCreationState } from "../canister-creation-wizard-store";
import ControllerAssignment from "./create-canister-controller-step";
import CyclesAllocation from "./create-canister-cycles-step";
import Execution from "./create-canister-execution-step";
import Failure from "./create-canister-failure-step";
import Review from "./create-canister-review-step";
import SubnetSelection from "./create-canister-subnet-step";
import Success from "./create-canister-success-step";

function mapStateToComponent(state: CanisterCreationState) {
  switch (state) {
    case "subnet-selection":
      return <SubnetSelection key={state} />;
    case "controller-assignment":
      return <ControllerAssignment key={state} />;
    case "cycles-allocation":
      return <CyclesAllocation key={state} />;
    // case "review":
    //   return <Review key={state} />;
    case "execution":
      return <Execution key={state} />;
    case "success":
      return <Success key={state} />;
    case "failure":
      return <Failure key={state} />;
    default:
      return null;
  }
}

export default mapStateToComponent;
