import { IdbStorage, KEY_STORAGE_DELEGATION } from "@dfinity/auth-client";
import { DelegationChain, isDelegationValid } from "@dfinity/identity";
import { formatDistanceToNow } from "date-fns";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";

import { InputNumber } from "@/components/input-number";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useConnectionTtl, useUpdateConnectionTtl } from "@/hooks/queries/idp";
import {
  ConnectionTtlUnit,
  ConnectionTtlSettings,
  validateConnectionTtl,
  useIdp,
} from "@/state/stores/idp";

import { ConnectionTtlChangeDialog } from "./connection-ttl-change-dialog";

const idbStorage = new IdbStorage();

function useSessionExpiration() {
  const [expiration, setExpiration] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getExpiration() {
      try {
        const storedDelegation = await idbStorage.get(KEY_STORAGE_DELEGATION);
        if (!storedDelegation) {
          setExpiration(null);
          return;
        }

        const chain = DelegationChain.fromJSON(storedDelegation);
        if (!isDelegationValid(chain)) {
          setExpiration(null);
          return;
        }

        const expirationNs = chain.delegations[0]?.delegation.expiration;
        if (!expirationNs) {
          setExpiration(null);
          return;
        }

        setExpiration(new Date(Number(expirationNs) / 1e6));
      } catch (error) {
        console.error("Error getting session expiration:", error);
        setExpiration(null);
      } finally {
        setIsLoading(false);
      }
    }

    getExpiration();
  }, []);

  return { expiration, isLoading };
}

export function ConnectionTtlCard() {
  const flag = useFeatureFlagEnabled("connection-ttl-config");
  const { data: settings, isLoading } = useConnectionTtl();
  const { mutate: updateSettings } = useUpdateConnectionTtl();
  const { expiration, isLoading: isLoadingExpiration } = useSessionExpiration();
  const [showChangeDialog, setShowChangeDialog] = useState(false);
  const [localSettings, setLocalSettings] =
    useState<ConnectionTtlSettings | null>(null);

  if (isLoading || !settings || !flag) return null;

  const handleValueChange = (value: string) => {
    const numValue = parseInt(value, 10);
    if (!Number.isNaN(numValue) && validateConnectionTtl(numValue)) {
      setLocalSettings({
        value: numValue,
        unit: localSettings?.unit ?? settings.unit,
      });
    }
  };

  const handleUnitChange = (unit: ConnectionTtlUnit) => {
    setLocalSettings({
      value: localSettings?.value ?? settings.value,
      unit,
    });
  };

  const handleSave = () => {
    if (localSettings) {
      updateSettings(localSettings);
      setShowChangeDialog(true);
      setLocalSettings(null);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Session Duration</CardTitle>
          <CardDescription>
            Configure how long your authenticated session will last before
            requiring you to sign in again. This setting is stored on your
            device and will apply to all accounts you use on this browser.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center gap-4">
            <InputNumber
              min={1}
              step={1}
              value={localSettings?.value ?? settings.value}
              onChange={(e) => handleValueChange(e.target.value)}
              placeholder="Enter duration"
              className="w-[180px]"
            />
            <Select
              value={localSettings?.unit ?? settings.unit}
              onValueChange={handleUnitChange}
            >
              <SelectTrigger className="w-[120px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="minutes">Minutes</SelectItem>
                <SelectItem value="hours">Hours</SelectItem>
                <SelectItem value="days">Days</SelectItem>
              </SelectContent>
            </Select>
            <Button onClick={handleSave} disabled={!localSettings}>
              Save
            </Button>
          </div>
          {isLoadingExpiration ? (
            <p className="text-sm text-muted-foreground">
              Loading session info...
            </p>
          ) : expiration ? (
            <p className="text-sm text-muted-foreground">
              Current session expires{" "}
              {formatDistanceToNow(expiration, { addSuffix: true })}
            </p>
          ) : (
            <p className="text-sm text-muted-foreground">No active session</p>
          )}
        </CardContent>
      </Card>

      <ConnectionTtlChangeDialog
        open={showChangeDialog}
        onOpenChange={setShowChangeDialog}
      />
    </>
  );
}
