import { Principal } from "@dfinity/principal";
import { format } from "date-fns";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  useCanisterSnapshotsQuery,
  useDeleteCanisterSnapshotMutation,
  useLoadCanisterSnapshotMutation,
  useTakeCanisterSnapshotMutation,
} from "@/hooks/queries/canister-management";

interface CanisterSnapshotsProps {
  canisterIdString: string;
}

function bytesToHex(bytes: number[] | Uint8Array): string {
  return Array.from(bytes)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export default function CanisterSnapshots({
  canisterIdString,
}: CanisterSnapshotsProps) {
  const canisterId = Principal.fromText(canisterIdString);
  const { data: snapshots, isLoading } = useCanisterSnapshotsQuery(canisterId);
  const { mutate: takeSnapshot, isPending: isTaking } =
    useTakeCanisterSnapshotMutation();
  const { mutate: deleteSnapshot, isPending: isDeleting } =
    useDeleteCanisterSnapshotMutation();
  const { mutate: loadSnapshot, isPending: isLoadingSnapshot } =
    useLoadCanisterSnapshotMutation();

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Snapshots</CardTitle>
        <Button
          onClick={() => takeSnapshot({ canisterId })}
          disabled={isLoading || isTaking}
        >
          {isTaking ? "Taking Snapshot..." : "Take Snapshot"}
        </Button>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="flex h-[200px] items-center justify-center">
            Loading snapshots...
          </div>
        ) : snapshots?.length === 0 ? (
          <div className="flex h-[200px] items-center justify-center text-muted-foreground">
            No snapshots available
          </div>
        ) : (
          <div className="space-y-4">
            {snapshots?.map((snapshot) => (
              <div
                key={bytesToHex(snapshot.id)}
                className="flex items-center justify-between rounded-lg border p-4"
              >
                <div className="space-y-1">
                  <div className="text-sm font-medium">
                    {format(
                      Number(snapshot.taken_at_timestamp) / 1_000_000,
                      "PPpp"
                    )}
                  </div>
                  <div className="text-xs text-muted-foreground">
                    Size: {formatBytes(Number(snapshot.total_size))}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    onClick={() =>
                      loadSnapshot({
                        canisterId,
                        snapshotId: new Uint8Array(snapshot.id),
                      })
                    }
                    disabled={isLoadingSnapshot || isDeleting}
                  >
                    {isLoadingSnapshot ? "Loading..." : "Load"}
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() =>
                      deleteSnapshot({
                        canisterId,
                        snapshotId: new Uint8Array(snapshot.id),
                      })
                    }
                    disabled={isDeleting || isLoadingSnapshot}
                  >
                    {isDeleting ? "Deleting..." : "Delete"}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
}
