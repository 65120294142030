/**
 * Type representing a dot-notation path through an object type
 * Limits depth to 5 levels to prevent infinite recursion
 */
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5];

type Paths<T, D extends number = 5> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : "";

/**
 * Get the type of a value at a specific path, handling optional properties
 */
type PathValue<T, P extends string> = P extends keyof T
  ? T[P]
  : P extends `${infer K}.${infer R}`
  ? K extends keyof T
    ? NonNullable<T[K]> extends object
      ? R extends keyof NonNullable<T[K]>
        ? undefined extends T[K]
          ? NonNullable<T[K]>[R] | undefined
          : NonNullable<T[K]>[R]
        : PathValue<NonNullable<T[K]>, R>
      : undefined
    : never
  : never;

/**
 * Type representing a selector that can either be a path string or a function
 */
export type DataSelector<Input, Output = unknown> =
  | Paths<Input>
  | ((item: Input) => Output);

/**
 * Safely gets a value from an object using a dot-notation path
 */
function getByPath<T extends Record<string, any>, P extends Paths<T>>(
  obj: T,
  path: P
): PathValue<T, P> {
  return path.split(".").reduce((acc: any, part: string) => acc?.[part], obj);
}

/**
 * Selects a value from a data item using either a path string or a selector function
 */
export function selectValue<T, S extends DataSelector<T>>(
  item: T,
  selector: S
): S extends (item: T) => infer R
  ? R
  : S extends keyof T
  ? T[S]
  : S extends Paths<T>
  ? PathValue<T, S>
  : never {
  if (typeof selector === "function") {
    return selector(item) as any;
  }
  return getByPath(item as Record<string, any>, selector as string);
}
