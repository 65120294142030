import { AnonymousIdentity, Identity } from "@dfinity/agent";
import {
  AuthClient,
  IdbStorage,
  KEY_STORAGE_DELEGATION,
} from "@dfinity/auth-client";
import { DelegationChain, isDelegationValid } from "@dfinity/identity";
import { Principal } from "@dfinity/principal";
import posthog from "posthog-js";
import { toast } from "sonner";
import { createStore, useStore } from "zustand";

import { queryClient } from "@/hooks/queries";
import { agent, ic } from "@/lib/actors";

export type ConnectionTtlUnit = "minutes" | "hours" | "days";

export interface ConnectionTtlSettings {
  value: number;
  unit: ConnectionTtlUnit;
}

export function getConnectionTtlStorageKey(principal: Principal): string {
  return "connection-ttl-settings";
}

export const DEFAULT_CONNECTION_TTL: ConnectionTtlSettings = {
  value: 7,
  unit: "days",
};

const NANOS_PER_MINUTE = BigInt(60 * 1_000_000_000);
const NANOS_PER_HOUR = BigInt(60 * 60 * 1_000_000_000);
const NANOS_PER_DAY = BigInt(24 * 60 * 60 * 1_000_000_000);

const unitToNanos: Record<ConnectionTtlUnit, bigint> = {
  minutes: NANOS_PER_MINUTE,
  hours: NANOS_PER_HOUR,
  days: NANOS_PER_DAY,
};

export function settingsToNanos(settings: ConnectionTtlSettings): bigint {
  return BigInt(settings.value) * unitToNanos[settings.unit];
}

function nanosToSettings(nanos: bigint): ConnectionTtlSettings {
  // Convert to days by default, as it's the most common use case
  const days = Number(nanos) / Number(NANOS_PER_DAY);
  return {
    value: Math.round(days),
    unit: "days",
  };
}

export function validateConnectionTtl(value: number): boolean {
  return value > 0 && Number.isInteger(value);
}

// Store handling the connection of the user's identity provider.

// Determines how long the delegation identity created by the identity provider will last for.
const CONNECTION_TTL_NS = BigInt(7 * 24 * 60 * 60_000_000_000);

interface IdpStore {
  // Function to connect to the identity provider.
  connect: () => Promise<void>;

  // Function to disconnect from the identity provider.
  disconnect: () => Promise<void>;

  // Dfinity auth client, which does most of the heavy lifting for us.
  authClient: Promise<AuthClient>;

  // Boolean state parameter indicating the connection status.
  connected: boolean;

  // State parameter indicating the currently connected user's principal.
  principal: Principal;

  // State parameter indicating the currently connected identity.
  identity: Identity;

  // Boolean state parameter indicating whether the store's asynchronous initialization has completed.
  initialized: boolean;
}

// NFID config (not working, local setup not good)
const APPLICATION_NAME = "CyclΞOps";
const APPLICATION_LOGO_URL = "https://i.imgur.com/xjAm5I7.png";
const AUTH_PATH = `/authenticate/?applicationName=${APPLICATION_NAME}&applicationLogo=${APPLICATION_LOGO_URL}#authorize`;
const NFID_AUTH_URL = `https://nfid.one${AUTH_PATH}`;

// II config
const II_AUTH_URL =
  ic.isLocal === true
    ? `${ic.protocol}://${import.meta.env.CYOPS_II_CANISTER_ID}.${
        import.meta.env.CYOPS_IC_HOST
      }/`
    : `${ic.protocol}://${import.meta.env.CYOPS_II_CANISTER_ID}.${ic.origin}`;

const idp = createStore<IdpStore>((set, get) => ({
  authClient: AuthClient.create({
    idleOptions: { disableDefaultIdleCallback: true, disableIdle: true },
  }),
  initialized: false,
  connected: false,
  principal: Principal.anonymous(),
  identity: new AnonymousIdentity(),

  /// Connect to the identity provider.
  async connect() {
    const client = await get().authClient;
    const { principal } = get();
    const storageKey = getConnectionTtlStorageKey(principal);
    const stored = localStorage.getItem(storageKey);
    let ttl: bigint;

    if (stored) {
      try {
        const settings = JSON.parse(stored) as ConnectionTtlSettings;
        ttl = settingsToNanos(settings);
      } catch {
        ttl = settingsToNanos(DEFAULT_CONNECTION_TTL);
      }
    } else {
      ttl = settingsToNanos(DEFAULT_CONNECTION_TTL);
    }

    return new Promise((res, rej) => {
      client.login({
        onSuccess: async () => {
          await assignIdentity();
          res();
        },
        onError: (error) => {
          rej(new Error(error));
        },
        identityProvider: II_AUTH_URL, // NFID_AUTH_URL,
        windowOpenerFeatures:
          `left=${window.screen.width / 2 - 525 / 2}, ` +
          `top=${window.screen.height / 2 - 705 / 2},` +
          `toolbar=0,location=0,menubar=0,width=525,height=705`,
        maxTimeToLive: ttl,
      });
    });
  },

  /// Disconnect from the identity provider.
  async disconnect() {
    const client = await get().authClient;
    client.logout();
    const identity = new AnonymousIdentity();
    agent.replaceIdentity(identity);
    set({
      connected: false,
      principal: Principal.anonymous(),
      identity,
    });
    posthog.reset();
    clearExpiryTimer();
    // Clear all React Query cache
    queryClient.clear();
  },
}));

/// Assign an identity to the store.
async function assignIdentity() {
  const client = await idp.getState().authClient;
  const identity = client.getIdentity();
  const principal = identity.getPrincipal();
  agent.replaceIdentity(identity);
  idp.setState({ connected: true, principal, identity });
  posthog.identify(principal.toText());
  watchExpiryTimer();
}

/// Initialize the IDP store.
async function init() {
  // When working locally (or !mainnet) we need to retrieve the root key of the replica.
  if (ic.isLocal) {
    console.debug("Fetching network root key.");
    await agent.fetchRootKey();
  }

  // Restore existing connection.
  const client = await idp.getState().authClient;
  if (await client.isAuthenticated()) {
    await assignIdentity();
  }

  idp.setState({ initialized: true });
}

init();

const idbStorage = new IdbStorage();
let expiryTimer: number;

/// Monitor delegation for expiry
async function watchExpiryTimer() {
  if (expiryTimer !== undefined) clearExpiryTimer();
  const interval = 5_000;

  async function checkExpiry() {
    const client = await idp.getState().authClient;
    const storedDelegation = await idbStorage.get(KEY_STORAGE_DELEGATION);
    const chain =
      storedDelegation !== null
        ? DelegationChain.fromJSON(storedDelegation)
        : null;
    const delegationValid = chain !== null && isDelegationValid(chain);
    const expiration = chain?.delegations?.[0]?.delegation.expiration;
    const expirationDate = new Date(Number(expiration) / 1e6);
    const willExpire = expirationDate < new Date(Date.now() + interval);
    if (willExpire || !delegationValid || !(await client.isAuthenticated())) {
      toast.info("Your session has expired.");
      idp.getState().disconnect();
    }
  }

  expiryTimer = window.setInterval(async () => {
    await checkExpiry();
  }, interval);

  await checkExpiry();
}

async function clearExpiryTimer() {
  window.clearInterval(expiryTimer);
}

export const useIdp = () => useStore(idp);
export default idp;
