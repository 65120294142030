"use client";

import { Principal } from "@dfinity/principal";
import { Trash2, Plus, Copy, AlertCircle, Check } from "lucide-react";
import { useState } from "react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  useCanisterControllersMutation,
  useCanisterControllersQuery,
} from "@/hooks/queries/canister-management";
import { copy } from "@/lib/ui-utils";

export default function CanisterControllers({
  canisterIdString,
}: {
  canisterIdString: string;
}) {
  const canisterId = Principal.fromText(canisterIdString);
  const controllers = useCanisterControllersQuery(canisterId);
  const mutation = useCanisterControllersMutation();
  const [newController, setNewController] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState<string | null>(null);

  const addController = () => {
    if (!controllers.data) return;
    if (!newController) {
      setError("Please enter a controller ID");
      return;
    }

    if (controllers.data?.map((x) => x.toText()).includes(newController)) {
      setError("This controller already exists");
      return;
    }

    try {
      const principal = Principal.fromText(newController);
      mutation.mutate({
        canisterId,
        controllers: [...controllers.data, principal],
      });
      setNewController("");
    } catch (e) {
      setError("Invalid principal ID");
    }
  };

  const removeController = (controller: string) => {
    if (!controllers.data) return;
    const principal = Principal.fromText(controller);
    mutation.mutate({
      canisterId,
      controllers: controllers.data.filter(
        (x) => x.toText() !== principal.toText()
      ),
    });
  };

  const copyToClipboard = (text: string) => {
    copy(text);
    setCopied(text);
    setTimeout(() => setCopied(null), 2000);
  };

  console.log({ controllers });

  return (
    <Card className="w-full max-w-2xl">
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-2xl font-bold">
              Canister Controllers
            </CardTitle>
            <CardDescription className="mt-1">
              Manage principal IDs that can control this canister
            </CardDescription>
          </div>
          <Badge variant="outline" className="">
            {controllers.data?.length ?? "-"} Controllers
          </Badge>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        {error && (
          <Alert
            variant="destructive"
            className="bg-red-900/20 border-red-800 text-red-300"
          >
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="space-y-4">
          {controllers.data?.map((controller, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 rounded-lg border group transition-colors"
            >
              <div className="flex items-center gap-2 overflow-hidden">
                <span className="font-mono text-sm truncate">
                  {controller.toText()}
                </span>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 "
                  onClick={() => copyToClipboard(controller.toText())}
                >
                  {copied === controller.toText() ? (
                    <Check className="h-4 w-4" />
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </Button>

                <Button
                  loading={mutation.isPending}
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 text-red-400 hover:text-red-300 hover:bg-red-900/30"
                  onClick={() => removeController(controller.toText())}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="pt-4 border-t ">
          <div className="flex gap-2">
            <Input
              placeholder="Enter controller principal ID"
              value={newController}
              onChange={(e) => {
                setNewController(e.target.value);
                setError(null);
              }}
              className=""
            />
            <Button
              onClick={addController}
              loading={mutation.isPending}
              className=""
            >
              <Plus className="h-4 w-4 mr-2" />
              Add
            </Button>
          </div>
        </div>
      </CardContent>
      <CardFooter className="border-t text-xs pt-6">
        <p>Controllers have all the fun</p>
      </CardFooter>
    </Card>
  );
}
