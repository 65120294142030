import { Principal } from "@dfinity/principal";
import { useQueries, useQuery } from "@tanstack/react-query";

import { fetchCanistersPaginated } from "@/hooks/queries/canisters";
import { useCallerTeamsQuery } from "@/hooks/queries/team";
import { useIdp } from "@/state/stores/idp";

// Hook to check if a canister is already in one of the user's teams
export function useCheckCanisterInTeamsQuery(canisterId?: Principal) {
  const teamsQuery = useCallerTeamsQuery();
  const { principal } = useIdp();

  // Create a query for each team to fetch its canisters
  const teamQueries = useQueries({
    queries: (teamsQuery.data || []).map((team) => ({
      queryKey: ["canisters", "paginated", team.principal.toString()],
      queryFn: async () => {
        // Use the same function that usePaginatedCanistersQuery uses internally
        return fetchCanistersPaginated({ asTeamPrincipal: [team.principal] });
      },
      enabled: teamsQuery.isSuccess,
      // Share cache time with other canisters queries
      staleTime: 1000 * 10, // Match the staleTime used in usePaginatedCanistersQuery
    })),
  });

  return useQuery({
    queryKey: [
      "canister-in-teams",
      canisterId?.toString(),
      principal.toString(),
    ],
    queryFn: async () => {
      if (!canisterId || !teamsQuery.data || teamsQuery.data.length === 0) {
        return null;
      }

      // Process the results of the team queries
      for (let i = 0; i < teamQueries.length; i++) {
        const query = teamQueries[i];
        // Skip if query is undefined
        if (!query) continue;

        const team = teamsQuery.data[i];
        // Skip if team is undefined
        if (!team) continue;

        // Skip if the query failed or is still loading
        if (!query.data || query.isLoading) continue;

        const canisters = query.data;

        // Check if the canister is in this team
        const canisterExists = canisters.some((canisterData) => {
          return canisterData.canisterId.toString() === canisterId.toString();
        });

        if (canisterExists) {
          return {
            isInTeam: true,
            teamName: team.displayName || team.username,
            teamPrincipal: team.principal,
          };
        }
      }

      return null;
    },
    // Only run this query when all team queries are settled (either success or error)
    enabled:
      !!canisterId &&
      teamsQuery.isSuccess &&
      teamsQuery.data?.length > 0 &&
      teamQueries.every((query) => query && (query.isSuccess || query.isError)),
    // Cache the result for 5 minutes
    staleTime: 1000 * 60 * 5,
  });
}
