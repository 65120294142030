import { Principal } from "@dfinity/principal";
import { useCallback, useMemo } from "react";

import { Charge } from "@/components/pages/billing";
import {
  balanceInsight,
  burnInsight,
  memorySizeInsight,
  mergeInsights,
  queryCallsInsight,
  reservedCyclesInsight,
  topupsInsight,
} from "@/insights";
import { InsightOptions } from "@/insights/insight";
import { CanisterData } from "@/insights/types";

import {
  useCanisterTable,
  useCanisterTableStore,
} from "../stores/canister-table-store";
import { usePaginatedCanistersQuery } from "./canisters";
import { useChargesQuery } from "./transactions";

/// Just a util to map to the new canister status type... probably replace with a new Byron API
function useCanisterData(canisterId?: Principal): CanisterData | undefined {
  const select = useCallback(
    (d: CanisterData[]) => {
      const r = d.find((x) => x.canisterId.toText() === canisterId?.toText());
      return r;
    },
    [canisterId]
  );
  const { data } = usePaginatedCanistersQuery({ select });
  return data;
}

function useCanisterBurnInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => burnInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterBalanceInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => balanceInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterMemorySizeInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => memorySizeInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterReservedCyclesInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  const mappedData = useMemo(
    () =>
      data?.seriesStatus.map<[Date, CanisterData]>((x) => [x[0], data]) ?? [],
    [data]
  );
  return useMemo(
    () => reservedCyclesInsight(mappedData, options),
    [mappedData, options]
  );
}

function useCanisterQueryCallsInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => queryCallsInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterTopupsInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const select = useCallback(
    (data: Charge[]) =>
      data.filter((x) => x.canister.toText() === canisterId?.toText()),
    [canisterId]
  );
  const query = useChargesQuery({ limit: 1000 }, { select });
  const data: [Date, Charge][] = useMemo(
    () => query.data?.map((x) => [new Date(x.timestamp), x]) ?? [],
    [query.data]
  );
  return useMemo(() => topupsInsight(data, options), [data, options]);
}

function useAggregateBurnInsight(
  options?: InsightOptions,
  filter: boolean = true
) {
  const { filters, searchQuery } = useCanisterTableStore();
  const table = useCanisterTable();

  const select = useMemo(() => {
    if (!filter || !table) return (d: CanisterData[]) => d;

    return (d: CanisterData[]) => {
      // Get filtered rows directly from the table's filtered model
      // This will respect both searchQuery and the applied filters
      const filteredCanisters = table
        .getFilteredRowModel()
        .rows.map((row) => row.original.id.toText());

      return d.filter((canister) =>
        filteredCanisters.includes(canister.canisterId.toText())
      );
    };
  }, [table, filters, searchQuery, filter]);

  const { data } = usePaginatedCanistersQuery({ select });

  const aggregateBurn = useMemo(() => {
    if (!data) return undefined;
    const insights = data.map((x) => burnInsight(x.seriesStatus, options));
    const aggregate = mergeInsights(insights);
    if (aggregate?.points.filter(([, x]) => !!x).length === 0) {
      return { points: undefined };
    }
    return aggregate;
  }, [data, options]);

  return aggregateBurn;
}

function useAggregateTopupsInsight(
  options?: InsightOptions,
  filter: boolean = true
) {
  const { filters, searchQuery } = useCanisterTableStore();
  const table = useCanisterTable();

  const select = useMemo(() => {
    if (!filter || !table) return (d: Charge[]) => d;

    return (d: Charge[]) => {
      // Get filtered rows directly from the table's filtered model
      // This will respect both searchQuery and the applied filters
      const filteredCanisters = table
        .getFilteredRowModel()
        .rows.map((row) => row.original.id.toText());

      return d.filter((charge) =>
        filteredCanisters.includes(charge.canister.toText())
      );
    };
  }, [table, filters, searchQuery, filter]);

  const query = useChargesQuery({ limit: 1000 }, { select });
  const data: [Date, Charge][] = useMemo(
    () => query.data?.map((x) => [new Date(x.timestamp), x]) ?? [],
    [query.data]
  );
  const aggregateTopUps = useMemo(() => {
    const aggregate = topupsInsight(data, options);
    if (aggregate.points.filter(([, x]) => !!x).length === 0) {
      return { points: undefined };
    }
    return aggregate;
  }, [data, options]);

  return aggregateTopUps;
}

export {
  useCanisterData,
  useCanisterBurnInsight,
  useCanisterBalanceInsight,
  useCanisterReservedCyclesInsight,
  useCanisterMemorySizeInsight,
  useCanisterQueryCallsInsight,
  useCanisterTopupsInsight,
  useAggregateBurnInsight,
  useAggregateTopupsInsight,
};
