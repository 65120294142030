import { zodResolver } from "@hookform/resolvers/zod";
import BigNumber from "bignumber.js";
import { createContext, useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { InputNumber } from "@/components/input-number";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Label } from "@/components/ui/label";

// Schema for the form input values (before transformation)
const topUpRuleInputSchema = z.object({
  threshold: z.string(),
  amount: z.string(),
  method: z.enum(["by_amount", "to_balance"]).default("by_amount"),
});

// Schema for the form values (after transformation)
export const topUpRuleSchema = topUpRuleInputSchema
  .transform((data) => ({
    threshold: Number(data.threshold),
    amount: Number(data.amount),
    method: data.method,
  }))
  .pipe(
    z.object({
      threshold: z
        .number()
        .min(0.1, "Threshold must be at least 0.1T cycles")
        .max(1000, "Threshold cannot exceed 1000T cycles"),
      amount: z
        .number()
        .min(0.1, "Amount must be at least 0.1T cycles")
        .max(1000, "Amount cannot exceed 1000T cycles"),
      method: z.enum(["by_amount", "to_balance"]),
    })
  );

export type TopUpRuleFormValues = z.infer<typeof topUpRuleSchema>;
type TopUpRuleInputValues = z.infer<typeof topUpRuleInputSchema>;

export interface TopUpRuleFormProps {
  defaultValues?: Partial<{
    threshold: string | number;
    amount: string | number;
    method: "by_amount" | "to_balance";
  }>;
  onSubmit: (values: TopUpRuleFormValues) => void | Promise<void>;
}

// Create a context to share form state between components
interface TopUpRuleFormContextValue {
  form: ReturnType<typeof useTopUpRuleForm>["form"];
  handleSubmit: ReturnType<typeof useTopUpRuleForm>["handleSubmit"];
  loading?: boolean;
}

const TopUpRuleFormContext = createContext<TopUpRuleFormContextValue | null>(
  null
);

export function useTopUpRuleFormContext() {
  const context = useContext(TopUpRuleFormContext);
  if (!context) {
    throw new Error(
      "useTopUpRuleFormContext must be used within a TopUpRuleFormProvider"
    );
  }
  return context;
}

export function useTopUpRuleForm({
  defaultValues,
  onSubmit,
}: TopUpRuleFormProps) {
  const form = useForm<TopUpRuleInputValues>({
    resolver: zodResolver(topUpRuleSchema),
    defaultValues: {
      threshold: String(defaultValues?.threshold ?? 1), // Default 1T cycles
      amount: String(defaultValues?.amount ?? 3), // Default 3T cycles
      method: defaultValues?.method ?? "by_amount",
    },
  });

  const handleSubmit = form.handleSubmit((values) =>
    onSubmit(values as unknown as TopUpRuleFormValues)
  );

  return {
    form,
    handleSubmit,
    formState: form.formState,
    register: form.register,
    watch: form.watch,
    setValue: form.setValue,
    // Helper to convert trillion cycles to raw cycles using BigNumber for precision
    toRawCycles: (trillionCycles: number) =>
      BigNumber(trillionCycles).multipliedBy(1e12).integerValue().toNumber(),
  };
}

// Root component that provides context
export function TopUpRuleFormRoot({
  children,
  defaultValues,
  onSubmit,
  loading,
  className,
}: TopUpRuleFormProps & {
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
}) {
  const { form, handleSubmit } = useTopUpRuleForm({ defaultValues, onSubmit });

  return (
    <TopUpRuleFormContext.Provider value={{ form, handleSubmit, loading }}>
      <Form {...form}>
        <form onSubmit={handleSubmit} className={className}>
          {children}
        </form>
      </Form>
    </TopUpRuleFormContext.Provider>
  );
}

// Form fields component
export function TopUpRuleFormFields() {
  const { form } = useTopUpRuleFormContext();
  const method = form.watch("method");

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label>Top-up method</Label>
        <FormField
          control={form.control}
          name="method"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex gap-2">
                  <Button
                    type="button"
                    variant={
                      field.value === "by_amount" ? "default" : "outline"
                    }
                    onClick={() => field.onChange("by_amount")}
                    className="flex-1 max-w-[120px]"
                  >
                    By amount
                  </Button>
                  <Button
                    type="button"
                    variant={
                      field.value === "to_balance" ? "default" : "outline"
                    }
                    onClick={() => field.onChange("to_balance")}
                    className="flex-1 max-w-[120px]"
                  >
                    To balance
                  </Button>
                </div>
              </FormControl>
              <FormDescription className="text-xs text-muted-foreground">
                {field.value === "by_amount"
                  ? "Top-up the canister by a fixed amount every time the balance falls below the threshold."
                  : "Top-up the canister to a target balance every time the balance falls below the threshold."}
              </FormDescription>
            </FormItem>
          )}
        />
      </div>

      <div className="flex items-center gap-6">
        <FormField
          control={form.control}
          name="threshold"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>Threshold (T cycles)</FormLabel>
              <FormControl>
                <InputNumber
                  {...field}
                  min={0.1}
                  step={0.1}
                  className="w-full"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>
                {method === "by_amount" ? "Top-up amount" : "Target balance"} (T
                cycles)
              </FormLabel>
              <FormControl>
                <InputNumber
                  {...field}
                  min={0.1}
                  step={0.1}
                  className="w-full"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}

// Form errors component
export function TopUpRuleFormErrors() {
  const { form } = useTopUpRuleFormContext();

  if (Object.keys(form.formState.errors).length === 0) return null;

  return (
    <div className="text-sm text-destructive">
      {Object.entries(form.formState.errors).map(([field, error]) => (
        <div key={field}>{error?.message}</div>
      ))}
    </div>
  );
}

// Default submit button component
export function TopUpRuleFormSubmit({
  children = "Update top-up rule",
  className = "w-full",
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  const { loading } = useTopUpRuleFormContext();

  return (
    <Button
      type="submit"
      className={className}
      loading={loading}
      disabled={loading}
    >
      {children}
    </Button>
  );
}

// Legacy component for backward compatibility
export function TopUpRuleForm({
  defaultValues,
  onSubmit,
  loading,
  className,
}: TopUpRuleFormProps & {
  loading?: boolean;
  className?: string;
}) {
  return (
    <TopUpRuleFormRoot
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      loading={loading}
      className={className}
    >
      <div className="space-y-4">
        <TopUpRuleFormFields />
        <TopUpRuleFormSubmit />
        <TopUpRuleFormErrors />
      </div>
    </TopUpRuleFormRoot>
  );
}
