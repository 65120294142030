import { Principal } from "@dfinity/principal";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import posthog from "posthog-js";
import React from "react";
import { toast } from "sonner";
import { z } from "zod";

import {
  Subnet as SubnetRaw,
  SubnetSelection,
} from "common/declarations/cycleops/cycleops.did.d";

import useCanisterCreationStore, {
  mapToRequest,
} from "@/components/canister-create/canister-creation-wizard-store";
import { cyops, ic } from "@/lib/actors";
import { mapOptional } from "@/lib/ic-utils";

import { refetchCanisters } from "./canisters";
import { asTeamDefault, useAsTeamQuery } from "./team";

// Read interfaces

type SubnetType = "application" | "_system";

interface KnownSubnet {
  id: Principal;
  type: SubnetType;
  specialization?: string;
}

// Map read responses

function mapSubnet(raw: SubnetRaw): KnownSubnet {
  const { id, subnetType } = raw;
  const type = "application" in subnetType ? "application" : "_system";
  const specialization = mapOptional(raw.specialization);
  return {
    id,
    type,
    specialization,
  };
}

export { mapSubnet };

// Subnet Metadata Schema

const ReplicaVersionSchema = z.object({
  executed_timestamp_seconds: z.number(),
  proposal_id: z.string(),
  replica_version_id: z.string(),
});

const SubnetMetadataSchema = z.object({
  display_name: z.string().nullable(),
  instruction_rate: z.number(),
  memory_usage: z.number(),
  message_execution_rate: z.number(),
  nakamoto_coefficient_cities: z.number(),
  nakamoto_coefficient_countries: z.number(),
  nakamoto_coefficient_data_centers: z.number(),
  nakamoto_coefficient_node_providers: z.number(),
  nakamoto_coefficient_overall: z.number(),
  nakamoto_coefficient_owners: z.number(),
  nakamoto_percentage_cities: z.number(),
  nakamoto_percentage_countries: z.number(),
  nakamoto_percentage_data_centers: z.number(),
  nakamoto_percentage_node_providers: z.number(),
  nakamoto_percentage_overall: z.number(),
  nakamoto_percentage_owners: z.number(),
  replica_versions: z.array(ReplicaVersionSchema),
  running_canisters: z.number(),
  stopped_canisters: z.number(),
  subnet_authorization: z.string(),
  subnet_id: z.string(),
  subnet_specialization: z.string(),
  subnet_type: z.string(),
  total_canisters: z.number(),
  total_countries: z.number(),
  total_node_providers: z.number(),
  total_nodes: z.number(),
  up_nodes: z.number(),
});

const SubnetMetadataResponseSchema = z.object({
  subnets: z.array(SubnetMetadataSchema),
});

type SubnetMetadataRaw = z.infer<typeof SubnetMetadataSchema>;

interface SubnetMetadata {
  displayName: string | null;
  instructionRate: number;
  memoryUsage: number;
  messageExecutionRate: number;
  nakamotoCoefficientCities: number;
  nakamotoCoefficientCountries: number;
  nakamotoCoefficientDataCenters: number;
  nakamotoCoefficientNodeProviders: number;
  nakamotoCoefficientOverall: number;
  nakamotoCoefficientOwners: number;
  nakamotoPercentageCities: number;
  nakamotoPercentageCountries: number;
  nakamotoPercentageDataCenters: number;
  nakamotoPercentageNodeProviders: number;
  nakamotoPercentageOverall: number;
  nakamotoPercentageOwners: number;
  replicaVersions: Array<{
    executedTimestampSeconds: number;
    proposalId: string;
    replicaVersionId: string;
  }>;
  runningCanisters: number;
  stoppedCanisters: number;
  subnetAuthorization: string;
  id: Principal;
  subnetSpecialization: string;
  subnetType: string;
  totalCanisters: number;
  totalCountries: number;
  totalNodeProviders: number;
  totalNodes: number;
  upNodes: number;
  cost: bigint;
}

function calculateCost(data: SubnetMetadataRaw): bigint {
  const CANISTER_CREATE_FEE_PER_13_NODE_SUBNET = 500_000_000_000;
  const costPerNode = CANISTER_CREATE_FEE_PER_13_NODE_SUBNET / 13;
  const cost = BigNumber(costPerNode)
    .times(data.total_nodes)
    .integerValue()
    .toNumber();
  return BigInt(cost);
}

function mapType(type: string) {
  if (type.toLocaleLowerCase().includes("application")) return "application";
  return "system";
}

function mapSubnetMetadataToCamelCase(data: SubnetMetadataRaw): SubnetMetadata {
  return {
    displayName: data.display_name,
    instructionRate: data.instruction_rate,
    memoryUsage: data.memory_usage,
    messageExecutionRate: data.message_execution_rate,
    nakamotoCoefficientCities: data.nakamoto_coefficient_cities,
    nakamotoCoefficientCountries: data.nakamoto_coefficient_countries,
    nakamotoCoefficientDataCenters: data.nakamoto_coefficient_data_centers,
    nakamotoCoefficientNodeProviders: data.nakamoto_coefficient_node_providers,
    nakamotoCoefficientOverall: data.nakamoto_coefficient_overall,
    nakamotoCoefficientOwners: data.nakamoto_coefficient_owners,
    nakamotoPercentageCities: data.nakamoto_percentage_cities,
    nakamotoPercentageCountries: data.nakamoto_percentage_countries,
    nakamotoPercentageDataCenters: data.nakamoto_percentage_data_centers,
    nakamotoPercentageNodeProviders: data.nakamoto_percentage_node_providers,
    nakamotoPercentageOverall: data.nakamoto_percentage_overall,
    nakamotoPercentageOwners: data.nakamoto_percentage_owners,
    replicaVersions: data.replica_versions.map((version) => ({
      executedTimestampSeconds: version.executed_timestamp_seconds,
      proposalId: version.proposal_id,
      replicaVersionId: version.replica_version_id,
    })),
    runningCanisters: data.running_canisters,
    stoppedCanisters: data.stopped_canisters,
    subnetAuthorization: data.subnet_authorization,
    id: Principal.fromText(data.subnet_id),
    subnetSpecialization: data.subnet_specialization,
    subnetType: mapType(data.subnet_type),
    totalCanisters: data.total_canisters,
    totalCountries: data.total_countries,
    totalNodeProviders: data.total_node_providers,
    totalNodes: data.total_nodes,
    upNodes: data.up_nodes,
    cost: calculateCost(data),
  };
}

export {
  type SubnetMetadataRaw,
  type SubnetMetadata,
  SubnetMetadataSchema,
  mapSubnetMetadataToCamelCase,
  calculateCost,
};

// Fetch

async function fetchKnownSubnets() {
  const call = await cyops.getSubnetData();
  const result = call.metadataMap.map(([, raw]) => mapSubnet(raw));
  return result;
}

async function fetchSubnetMetadata() {
  const call = await fetch(
    "https://ic-api.internetcomputer.org/api/v3/subnets"
  );
  const result = await call.json();
  const validated = SubnetMetadataResponseSchema.parse(result);
  return validated.subnets.map(mapSubnetMetadataToCamelCase);
}

export { fetchKnownSubnets, fetchSubnetMetadata };

// Query

function useKnownSubnetsQuery<RT = SubnetMetadata[]>(
  options?: Partial<UseQueryOptions<SubnetMetadata[], unknown, RT>>
) {
  return useQuery({
    queryKey: ["known-subnets"],
    queryFn: async () => {
      const [knownSubnets, subnetMetadata] = await Promise.all([
        fetchKnownSubnets(),
        fetchSubnetMetadata(),
      ]);
      const source = ic.isLocal ? subnetMetadata : knownSubnets;
      const combined = source.map((subnet) => {
        const metadata = subnetMetadata.find(
          (m) => m.id.toText() === subnet.id.toText()
        );
        if (!metadata) throw new Error("Unexpected missing metadata");
        return { ...subnet, ...metadata };
      });
      return combined;
    },
    throwOnError(error, query) {
      console.error("Unexpected response from API", error);
      return false;
    },
    ...options,
  });
}

function useSubnetMetadataQuery(subnetId?: Principal) {
  const select = React.useCallback(
    (data: SubnetMetadata[]) => {
      const metadata = data.find((m) => m.id.toText() === subnetId?.toText())!;
      return metadata;
    },
    [subnetId]
  );
  return useKnownSubnetsQuery({ select });
}

export { useKnownSubnetsQuery, useSubnetMetadataQuery };

// Write interfaces

interface CreateCanisterRequest {
  controllers: Principal[];
  subnetSelection?: Principal;
  withStartingCyclesBalance: bigint;
}

export type { CreateCanisterRequest };

// Map write requests

function mapSubnetSelection(
  subnetSelection?: Principal
): [] | [SubnetSelection] {
  return subnetSelection === undefined
    ? []
    : [{ Subnet: { subnet: subnetSelection } }];
}

// Post

async function postCreateCanister(
  request: CreateCanisterRequest,
  asTeam = asTeamDefault
) {
  const subnetSelection = mapSubnetSelection(request.subnetSelection);
  const mapped = {
    ...request,
    subnetSelection,
  };
  const call = await cyops.createCanister({
    topupRule: [],
    name: [],
    ...mapped,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call;
}

export { postCreateCanister };

// Mutation

function useCreateCanisterMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: CreateCanisterRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCreateCanister(request, data);
    },
    onSuccess: (_, request) => {
      toast.success(`Canister created successfully`);
      posthog.capture("Canister Added", {
        "Created on CycleOps": true,
        "Monitoring Mechanism": "Blackhole",
        Count: 1,
      });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to create canister", { description: error.message });
    },
  });
}

function useCreateCanisterWizardMutation() {
  const store = useCanisterCreationStore();
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async () => {
      const request = await mapToRequest(store);
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCreateCanister(request, data);
    },
    onSuccess: (response, request) => {
      toast.success(`Canister created successfully`);
      posthog.capture("Canister Added", {
        "Created on CycleOps": true,
        "Monitoring Mechanism": "Blackhole",
        Count: 1,
      });
      store.execution.setCreatedCanisterId(response.ok);
      store.machine.set("success");
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to create canister", { description: error.message });
      store.execution.setError(error as Error);
      store.machine.set("failure");
    },
  });
}

export { useCreateCanisterMutation, useCreateCanisterWizardMutation };
