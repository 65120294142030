import { Principal } from "@dfinity/principal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  useCanisterStatusQuery,
  useUpdateCanisterSettingsMutation,
} from "@/hooks/queries/canister-management";

const formSchema = z.object({
  freezingThreshold: z.string().optional(),
  memoryAllocation: z.string().optional(),
  computeAllocation: z.string().optional(),
  reservedCyclesLimit: z.string().optional(),
  wasmMemoryLimit: z.string().optional(),
  logVisibility: z.enum(["controllers", "public"]).optional(),
});

interface CanisterSettingsProps {
  canisterIdString: string;
}

export default function CanisterSettings({
  canisterIdString,
}: CanisterSettingsProps) {
  const canisterId = Principal.fromText(canisterIdString);
  const { data: status, isLoading } = useCanisterStatusQuery(canisterId);
  const settingsMutation = useUpdateCanisterSettingsMutation();

  console.log({ status });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      freezingThreshold: status?.settings.freezing_threshold?.toString(),
      memoryAllocation: status?.settings.memory_allocation?.toString(),
      computeAllocation: status?.settings.compute_allocation?.toString(),
      reservedCyclesLimit: status?.settings.reserved_cycles_limit?.toString(),
      wasmMemoryLimit: status?.settings.wasm_memory_limit?.toString(),
      logVisibility: status?.settings.log_visibility
        ? "controllers" in status.settings.log_visibility
          ? "controllers"
          : "public"
        : undefined,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const settings: Parameters<typeof settingsMutation.mutate>[0]["settings"] =
      {};

    if (values.freezingThreshold) {
      settings.freezingThreshold = BigInt(values.freezingThreshold);
    }
    if (values.memoryAllocation) {
      settings.memoryAllocation = BigInt(values.memoryAllocation);
    }
    if (values.computeAllocation) {
      settings.computeAllocation = BigInt(values.computeAllocation);
    }
    if (values.reservedCyclesLimit) {
      settings.reservedCyclesLimit = BigInt(values.reservedCyclesLimit);
    }
    if (values.wasmMemoryLimit) {
      settings.wasmMemoryLimit = BigInt(values.wasmMemoryLimit);
    }
    if (values.logVisibility) {
      settings.logVisibility = values.logVisibility === "controllers" ? 0 : 1;
    }

    settingsMutation.mutate({
      canisterId,
      settings,
    });
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="freezingThreshold"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Freezing Threshold</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter freezing threshold"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Time in seconds until the canister is frozen
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="memoryAllocation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Memory Allocation</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter memory allocation"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>Memory allocation in bytes</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="computeAllocation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Compute Allocation</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter compute allocation"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Compute allocation percentage (0-100)
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="reservedCyclesLimit"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reserved Cycles Limit</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter reserved cycles limit"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Maximum number of cycles to reserve
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="wasmMemoryLimit"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>WASM Memory Limit</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter WASM memory limit"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Maximum WASM memory in bytes
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="logVisibility"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Log Visibility</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select log visibility" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="controllers">
                        Controllers Only
                      </SelectItem>
                      <SelectItem value="public">Public</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Who can view the canister logs
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="self-start"
              loading={settingsMutation.isPending}
              disabled={isLoading}
            >
              Update Settings
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
