import { Principal } from "@dfinity/principal";
import {
  CellContext,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
} from "@tanstack/react-table";
import { CheckIcon } from "lucide-react";
import React from "react";
import { create } from "zustand";

import {
  DataTableColumnHeader,
  DataTableColumnHeaderProps,
} from "@/components/data-table/column-header";
import TableSkeleton from "@/components/table-skeleton";
import { TC, TCtoUSD } from "@/components/tc";
import { Input } from "@/components/ui/input";
import {
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  Table,
} from "@/components/ui/table";
import {
  SubnetMetadata,
  useKnownSubnetsQuery,
} from "@/hooks/queries/canister-creation";
import { cn } from "@/lib/ui-utils";

import useCanisterCreationStore from "./canister-creation-wizard-store";

function IDCell({
  getValue,
}: CellContext<SubnetMetadata, SubnetMetadata["id"]>) {
  const id = getValue().toText();
  return <div className="font-mono text-xs">{id.slice(0, 5)}</div>;
}

function TypeCell({
  getValue,
}: CellContext<SubnetMetadata, SubnetMetadata["subnetType"]>) {
  return <div className="capitalize text-xs">{getValue()}</div>;
}

function NodesCell({
  getValue,
}: CellContext<SubnetMetadata, SubnetMetadata["totalNodes"]>) {
  return <div className="text-xs">{getValue()}</div>;
}

function CostCell({
  getValue,
}: CellContext<SubnetMetadata, SubnetMetadata["cost"]>) {
  return (
    <div className="text-xs text-nowrap">
      <TC value={getValue()} figs={2} includeUnit />{" "}
      <span className="text-muted-foreground">
        (<TCtoUSD value={getValue()} />)
      </span>
    </div>
  );
}

function SelectCell(cell: CellContext<SubnetMetadata, unknown>) {
  const { subnetSelection } = useCanisterCreationStore();
  const isSelected =
    cell.row.original.id.toText() ===
    subnetSelection.selectedSubnetId?.toText();
  return (
    <div className="flex items-center justify-end">
      <div
        className={cn(
          "h-4 w-4 rounded-full border flex items-center justify-center",
          isSelected
            ? "border-primary bg-primary"
            : "border-muted-foreground/20"
        )}
      >
        {isSelected && <CheckIcon className="h-4 w-4 text-background" />}
      </div>
    </div>
  );
}

interface SubnetTableState {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

const useSubnetTableState = create<SubnetTableState>((set) => ({
  globalFilter: "",
  setGlobalFilter: (value) => set({ globalFilter: value }),
}));

function Header<TData, TValue>({
  column,
}: DataTableColumnHeaderProps<TData, TValue>) {
  return (
    <div className="py-1">
      <DataTableColumnHeader
        column={column}
        className="text-xs px-2 py-0 h-8 rounded-sm"
      />
    </div>
  );
}

function SearchHeader() {
  const { globalFilter, setGlobalFilter } = useSubnetTableState();

  return (
    <Input
      placeholder="Search subnets..."
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value)}
      className="h-7 text-xs w-[150px]"
    />
  );
}

const column = createColumnHelper<SubnetMetadata>();

const columns = [
  column.accessor("id", {
    id: "id",
    cell: IDCell,
    enableSorting: true,
    header: Header,
  }),
  column.accessor("subnetType", {
    id: "Type",
    cell: TypeCell,
    enableSorting: true,
    header: Header,
  }),
  column.accessor("totalNodes", {
    id: "Nodes",
    cell: NodesCell,
    enableSorting: true,
    header: Header,
  }),
  column.accessor("cost", {
    id: "Cost",
    cell: CostCell,
    enableSorting: true,
    header: Header,
  }),
  column.display({
    id: "select",
    header: SearchHeader,
    cell: SelectCell,
    enableSorting: false,
    size: 200,
  }),
];

function DataTable() {
  const subnetSelection = useCanisterCreationStore((s) => s.subnetSelection);
  const { data: subnets, isLoading } = useKnownSubnetsQuery();
  const { globalFilter } = useSubnetTableState();

  const [sorting, setSorting] = React.useState([{ id: "Cost", desc: false }]);

  const table = useReactTable<SubnetMetadata>({
    data: subnets ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter },
    initialState: { globalFilter },
    globalFilterFn: (row, columnId, filterValue) => {
      if (typeof filterValue === "string") {
        return (
          row.original.id
            .toText()
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          row.original.subnetType
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      }
      return false;
    },
  });

  if (isLoading) return <TableSkeleton />;

  return (
    <Table>
      <TableHeader className="sticky top-0 h-6 py-0 ring-1 ring-border/50 bg-background">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="h-6 py-0">
            {headerGroup.headers.map((header) => (
              <TableHead
                key={header.id}
                className="py-1 text-xs font-medium h-6 py-0"
                style={{ width: header.column.getSize() }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              className={cn(
                "cursor-pointer h-8 hover:bg-muted/30",
                subnetSelection.selectedSubnetId?.toText() ===
                  row.original.id.toText() &&
                  "dark:bg-blue-950 bg-blue-50 ring-1 ring-blue-600 sticky top-10 bottom-0"
              )}
              onClick={() =>
                subnetSelection.setSelectedSubnetId(row.original.id)
              }
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="py-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="h-8 text-center text-sm"
            >
              No subnets found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default function SubnetTable() {
  const subnet = useCanisterCreationStore(
    (s) => s.subnetSelection.selectedSubnetId
  );
  return (
    <div className="flex-1 flex flex-col overflow-hidden gap-2">
      <div className="flex-1 border rounded-sm overflow-y-auto">
        <DataTable />
      </div>
      <div className="text-xs text-muted-foreground">
        {subnet
          ? `Canister will be deployed to subnet ${subnet
              .toText()
              .slice(0, 23)}...`
          : "Select a subnet to deploy with."}
      </div>
    </div>
  );
}
