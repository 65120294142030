import { Principal } from "@dfinity/principal";
import { z } from "zod";
import { create } from "zustand";

import {
  CreateCanisterRequest,
  fetchSubnetMetadata,
} from "@/hooks/queries/canister-creation";
import { fetchCanisterMetadata } from "@/hooks/queries/internet-computer";

// State machine definitions

interface CanisterCreationStateMetadata {
  id: string;
  title: string;
  description?: string;
  helpText?: string;
}

const SelectionStrategySchema = z.object({
  value: z.enum(["default", "with-canister", "specify"]),
  label: z.string(),
});

type SelectionStrategy = z.infer<typeof SelectionStrategySchema>;

export const subnetOptions = [
  {
    value: "default" as const,
    label: "Let the system choose",
  },
  {
    value: "with-canister" as const,
    label: "Deploy with an existing canister",
  },
  {
    value: "specify" as const,
    label: "Choose a specific subnet",
  },
] as const;

const states = [
  {
    id: "subnet-selection",
    title: "Select Subnet",
    description: "Where would you like to deploy this canister?",
    helpText:
      "Replication rate and load can vary between subnets, and canisters on the same subnet can talk to each other more easily.",
  },
  {
    id: "controller-assignment",
    title: "Assign Controllers",
    description: "Who should control this canister?",
    helpText:
      "Controllers can start, stop, update and destroy the canister, and manage its settings.",
  },
  {
    id: "cycles-allocation",
    title: "Allocate Cycles",
    description: "How many cycles would you like to allocate to this canister?",
    helpText:
      "Cycles are the unit of computation on the Internet Computer. They are used to pay for the execution of canisters.",
  },
  // {
  //   id: "review",
  //   title: "Review",
  //   description: "Review canister creation details",
  // },
  {
    id: "execution",
    title: "Execution",
  },
  {
    id: "success",
    title: "Canister Created Successfully!",
  },
  {
    id: "failure",
    title: "Failed to Create Canister",
  },
] as const;

type CanisterCreationState = (typeof states)[number]["id"];

export { type CanisterCreationState };

// State machine logic

function indexOfState(state: CanisterCreationState) {
  const index = states.findIndex((s) => s.id === state);
  if (index === -1) {
    throw new Error(`Invalid state: ${state}`);
  }
  return index;
}

function nextState(state: CanisterCreationState) {
  if (["execution", "success", "failure"].includes(state)) {
    return state;
  }
  return states[indexOfState(state) + 1]!.id;
}

function backState(state: CanisterCreationState) {
  if (indexOfState(state) === 0) return state;
  return states[indexOfState(state) - 1]!.id;
}

// Store

interface DialogState {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface MachineState {
  state: CanisterCreationState;
  stateMetadata: CanisterCreationStateMetadata;
  direction: -1 | 0 | 1;
  next: () => void;
  back: () => void;
  canBack: () => boolean;
  canNext: () => boolean;
  reset: () => void;
  set: (state: CanisterCreationState, direction?: 1 | 0 | -1) => void;
}

interface SubnetSelectionState {
  strategy: SelectionStrategy["value"];
  setStrategy: (strategy: SelectionStrategy["value"]) => void;
  selectedCanisterId?: Principal;
  setSelectedCanisterId: (id?: Principal) => void;
  selectedSubnetId?: Principal;
  setSelectedSubnetId: (id?: Principal) => void;
  reset: () => void;
}

interface ControllerAssignmentState {
  teamMembers: Principal[];
  others: Principal[];
  setTeamMembers: (teamMembers: Principal[]) => void;
  setOthers: (others: Principal[]) => void;
  reset: () => void;
}

interface CyclesAllocationState {
  additionalCycles: bigint;
  setAdditionalCycles: (cycles: bigint) => void;
  reset: () => void;
}

interface ExecutionState {
  createdCanisterId?: Principal;
  error?: Error;
  setError: (error?: Error) => void;
  setCreatedCanisterId: (id?: Principal) => void;
  reset: () => void;
}

interface CanisterCreationStore {
  dialog: DialogState;
  machine: MachineState;
  subnetSelection: SubnetSelectionState;
  controllerAssignment: ControllerAssignmentState;
  cyclesAllocation: CyclesAllocationState;
  execution: ExecutionState;
  reset: () => void;
}

const useCanisterCreationStore = create<CanisterCreationStore>((set, get) => ({
  dialog: {
    isOpen: false,
    setIsOpen: (isOpen) => {
      if (isOpen) return;
      get().dialog.close();
      get().machine.reset();
      get().subnetSelection.reset();
    },
    open: () => set(({ dialog }) => ({ dialog: { ...dialog, isOpen: true } })),
    close: () =>
      set(({ dialog }) => ({ dialog: { ...dialog, isOpen: false } })),
  },

  machine: {
    state: states[0].id,
    stateMetadata: states[0],
    direction: 0,
    set: (state, direction = 1) =>
      set(({ machine }) => ({
        machine: {
          ...machine,
          state,
          direction,
          stateMetadata: states.find((s) => s.id === state)!,
        },
      })),
    next: () => get().machine.set(nextState(get().machine.state), 1),
    back: () => get().machine.set(backState(get().machine.state), -1),
    canBack: () => backState(get().machine.state) !== get().machine.state,
    canNext: () => nextState(get().machine.state) !== get().machine.state,
    reset: () => get().machine.set(states[0].id, 0),
  },

  subnetSelection: {
    strategy: "default",
    setStrategy: (strategy) =>
      set(({ subnetSelection }) => ({
        subnetSelection: { ...subnetSelection, strategy },
      })),
    selectedCanisterId: undefined,
    setSelectedCanisterId: (id) =>
      set(({ subnetSelection }) => ({
        subnetSelection: {
          ...subnetSelection,
          selectedCanisterId: id,
        },
      })),
    selectedSubnetId: undefined,
    setSelectedSubnetId: (id) =>
      set(({ subnetSelection }) => ({
        subnetSelection: {
          ...subnetSelection,
          selectedSubnetId: id,
        },
      })),
    reset: () =>
      set(({ subnetSelection }) => ({
        subnetSelection: {
          ...subnetSelection,
          strategy: "default",
          selectedCanisterId: undefined,
          selectedSubnetId: undefined,
        },
      })),
  },

  controllerAssignment: {
    teamMembers: [],
    others: [],
    setTeamMembers: (teamMembers) =>
      set(({ controllerAssignment }) => ({
        controllerAssignment: { ...controllerAssignment, teamMembers },
      })),
    setOthers: (others) =>
      set(({ controllerAssignment }) => ({
        controllerAssignment: { ...controllerAssignment, others },
      })),
    reset: () =>
      set(({ controllerAssignment }) => ({
        controllerAssignment: {
          ...controllerAssignment,
          teamMembers: [],
          others: [],
        },
      })),
  },

  cyclesAllocation: {
    additionalCycles: 100_000_000_000n,
    setAdditionalCycles: (cycles) =>
      set(({ cyclesAllocation }) => ({
        cyclesAllocation: { ...cyclesAllocation, additionalCycles: cycles },
      })),
    reset: () =>
      set(({ cyclesAllocation }) => ({
        cyclesAllocation: { ...cyclesAllocation, additionalCycles: BigInt(0) },
      })),
  },

  execution: {
    createdCanisterId: undefined,
    error: undefined,
    setError: (error) =>
      set(({ execution }) => ({ execution: { ...execution, error } })),
    setCreatedCanisterId: (id) =>
      set(({ execution }) => ({
        execution: { ...execution, createdCanisterId: id },
      })),
    reset: () =>
      set(({ execution }) => ({
        execution: {
          ...execution,
          createdCanisterId: undefined,
          error: undefined,
        },
      })),
  },

  reset: () => {
    const g = get();
    g.machine.reset();
    g.subnetSelection.reset();
    g.controllerAssignment.reset();
    g.cyclesAllocation.reset();
    g.execution.reset();
  },
}));

async function mapToRequest(
  store: CanisterCreationStore
): Promise<CreateCanisterRequest> {
  const subnet = await (async () => {
    if (store.subnetSelection.strategy === "default") return undefined;
    if (store.subnetSelection.selectedSubnetId)
      return store.subnetSelection.selectedSubnetId;
    if (!store.subnetSelection.selectedCanisterId) return undefined;
    const call = await fetchCanisterMetadata(
      store.subnetSelection.selectedCanisterId!.toText()
    );
    return Principal.fromText(call.subnet);
  })();

  const subnetMetadata = (await fetchSubnetMetadata()).find(
    (s) => s.id.toText() === subnet?.toText()
  );

  const creationCost = subnetMetadata?.cost ?? BigInt(500_000_000_000n);

  return {
    controllers: [
      // Self included automatically on BE
      ...store.controllerAssignment.teamMembers,
      ...store.controllerAssignment.others,
    ],
    subnetSelection: subnet,
    withStartingCyclesBalance: store.cyclesAllocation.additionalCycles,
  };
}

export { mapToRequest };
export default useCanisterCreationStore;
