import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useIdp } from "@/state/stores/idp";

interface ConnectionTtlChangeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ConnectionTtlChangeDialog({
  open,
  onOpenChange,
}: ConnectionTtlChangeDialogProps) {
  const { disconnect } = useIdp();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Session Duration Updated</DialogTitle>
          <DialogDescription>
            Your session duration setting has been updated. For this change to
            take effect, you need to sign out and sign back in.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={disconnect}>Sign Out</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
