import { Principal } from "@dfinity/principal";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import {
  MonitoringCanisterType,
  TopupRule,
} from "common/declarations/cycleops/cycleops.did.d";

import { mostRecentCycleBalanceFromStatusHistory } from "@/components/pages/canister-list";
import { Status } from "@/components/status-indicator";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  TopUpRuleFormRoot,
  TopUpRuleFormFields,
  TopUpRuleFormErrors,
  TopUpRuleFormSubmit,
  TopUpRuleFormValues,
} from "@/hooks/forms/top-up-rule";
import {
  UseCanistersResult,
  useCanisterTopupRuleMutation,
  useCanistersQuery,
} from "@/hooks/queries/canisters";

import { useFindCanisterOr404 } from "./pages/canister-detail";

export interface Canister {
  id: Principal;
  name?: string;
  rule: TopupRule;
  cycles?: {
    status: Status;
    balance: number;
    timestamp: Date;
  };
  monitoringType?: "blackhole" | "sns" | "nns" | "publicStatus";
}

export function mapMonitoringType(mtype: MonitoringCanisterType) {
  if (mtype === undefined) return undefined;
  if ("blackhole" in mtype) return "blackhole";
  if ("sns" in mtype) return "sns";
  if ("nns" in mtype) return "nns";
  if ("publicStatus" in mtype) return "publicStatus";
  throw new Error("Unknown monitoring type");
}

export function reduceCanister(
  state: UseCanistersResult,
  canisterId: string
): Canister | undefined {
  const canister = state.data?.find(([id]) => id.toText() === canisterId);
  if (!canister) return undefined;
  const [id, conf, statusRecords, , mtype] = canister;
  return {
    id,
    name: conf.name,
    rule: conf.topupRule,
    cycles: mostRecentCycleBalanceFromStatusHistory(statusRecords, conf),
    monitoringType: mapMonitoringType(mtype),
  };
}

export default function CanisterRule() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const { mutate, isPending } = useCanisterTopupRuleMutation();
  const canisters = useCanistersQuery();
  const canister = reduceCanister(canisters, canisterId!);

  // Get initial values from the canister's current rule
  const defaultValues = React.useMemo(() => {
    if (!canister) return undefined;

    const method =
      "by_amount" in canister.rule.method
        ? ("by_amount" as const)
        : ("to_balance" as const);
    const amount =
      "by_amount" in canister.rule.method
        ? Number(canister.rule.method.by_amount) / 1e12
        : Number(canister.rule.method.to_balance) / 1e12;

    return {
      threshold: Number(canister.rule.threshold) / 1e12,
      amount,
      method,
    };
  }, [canister]);

  const handleSubmit = React.useCallback(
    (values: TopUpRuleFormValues) => {
      if (!canister?.id) {
        toast.error("Canister ID is required");
        return;
      }

      mutate({
        canisterIds: [canister.id],
        rule: {
          threshold: BigInt(values.threshold * 1e12),
          method:
            values.method === "by_amount"
              ? { by_amount: BigInt(values.amount * 1e12) }
              : { to_balance: BigInt(values.amount * 1e12) },
        },
      });
    },
    [canister?.id, mutate]
  );

  return (
    <div className="pb-4">
      <div className="flex flex-col">
        <TopUpRuleFormRoot
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          loading={isPending}
        >
          <Card className="">
            <CardHeader>
              <CardTitle>Rule</CardTitle>
              <CardDescription>
                These parameters allow you to determine when to send more cycles
                to this canister.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <TopUpRuleFormFields />
              <TopUpRuleFormErrors />
            </CardContent>
            <CardFooter className="flex justify-between items-center w-full">
              <small>
                Learn more about{" "}
                <a
                  href="https://docs.cycleops.dev/docs/basics/topup-rules"
                  target="_blank"
                >
                  best practices for top-up rules
                </a>
              </small>
              <TopUpRuleFormSubmit className="w-auto">Save</TopUpRuleFormSubmit>
            </CardFooter>
          </Card>
        </TopUpRuleFormRoot>
      </div>
    </div>
  );
}
