import { AnimatePresence, motion } from "framer-motion";
import { Info } from "lucide-react";
import { useMemo } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogOverlay,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/ui-utils";

import useCanisterCreationStore from "./canister-creation-wizard-store";
import mapStateToComponent from "./steps/create-canister-steps";

function WizardDialog({ children }: { children: React.ReactNode }) {
  const {
    machine: { next, back, canBack, canNext, direction, stateMetadata },
  } = useCanisterCreationStore();
  return (
    <DialogContent
      className={cn(
        "overflow-hidden max-w-none md:w-[680px] h-[500px] flex flex-col"
      )}
      noOverlay
    >
      <DialogHeader>
        <DialogTitle className="flex items-baseline gap-2">
          {stateMetadata.title}
          {stateMetadata.helpText && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="w-4 h-4  cursor-help" />
              </TooltipTrigger>
              <TooltipContent
                align="start"
                alignOffset={-10}
                side="right"
                sideOffset={10}
                className="max-w-[400px] font-normal"
              >
                {stateMetadata.helpText}
              </TooltipContent>
            </Tooltip>
          )}
        </DialogTitle>
        {stateMetadata.description && (
          <DialogDescription>{stateMetadata.description}</DialogDescription>
        )}
      </DialogHeader>

      <motion.div
        key={stateMetadata.id}
        initial={{ x: direction * 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: direction * -50, opacity: 0 }}
        transition={{ duration: 0.32, ease: "easeInOut" }}
        className="flex-1 relative h-full w-full overflow-hidden"
      >
        {children}
      </motion.div>
    </DialogContent>
  );
}

export default function CanisterCreationWizard() {
  const {
    machine: { state },
    dialog: { isOpen, setIsOpen },
  } = useCanisterCreationStore();
  const Component = useMemo(
    () => <WizardDialog>{mapStateToComponent(state)}</WizardDialog>,
    [state]
  );

  if (!Component) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogOverlay className="" />
      <AnimatePresence mode="wait">{Component}</AnimatePresence>
    </Dialog>
  );
}

export function DefaultButtons() {
  const {
    machine: { next, back, canBack, canNext, direction, stateMetadata },
  } = useCanisterCreationStore();
  return (
    <div className="flex gap-2">
      <Button onClick={() => back()} disabled={!canBack()}>
        Back
      </Button>
      <Button onClick={() => next()} disabled={!canNext()}>
        Next
      </Button>
    </div>
  );
}
