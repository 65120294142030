import { Principal } from "@dfinity/principal";
import {
  ArrowUpCircle,
  BatteryCharging,
  BatteryWarning,
  Box,
  CheckCircle2,
  Cpu,
  Settings,
  Tag,
  Trash2,
} from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useBlackholeStore } from "@/components/pages/blackhole-upgrade";
import {
  useAllCanistersTags,
  useCanisterProject,
  useCanisterTags,
  useCanistersProject,
  useCanistersTags,
  useBatchCanisterDeleteMutation,
  useCanisterTableDetailQuery,
  useCanisterTableQuery,
  useBatchCanisterMemoryThresholdMutation,
  useBatchCanisterReservedCyclesThresholdMutation,
} from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useBatchCanisterTagMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { useBatchCanisterTopupMutation } from "@/hooks/queries/top-up";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";

import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuSub,
  ContextMenuSubTrigger,
  ContextMenuSubContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuCheckboxItem,
  ContextMenuInputItem,
} from "../../ui/context-menu";
import { useDeleteCommand } from "../commands/delete-command";
import { useMemoryAlertCommand } from "../commands/memory-alert-command";
import { useReservedCyclesAlertCommand } from "../commands/reserved-cycles-alert-command";
import { useTopupCommand } from "../commands/topup-command";
import { useTopupRuleCommand } from "../commands/topup-rule-command";
import { useVerifyCommand } from "../commands/verify-command";

function CanisterListContextMenu({
  selected,
  canisterId,
  children,
}: {
  selected?: boolean;
  canisterId: Principal;
  children?: React.ReactNode;
}) {
  const setRowSelection = useCanisterTableStore(
    (state) => state.setRowSelection
  );
  return (
    <ContextMenu>
      <ContextMenuTrigger
        asChild
        onContextMenu={(e) => {
          if (!selected) {
            setRowSelection({});
          }
        }}
      >
        {children}
      </ContextMenuTrigger>
      <ContextMenuContent className="w-[240px]">
        <ManualTopupItem canisterId={canisterId} />
        <ContextMenuSeparator />
        <VerifyMenuItem canisterId={canisterId} />
        <UpgradeBlacholeMenuItem canisterId={canisterId} />
        <ContextMenuSub>
          <ContextMenuSubTrigger className="gap-2">
            <Box className="w-4 h-4" />
            Project
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ProjectMenu canisterId={canisterId} />
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSub>
          <ContextMenuSubTrigger className="gap-2">
            <Tag className="w-4 h-4" />
            Tags
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <TagsMenu canisterId={canisterId} />
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSeparator />
        <TopupRuleMenuItem canisterId={canisterId} />
        <MemoryAlertMenuItem canisterId={canisterId} />
        <ReservedCyclesAlertMenuItem canisterId={canisterId} />
        <ContextMenuSeparator />
        <DeleteMenuItem canisterId={canisterId} />
      </ContextMenuContent>
    </ContextMenu>
  );
}

export function useSelectedCanisters() {
  const rowSelection = useCanisterTableStore((state) => state.rowSelection);
  const idMap = useCanisterTableStore((state) => {
    if (!state.table.getRowModel) return {};
    return state.table.getRowModel().rowsById;
  });
  const selection = Object.entries(rowSelection).map(([id]) => id);
  const selectedCanisters = selection
    .map((id) => idMap[id]?.original.id)
    .filter((x) => !!x);

  return selectedCanisters;
}

function TopupRuleMenuItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useTopupRuleCommand();

  return (
    <ContextMenuItem
      className="gap-2"
      onSelect={(e) => {
        e.preventDefault();
        setCanisterId(canisterId);
        open();
      }}
    >
      <BatteryWarning className="w-4 h-4" />
      Set top-up rule
    </ContextMenuItem>
  );
}

function NewTag({ canisterId }: { canisterId: Principal }) {
  const selectedCanisters = useSelectedCanisters();
  const canisterTags = useBatchCanisterTagMutation();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  function handle(tag: string) {
    canisterTags.mutate({
      canisterIds,
      tag,
      type: "add",
    });
  }

  return (
    <ContextMenuInputItem
      className="gap-2"
      onSubmit={handle}
      placeholder="New tag"
    />
  );
}

function TagsMenu({ canisterId }: { canisterId: Principal }) {
  const selectedCanisters = useSelectedCanisters();

  const existingTags = useAllCanistersTags();
  const canisterTags = useCanisterTags(canisterId);
  const selectionTags = useCanistersTags(selectedCanisters);

  const mutation = useBatchCanisterTagMutation();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds,
      tag,
      type,
    });
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = selectedCanisters.length
        ? selectionTags.data?.[tag]
        : !!canisterTags.data?.includes(tag);
      return { tag, checked };
    })
    .filter(
      (tag): tag is { tag: string; checked: boolean | "indeterminate" } => !!tag
    );

  return (
    <>
      {tags?.map(({ tag, checked }) => (
        <ContextMenuCheckboxItem
          box
          checked={checked}
          onSelect={(e) => {
            handleTags(tag, checked === true ? "remove" : "add");
            e.preventDefault();
          }}
          className="gap-2 items-center"
          key={tag}
        >
          {tag}
        </ContextMenuCheckboxItem>
      ))}
      {!!tags?.length && <ContextMenuSeparator />}
      <NewTag canisterId={canisterId} />
    </>
  );
}

function ManageProjects() {
  return (
    <ContextMenuItem asChild>
      <AppLink to="/projects" className="gap-2">
        <Settings className="w-4 h-4" />
        Manage Projects
      </AppLink>
    </ContextMenuItem>
  );
}

function ProjectMenu({
  canisterId,
  components = { Separator: ContextMenuSeparator },
}: {
  canisterId: Principal;
  components?: {
    Separator: React.ComponentType;
  };
}) {
  const selectedCanisters = useSelectedCanisters();

  const existingProjects = useProjectsQuery();
  const canisterProject = useCanisterProject(canisterId);
  const selectionProjects = useCanistersProject(selectedCanisters);

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  const mutation = useBatchCanisterProjectMutation();

  function handleProject(project: string) {
    const checked = selectedCanisters.length
      ? selectionProjects.data?.[project]
      : !!canisterProject.data?.includes(project);
    mutation.mutate({
      canisterIds,
      projectName: checked === true ? undefined : project,
    });
  }

  const projects = existingProjects.data?.map((project) => {
    const checked = selectedCanisters.length
      ? selectionProjects.data?.[project.name]
      : !!canisterProject.data?.includes(project.name);
    return { project: project.name, checked };
  });

  const { Separator } = components;

  return (
    <>
      {projects?.map(({ project, checked }) => (
        <ContextMenuCheckboxItem
          checked={checked}
          onSelect={(e) => {
            handleProject(project);
            e.preventDefault();
          }}
          className="gap-2 items-center"
          key={project}
        >
          {project}
        </ContextMenuCheckboxItem>
      ))}
      {!!projects?.length && <Separator />}
      <ManageProjects />
    </>
  );
}

function DeleteMenuItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useDeleteCommand();
  const mutation = useBatchCanisterDeleteMutation();

  return (
    <ContextMenuItem
      className="gap-2 text-destructive hover:text-destructive hover:bg-destructive/10 hover:ring-1 hover:ring-destructive/30 focus:text-destructive focus:bg-destructive/10 focus:ring-1 focus:ring-destructive/30"
      disabled={mutation.isPending}
      onSelect={(e) => {
        e.preventDefault();
        setCanisterId(canisterId);
        open();
      }}
    >
      <Trash2 className="w-4 h-4" />
      Delete
    </ContextMenuItem>
  );
}

function MemoryAlertMenuItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useMemoryAlertCommand();
  const mutation = useBatchCanisterMemoryThresholdMutation();

  return (
    <ContextMenuItem
      className="gap-2"
      disabled={mutation.isPending}
      onSelect={(e) => {
        e.preventDefault();
        setCanisterId(canisterId);
        open();
      }}
    >
      <Cpu className="w-4 h-4" />
      Set memory alert
    </ContextMenuItem>
  );
}

function ReservedCyclesAlertMenuItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useReservedCyclesAlertCommand();
  const mutation = useBatchCanisterReservedCyclesThresholdMutation();

  return (
    <ContextMenuItem
      className="gap-2"
      disabled={mutation.isPending}
      onSelect={(e) => {
        e.preventDefault();
        setCanisterId(canisterId);
        open();
      }}
    >
      <BatteryWarning className="w-4 h-4" />
      Set reserved cycles alert
    </ContextMenuItem>
  );
}

function VerifyMenuItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useVerifyCommand();
  // const mutation = useBatchCanisterVerifyMutation();

  const canister = useCanisterTableDetailQuery(canisterId);

  const isCanisterPending =
    canister.data === undefined || canister.data.status === "pending";

  if (!isCanisterPending) return null;

  return (
    <>
      <ContextMenuItem
        className="gap-2"
        // disabled={mutation.isPending}
        onSelect={(e) => {
          e.preventDefault();
          setCanisterId(canisterId);
          open();
        }}
      >
        <CheckCircle2 className="w-4 h-4" />
        Finish setup
      </ContextMenuItem>
      <ContextMenuSeparator />
    </>
  );
}
function UpgradeBlacholeMenuItem({ canisterId }: { canisterId: Principal }) {
  const selectedCanisters = useSelectedCanisters();
  const { setSelectedCanisters } = useBlackholeStore();
  const navigate = useNavigate();
  const route = useRoute();
  const canisters = useCanisterTableQuery();

  const elligibleCanisters = canisters.data?.filter((canister) => {
    const cans = selectedCanisters.length ? selectedCanisters : [canisterId];
    if (!cans.map((x) => x.toText()).includes(canister.id.toText()))
      return false;
    return (
      "blackhole" in canister.monitoringMechanism &&
      canister.monitoringMechanism.blackhole === 1n
    );
  });

  function handle() {
    setSelectedCanisters(elligibleCanisters?.map((x) => x.id) ?? []);
    navigate(route("blackhole-upgrade"));
  }

  if (!elligibleCanisters?.length) return null;

  return (
    <>
      <ContextMenuItem
        className="gap-2"
        onSelect={(e) => {
          e.preventDefault();
          handle();
        }}
      >
        <ArrowUpCircle className="w-4 h-4" />
        Upgrade blackhole
      </ContextMenuItem>
      <ContextMenuSeparator />
    </>
  );
}

function ManualTopupItem({ canisterId }: { canisterId: Principal }) {
  const { open, setCanisterId } = useTopupCommand();
  const mutation = useBatchCanisterTopupMutation();

  return (
    <ContextMenuItem
      className="gap-2"
      disabled={mutation.isPending}
      onSelect={(e) => {
        e.preventDefault();
        setCanisterId(canisterId);
        open();
      }}
    >
      <BatteryCharging className="w-4 h-4" />
      Topup now
    </ContextMenuItem>
  );
}

export { CanisterListContextMenu };
