import { ReductionStrategy, TimePoint, reduce } from "./reduction";
import { DataSelector, selectValue } from "./selection";
import {
  TimeFrame,
  getTimeFrameBoundaries,
  DEFAULT_TIME_FRAME,
} from "./time-frame";
import {
  DEFAULT_TIME_RESOLUTION,
  TimeResolution,
  groupTimeSeriesData,
} from "./time-resolution";

export interface InsightConfig<Status, T, R = T> {
  /** Selector for extracting values from MergedCanisterStatus */
  selector: DataSelector<Status>;
  /** Strategy for reducing grouped values */
  reduction: ReductionStrategy<T, R>;
}

export interface InsightOptions {
  /** Optional time frame for filtering data points */
  timeFrame?: TimeFrame;
  /** Optional time resolution override */
  timeResolution?: TimeResolution;
}

export interface InsightResult<R> {
  /** Grouped and reduced values with their time boundaries */
  points: [Date, R | undefined][];
}

/**
 * Creates an insight function that can be applied to a series of canister statuses
 */
export function defineTimeSeriesInsight<Input, Selected, Output = Selected>(
  config: InsightConfig<Input, Selected, Output>
): (
  statuses: [Date, Input][],
  options?: InsightOptions
) => InsightResult<Output> {
  return (statuses, options = {}) => {
    // Filter data points based on time frame
    const timeResolution = options.timeResolution ?? DEFAULT_TIME_RESOLUTION;
    const timeFrame = options.timeFrame ?? DEFAULT_TIME_FRAME;
    if (timeFrame.type === "all") {
      timeFrame.beginning = statuses.length > 0 ? statuses[0]?.[0] : undefined;
    }
    const [start, end] = getTimeFrameBoundaries(timeFrame);
    const filteredStatuses = statuses.filter(
      ([date]) => date >= start && date <= end
    );

    // Extract values using selector
    const selectedPoints: [Date, Selected][] = filteredStatuses.map(
      ([date, status]) => [
        date,
        selectValue(status, config.selector) as Selected,
      ]
    );

    // Group by time resolution
    const groups = groupTimeSeriesData(
      selectedPoints,
      timeResolution,
      timeFrame
    );

    // Convert groups to array and reduce each group
    const reducedPoints: [Date, Output | undefined][] = Array.from(
      groups.entries()
    ).map(([timeKey, points], index) => [
      new Date(timeKey),
      reduce(
        points as TimePoint<Selected>[],
        config.reduction,
        [...groups.values()],
        index
      ),
    ]);

    // Sort by date
    reducedPoints.sort(([dateA], [dateB]) => dateA.getTime() - dateB.getTime());

    return {
      points: reducedPoints,
    };
  };
}
