import { Principal } from "@dfinity/principal";
import { Info } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { create } from "zustand";

import { useCanistersWithOldBlackhole } from "@/components/blackhole-migration-modal";
import {
  DFX_COMMAND_REST_INTERVAL_SECONDS,
  DFX_PARALLEL_COMMANDS,
} from "@/components/canister-list/filters/constants";
import {
  MemoizedID,
  MemoizedName,
  MemoizedProject,
  MemoizedTags,
} from "@/components/canister-list/table/cells";
import Code from "@/components/code";
import LayoutFocus, { FocusTopbar } from "@/components/layout/layout-focus";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Steps } from "@/components/ui/steps";
import { createBinding, useKeyMap } from "@/hooks/keyboard";
import { useVerifyBlackholeBulkMutation } from "@/hooks/queries/canisters";
import {
  useBalanceChecker,
  useOldBalanceChecker,
} from "@/hooks/queries/cycleops-service";
import { useRoute } from "@/hooks/queries/team";
import { ic } from "@/lib/actors";
import { cn } from "@/lib/ui-utils";

interface StepProps {
  onComplete: () => void;
  onBack?: () => void;
}

interface BlackholeStore {
  currentStep: number;
  selectedCanisters: Principal[];
  searchQuery: string;
  highlightedIndex: number;
  toggleCanister: (canister: Principal) => void;
  setSelectedCanisters: (canisters: Principal[]) => void;
  setCurrentStep: (step: number) => void;
  setSearchQuery: (query: string) => void;
  setHighlightedIndex: (index: number) => void;
}

export const useBlackholeStore = create<BlackholeStore>((set) => ({
  currentStep: 0,
  selectedCanisters: [],
  searchQuery: "",
  highlightedIndex: -1,
  toggleCanister: (canister) =>
    set((state) => ({
      selectedCanisters: state.selectedCanisters.includes(canister)
        ? state.selectedCanisters.filter(
            (c) => c.toText() !== canister.toText()
          )
        : [...state.selectedCanisters, canister],
    })),
  setSelectedCanisters: (canisters) => set({ selectedCanisters: canisters }),
  setCurrentStep: (step) => set({ currentStep: step }),
  setSearchQuery: (query) => set({ searchQuery: query }),
  setHighlightedIndex: (index) => set({ highlightedIndex: index }),
}));

const steps = [
  {
    title: "Select Canisters",
    description: "Choose which canisters to upgrade",
    component: SelectStep,
  },
  {
    title: "Add New Blackhole",
    description: "Add the new blackhole controller",
    component: Step2,
  },
  {
    title: "Remove Old Blackhole",
    description: "Clean up the old blackhole controller",
    component: Step3,
  },
];

function Step1({ onComplete }: StepProps) {
  return (
    <div className="space-y-4">
      <p>
        It looks like you're using an old version of the Blackhole to monitor
        your canisters. After completing this upgrade, new metrics will be
        collected during monitoring.
      </p>
      <Button onClick={onComplete}>Begin</Button>
    </div>
  );
}

function mapCanisterStatus(
  status?: "stopped" | "stopping" | "running"
): "healthy" | "low balance" | "frozen" | "pending" {
  if (!status) return "pending";
  switch (status) {
    case "running":
      return "healthy";
    case "stopped":
      return "frozen";
    case "stopping":
      return "low balance";
    default:
      return "pending";
  }
}

function SelectStep({ onComplete, onBack }: StepProps) {
  const {
    selectedCanisters,
    toggleCanister,
    setSelectedCanisters,
    searchQuery,
    setSearchQuery,
    highlightedIndex,
    setHighlightedIndex,
  } = useBlackholeStore();
  const query = useCanistersWithOldBlackhole();

  const handleSelectAll = () => {
    if (selectedCanisters.length === filteredCanisters.length) {
      setSelectedCanisters([]);
    } else {
      setSelectedCanisters(filteredCanisters.map((c) => c.canisterId));
    }
  };

  const filteredCanisters = useMemo(() => {
    if (!query.data) return [];
    if (!searchQuery) return query.data;

    const lowerQuery = searchQuery.toLowerCase();
    return query.data.filter((canister) => {
      const searchableText = [
        canister.canisterId.toText(),
        canister.config.name,
        canister.metadata?.projectName,
        canister.metadata?.keywordTags.join(" "),
      ]
        .filter(Boolean)
        .join(" ")
        .toLowerCase();
      return searchableText.includes(lowerQuery);
    });
  }, [query.data, searchQuery]);

  // Keyboard navigation
  const navigationBindings = [
    createBinding("ArrowUp")
      .withDescription("Move highlight to previous canister")
      .handle(() => {
        const newIndex =
          highlightedIndex === -1 ? 0 : Math.max(0, highlightedIndex - 1);
        setHighlightedIndex(newIndex);
      }),

    createBinding("ArrowDown")
      .withDescription("Move highlight to next canister")
      .handle(() => {
        const newIndex =
          highlightedIndex === -1
            ? 0
            : Math.min(filteredCanisters.length - 1, highlightedIndex + 1);
        setHighlightedIndex(newIndex);
      }),

    createBinding("ArrowUp")
      .withModifiers("shift")
      .withDescription("Select previous canister")
      .handle(() => {
        const newIndex = Math.max(0, highlightedIndex - 1);
        setHighlightedIndex(newIndex);
        if (filteredCanisters[newIndex]) {
          toggleCanister(filteredCanisters[newIndex].canisterId);
        }
      }),

    createBinding("ArrowDown")
      .withModifiers("shift")
      .withDescription("Select next canister")
      .handle(() => {
        const newIndex = Math.min(
          filteredCanisters.length - 1,
          highlightedIndex + 1
        );
        setHighlightedIndex(newIndex);
        if (filteredCanisters[newIndex]) {
          toggleCanister(filteredCanisters[newIndex].canisterId);
        }
      }),

    createBinding("x")
      .withDescription("Toggle selection of highlighted canister")
      .handle(() => {
        if (highlightedIndex !== -1 && filteredCanisters[highlightedIndex]) {
          toggleCanister(filteredCanisters[highlightedIndex].canisterId);
        }
      }),

    createBinding("a")
      .withModifiers("meta")
      .withDescription("Select all canisters")
      .handle(() => {
        setSelectedCanisters(filteredCanisters.map((c) => c.canisterId));
      }),

    createBinding("Escape")
      .withDescription("Deselect all canisters")
      .handle(() => {
        setSelectedCanisters([]);
        setHighlightedIndex(-1);
      }),
  ];

  useKeyMap(navigationBindings);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        {filteredCanisters.map((canister, i) => {
          const isSelected = selectedCanisters.some(
            (c) => c.toText() === canister.canisterId.toText()
          );
          return (
            <div key={canister.canisterId.toText()}>
              <div
                className={cn(
                  "flex items-center space-x-4 p-2 pl-6 cursor-pointer select-none",
                  "hover:bg-muted/50 active:bg-muted/70",
                  i === highlightedIndex && "bg-muted/40",
                  isSelected && "bg-primary/5"
                )}
                onMouseEnter={() => setHighlightedIndex(i)}
                onClick={() => toggleCanister(canister.canisterId)}
              >
                <Checkbox
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCanister(canister.canisterId);
                  }}
                />
                <div className="flex items-center space-x-4 flex-1 min-w-0">
                  <MemoizedID value={canister.canisterId} />
                  <MemoizedName value={canister.config.name} />
                  <MemoizedProject value={canister.metadata?.projectName} />
                  <MemoizedTags value={canister.metadata?.keywordTags || []} />
                </div>
              </div>
              {i < filteredCanisters.length - 1 && <Separator />}
            </div>
          );
        })}
      </div>
      <div className="sticky bottom-0 border-t bg-background p-4 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Button
            onClick={onComplete}
            disabled={selectedCanisters.length === 0}
            className="whitespace-nowrap"
          >
            Continue with {selectedCanisters.length} selected
          </Button>
          <Button
            variant="ghost"
            onClick={handleSelectAll}
            className="whitespace-nowrap"
          >
            {selectedCanisters.length === filteredCanisters.length
              ? "Deselect All"
              : "Select All"}{" "}
            ({filteredCanisters.length})
          </Button>
        </div>
        <Input
          placeholder="Search canisters..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-[200px]"
        />
      </div>
    </div>
  );
}

interface VerificationStatus {
  status: "not_started" | "pending" | "failed" | "completed";
  error?: string;
}

interface VerificationState {
  [canisterId: string]: VerificationStatus;
}

function StatusIndicator({ status }: { status: VerificationStatus["status"] }) {
  return (
    <div
      className={cn(
        "w-2 h-2 rounded-full shrink-0",
        status === "not_started" && "bg-muted-foreground/30",
        status === "pending" && "bg-blue-500 animate-pulse",
        status === "failed" && "bg-destructive",
        status === "completed" && "bg-green-500"
      )}
    />
  );
}

function VerificationList({
  canisters,
  verificationState,
}: {
  canisters: Principal[];
  verificationState: VerificationState;
}) {
  const query = useCanistersWithOldBlackhole();
  const canisterDetails = useMemo(() => {
    if (!query.data) return new Map();
    return new Map(query.data.map((c) => [c.canisterId.toText(), c]));
  }, [query.data]);

  const stats = useMemo(() => {
    const total = canisters.length;
    const completed = Object.values(verificationState).filter(
      (s) => s.status === "completed"
    ).length;
    const failed = Object.values(verificationState).filter(
      (s) => s.status === "failed"
    ).length;
    return { total, completed, failed };
  }, [canisters, verificationState]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-2 py-3 text-sm">
        <div className="flex items-center gap-4 text-muted-foreground">
          {stats.completed > 0 && (
            <span className="flex items-center gap-2">
              <StatusIndicator status="completed" />
              {stats.completed} completed
            </span>
          )}
          {stats.failed > 0 && (
            <span className="flex items-center gap-2">
              <StatusIndicator status="failed" />
              {stats.failed} failed
            </span>
          )}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        {canisters.map((canister) => {
          const status = verificationState[canister.toText()] ?? {
            status: "not_started",
          };
          const details = canisterDetails.get(canister.toText());
          return (
            <div
              key={canister.toText()}
              className="flex items-center space-x-3 px-2 py-1.5 hover:bg-muted/50"
            >
              <StatusIndicator status={status.status} />
              <div className="flex-1 min-w-0">
                <div className="flex items-center space-x-4">
                  {details?.config.name}
                  <span className="text-xs text-muted-foreground">
                    {canister.toText()}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Step2({ onComplete, onBack }: StepProps) {
  const statusChecker = useBalanceChecker();
  const mutation = useVerifyBlackholeBulkMutation();
  const selectedCanisters = useBlackholeStore(
    (state) => state.selectedCanisters
  );
  const [verificationState, setVerificationState] = useState<VerificationState>(
    {}
  );

  async function handleSubmit() {
    if (!selectedCanisters.length) return;

    // Reset state when starting
    setVerificationState(
      Object.fromEntries(
        selectedCanisters.map((c) => [c.toText(), { status: "pending" }])
      )
    );

    // Process each canister individually
    const individualVerify = async (canister: Principal) => {
      try {
        // Use the verify mutation on a single canister
        await mutation.mutateAsync([canister.toText()]);

        // Update state on success
        setVerificationState((prev) => ({
          ...prev,
          [canister.toText()]: { status: "completed" },
        }));

        return true;
      } catch (error) {
        // Update state on failure
        setVerificationState((prev) => ({
          ...prev,
          [canister.toText()]: {
            status: "failed",
            error:
              error instanceof Error ? error.message : "Verification failed",
          },
        }));

        return false;
      }
    };

    // Process canisters in parallel, but limit concurrency
    const concurrencyLimit = 5;

    for (let i = 0; i < selectedCanisters.length; i += concurrencyLimit) {
      const batch = selectedCanisters.slice(i, i + concurrencyLimit);

      // Run verifications in parallel but catch errors for each individual canister
      await Promise.all(batch.map((canister) => individualVerify(canister)));
    }
  }

  useEffect(() => {
    // Check if all canisters are either completed or failed
    const isComplete = selectedCanisters.every((canister) => {
      const status = verificationState[canister.toText()]?.status;
      return status === "completed" || status === "failed";
    });

    // If all verifications are complete (regardless of success/failure)
    if (isComplete) {
      // Filter out the failed canisters, keeping only successfully verified ones
      const successfulCanisters = selectedCanisters.filter(
        (canister) =>
          verificationState[canister.toText()]?.status === "completed"
      );

      // Update the store to only include successful canisters for the next step
      if (successfulCanisters.length > 0) {
        // Only update the selected canisters if at least one was successful
        useBlackholeStore.setState({
          selectedCanisters: successfulCanisters,
        });

        // Show a toast if some canisters failed verification
        const failedCount =
          selectedCanisters.length - successfulCanisters.length;
        if (failedCount > 0) {
          toast.warning(
            `${failedCount} canister${
              failedCount !== 1 ? "s" : ""
            } failed verification and will be skipped. ` +
              `Continuing with ${successfulCanisters.length} verified canister${
                successfulCanisters.length !== 1 ? "s" : ""
              }.`
          );
        }

        // Proceed to next step with the filtered list
        onComplete();
      } else {
        // If all canisters failed verification, show an error but don't proceed
        toast.error(
          "All canisters failed verification. Please check the commands and try again."
        );
      }
    }
  }, [verificationState, selectedCanisters]);

  const dfxCommand = useMemo(() => {
    if (!selectedCanisters?.length) return "";

    const canisterList = selectedCanisters
      .map((c) => `"${c.toText()}"`)
      .join(" ");
    return `canisters=(${canisterList})
printf "%s\\n" "\${canisters[@]}" | xargs -P${DFX_PARALLEL_COMMANDS} -I{} bash -c "
  dfx canister${
    ic.isLocal ? "" : " --ic"
  } update-settings {} --add-controller ${
      statusChecker.data || "BLACKHOLE_CANISTER_ID"
    }
  sleep ${DFX_COMMAND_REST_INTERVAL_SECONDS}
" `;
  }, [selectedCanisters, statusChecker, ic.isLocal]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm">
            1. Use the command below to add the new blackhole to your canisters.
          </div>
          <Code
            copyable={dfxCommand}
            children={dfxCommand}
            className="whitespace-pre overflow-x-auto text-xs w-full max-w-[62vw]"
          />
        </div>
        <Separator />
        <div className="flex flex-col gap-2">
          <div className="text-sm">
            2. Press the button below to verify the new blackhole has been added
            to your canisters.
            <VerificationList
              canisters={selectedCanisters}
              verificationState={verificationState}
            />
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 border-t bg-background p-4 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Button loading={mutation.isPending} onClick={handleSubmit}>
            Verify {selectedCanisters.length} canister
            {selectedCanisters.length > 1 ? "s" : ""}
          </Button>
          <Button variant="ghost" onClick={onBack}>
            Back
          </Button>
        </div>
        <div />
      </div>
    </div>
  );
}

function Step3({ onComplete, onBack }: StepProps) {
  const statusChecker = useOldBalanceChecker();
  const selectedCanisters = useBlackholeStore(
    (state) => state.selectedCanisters
  );

  const dfxCommand = useMemo(() => {
    if (!selectedCanisters?.length) return "";

    const canisterList = selectedCanisters
      .map((c) => `"${c.toText()}"`)
      .join(" ");
    return `canisters=(${canisterList})
printf "%s\\n" "\${canisters[@]}" | xargs -P${DFX_PARALLEL_COMMANDS} -I{} bash -c "
  dfx canister${
    ic.isLocal ? "" : " --ic"
  } update-settings {} --remove-controller ${
      statusChecker.data || "BLACKHOLE_CANISTER_ID"
    }
  sleep ${DFX_COMMAND_REST_INTERVAL_SECONDS}
" `;
  }, [selectedCanisters, statusChecker, ic.isLocal]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm">
            Use the command below to remove the old blackhole from your
            canisters.
          </div>
          <Code
            copyable={dfxCommand}
            children={dfxCommand}
            className="whitespace-pre overflow-x-auto text-xs w-full max-w-[62vw]"
          />
        </div>
      </div>
      <div className="sticky bottom-0 border-t bg-background p-4 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Button onClick={onComplete}>Complete</Button>
          <Button variant="ghost" onClick={onBack}>
            Back
          </Button>
        </div>
        <div /> {/* Empty div to maintain spacing */}
      </div>
    </div>
  );
}

function ProgressSidebar({ currentStep }: { currentStep: number }) {
  return (
    <div className="w-80 border-r bg-muted/10 p-6 space-y-8 flex-shrink-0">
      <div className="space-y-2">
        <h2 className="text-lg font-semibold">Blackhole Upgrade</h2>
        <p className="text-sm text-muted-foreground">
          Take a minute to migrate to the new blackhole to enable new monitoring
          metrics.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        {steps.map((step, index) => (
          <div
            key={index}
            className={cn(
              "cursor-default flex items-center space-x-3 p-3 rounded-md transition-colors bg-muted/10 border border-muted/20",
              currentStep === index
                ? "bg-muted/50 text-primary border-muted-foreground/10"
                : " text-muted-foreground",
              currentStep > index && "text-primary bg-muted/25"
            )}
          >
            <div
              className={cn(
                "flex items-center justify-center w-5 h-5 rounded-full border border-dotted text-[10px] font-bold",
                currentStep === index &&
                  "border-solid border-muted-foreground/25",
                currentStep > index &&
                  "border-none text-background bg-foreground"
              )}
            >
              {index + 1}
            </div>
            <div className="space-y-1">
              <div className="text-sm font-medium">{step.title}</div>
              <div className="text-xs">{step.description}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="self-end">
        <MoreInfo />
      </div>
    </div>
  );
}

function MoreInfo() {
  return (
    <div className="bg-blue-500/5 border-blue-500/20 rounded-md border p-4 flex flex-col gap-4 relative">
      <div className="flex flex-col gap-2">
        <p className="text-sm flex items-baseline gap-2">
          Learn More <Info className="w-3 h-3" />
        </p>
        <p className="text-xs text-muted-foreground">
          Learn why the new blackhole is better and how upgrading works.
        </p>
        <div className="flex gap-2">
          <a
            href="https://docs.cycleops.dev/docs/blackhole-upgrade"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="outline" size="sm" className="text-xs h-6 px-2">
              Learn More
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

function CommandWizard() {
  const { currentStep, setCurrentStep } = useBlackholeStore();
  const route = useRoute();
  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.success("Blackhole Upgrade Complete");
      navigate(route("/"));
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const CurrentStepComponent = steps[currentStep]?.component;

  return (
    <div className="flex flex-1 h-full">
      <ProgressSidebar currentStep={currentStep} />
      <div className="flex-1">
        {CurrentStepComponent && (
          <CurrentStepComponent onComplete={handleNext} onBack={handleBack} />
        )}
      </div>
    </div>
  );
}

export default function BlackholeUpgradePage() {
  const navigate = useNavigate();

  return (
    <LayoutFocus>
      <FocusTopbar>
        <SiteBreadcrumb items={[{ title: "Upgrade Blackhole" }]} />
        <Button variant="outline" onClick={() => navigate(-1)}>
          Cancel Upgrade
        </Button>
      </FocusTopbar>
      <div className="border border-border/75 flex flex-1 gap-4 p-2 md:p-4 pt-0 relative bg-table rounded-lg overflow-hidden">
        <div className="h-auto absolute inset-0 overflow-y-auto">
          <CommandWizard />
        </div>
      </div>
    </LayoutFocus>
  );
}
