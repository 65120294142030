import { AlertCircle, RotateCcw, X } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";

import useCanisterCreationStore from "../canister-creation-wizard-store";

export default function Failure() {
  const store = useCanisterCreationStore();
  const { error } = store.execution;

  return (
    <div className="flex-1 flex flex-col items-center justify-center h-full gap-8 p-8">
      <div className="relative">
        <div className="absolute inset-0 animate-pulse rounded-full bg-destructive/10" />
        <div className="relative bg-background rounded-full p-4">
          <AlertCircle className="w-12 h-12 text-destructive" />
        </div>
      </div>

      <div className="text-center max-w-md">
        <p className="text-muted-foreground text-sm">
          Something went wrong while creating your canister. You can try again
          or contact support if the problem persists.
        </p>
      </div>

      {error && (
        <Alert variant="destructive" className="max-w-md">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error Details</AlertTitle>
          <AlertDescription className="mt-2 font-mono text-xs break-all">
            {error.message}
          </AlertDescription>
        </Alert>
      )}

      <div className="flex flex-col w-full gap-3 max-w-xs">
        <Button
          variant="default"
          className="gap-2"
          onClick={() => {
            store.machine.reset();
          }}
        >
          <RotateCcw className="w-4 h-4" />
          Try Again
        </Button>
        <Button
          variant="ghost"
          className="gap-2"
          onClick={() => {
            store.dialog.close();
            store.reset();
          }}
        >
          <X className="w-4 h-4" />
          Close
        </Button>
      </div>
    </div>
  );
}
