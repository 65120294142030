// A global singleton for our internet computer actors.
import type { _SERVICE as AccountsService } from "common/declarations/accounts/accounts.did.d";
import type { CycleOpsService } from "common/declarations/cycleops/cycleops.did.d";
import type { _SERVICE as CyclesLedger } from "common/declarations/cycles_ledger/cycles_ledger.did.d";
import type { _SERVICE as Cycles } from "common/declarations/nns-cycles-minting/nns-cycles-minting.did.d";
import type { _SERVICE as Ledger } from "common/declarations/nns-ledger/nns-ledger.did.d";
import type { _SERVICE as TransactionHistory } from "common/declarations/transaction_history/transaction_history.did.d";

import * as Agent from "@dfinity/agent";
import { InterfaceFactory } from "@dfinity/candid/lib/cjs/idl";

import { idlFactory as accountsIDL } from "common/declarations/accounts/accounts.did";
import { idlFactory as cycleopsIDL } from "common/declarations/cycleops/cycleops.did";
import { idlFactory as CyclesLedgerIDL } from "common/declarations/cycles_ledger/cycles_ledger.did";
import { idlFactory as CyclesIDL } from "common/declarations/nns-cycles-minting/nns-cycles-minting.did";
import { idlFactory as NnsIDL } from "common/declarations/nns-ledger/nns-ledger.did";
import { idlFactory as TransactionHistoryIDL } from "common/declarations/transaction_history/transaction_history.did";

import { idlFactory as DidToJsIDL } from "@/lib/declarations/didtojs.did";
import { DidToJsService } from "@/lib/declarations/didtojs.did.d";
import { idlFactory as IcpcoinsIDL } from "@/lib/declarations/icpcoins.did";
import { IcpcoinsService } from "@/lib/declarations/icpcoins.did.d";

// Check to make sure we have all of the required environment variables.
for (const [name, id] of [
  ["MAIN", import.meta.env.CYOPS_MAIN_CANISTER_ID],
  ["ACCOUNTS", import.meta.env.CYOPS_ACCOUNTS_CANISTER_ID],
  ["II", import.meta.env.CYOPS_II_CANISTER_ID],
  ["NNS_LEDGER", import.meta.env.CYOPS_NNS_LEDGER_CANISTER_ID],
  ["NNS_CYCLES", import.meta.env.CYOPS_NNS_CYCLES_CANISTER_ID],
  ["TX_HISTORY", import.meta.env.CYOPS_TX_HISTORY_CANISTER_ID],
]) {
  if (!id) {
    throw new Error(`Missing CYOPS_${name}_CANISTER_ID`);
  }
}

export const ic = {
  protocol: (import.meta.env.CYOPS_IC_PROTOCOL as string) || "https",
  origin: (import.meta.env.CYOPS_IC_HOST as string) || "ic0.app",
  isLocal: import.meta.env.CYOPS_IS_LOCAL === "TRUE",
};

export const host = `${ic.protocol}://${ic.origin}`;

/// The default agent used in our app, to be assigned to the user's delegation identity.
export const agent = new Agent.HttpAgent({ host, retryTimes: 10 });

export const alwaysMainnetAgent = new Agent.HttpAgent({
  host: "https://icp-api.io",
  identity: undefined,
});

/// Create an actor.
export function actor<T>(
  canisterId: string,
  factory: InterfaceFactory,
  config?: Partial<Agent.ActorConfig>
): Agent.ActorSubclass<T> {
  return Agent.Actor.createActor(factory, { canisterId, agent, ...config });
}

// All of the actor definitions needed in our app should go here.

export const cyops = actor<CycleOpsService>(
  import.meta.env.CYOPS_MAIN_CANISTER_ID,
  cycleopsIDL
);

export const accounts = actor<AccountsService>(
  import.meta.env.CYOPS_ACCOUNTS_CANISTER_ID,
  accountsIDL
);

export const txHistory = actor<TransactionHistory>(
  import.meta.env.CYOPS_TX_HISTORY_CANISTER_ID,
  TransactionHistoryIDL
);

export const nns = actor<Ledger>(
  import.meta.env.CYOPS_NNS_LEDGER_CANISTER_ID,
  NnsIDL
);

export const cycles = actor<Cycles>(
  import.meta.env.CYOPS_NNS_CYCLES_CANISTER_ID,
  CyclesIDL
);

export const icpCoins = Agent.Actor.createActor<IcpcoinsService>(IcpcoinsIDL, {
  canisterId: "u45jl-liaaa-aaaam-abppa-cai",
  agent: alwaysMainnetAgent,
});

export const cyclesLedger = actor<CyclesLedger>(
  import.meta.env.CYOPS_CYCLES_LEDGER_CANISTER_ID,
  CyclesLedgerIDL
);

export const nnsLedger = actor<CyclesLedger>(
  import.meta.env.CYOPS_NNS_LEDGER_CANISTER_ID,
  CyclesLedgerIDL
);

export const didToJs = Agent.Actor.createActor<DidToJsService>(DidToJsIDL, {
  canisterId: "a4gq6-oaaaa-aaaab-qaa4q-cai",
  agent: alwaysMainnetAgent,
});
