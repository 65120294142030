import {
  canister_status_result,
  ICManagementCanister,
  LogVisibility,
} from "@dfinity/ic-management";
import { Principal } from "@dfinity/principal";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { toast } from "sonner";

import { agent } from "@/lib/actors";
import { useIdp } from "@/state/stores/idp";

import { queryClient } from ".";

const manager = ICManagementCanister.create({
  serviceOverride: undefined,
  agent,
});

// Fetch

async function fetchCanisterStatus(canisterId: Principal) {
  const call = await manager.canisterStatus(canisterId);
  return call;
}

async function fetchCanisterLogs(canisterId: Principal) {
  const call = await manager.fetchCanisterLogs(canisterId);
  return call;
}

async function fetchCanisterSnapshots(canisterId: Principal) {
  const call = await manager.listCanisterSnapshots({ canisterId });
  return call;
}

export { fetchCanisterStatus, fetchCanisterLogs, fetchCanisterSnapshots };

// Query

function useCanisterStatusQuery<RT = canister_status_result>(
  canisterId: Principal,
  options?: Partial<UseQueryOptions<canister_status_result, unknown, RT>>
) {
  return useQuery({
    queryKey: ["canister-status", canisterId.toText()],
    queryFn: async () => {
      const call = await fetchCanisterStatus(canisterId);
      return call;
    },
    ...options,
  });
}

function useCanisterControllersQuery(canisterId: Principal) {
  return useCanisterStatusQuery(canisterId, {
    select: (data) => data.settings.controllers,
  });
}

function useIsManagedCanisterQuery(canisterId: Principal) {
  const { principal } = useIdp();
  return useCanisterStatusQuery(canisterId, {
    select: ({ settings: { controllers } }) =>
      controllers.map((x) => x.toText()).includes(principal.toText()),
  });
}

function useCanisterLogsQuery(canisterId: Principal, tailing: boolean = true) {
  return useQuery({
    queryKey: ["canister-logs", canisterId.toText()],
    queryFn: async () => {
      const call = await fetchCanisterLogs(canisterId);
      return call;
    },
    refetchInterval: tailing ? 900 : false,
  });
}

function useCanisterSnapshotsQuery(canisterId: Principal) {
  return useQuery({
    queryKey: ["canister-snapshots", canisterId.toText()],
    queryFn: async () => {
      const call = await fetchCanisterSnapshots(canisterId);
      return call;
    },
  });
}

export {
  useCanisterStatusQuery,
  useCanisterControllersQuery,
  useIsManagedCanisterQuery,
  useCanisterLogsQuery,
  useCanisterSnapshotsQuery,
};

// Post

async function postUpdateCanisterControllers(
  canisterId: Principal,
  controllers: Principal[]
) {
  const call = await manager.updateSettings({
    canisterId,
    settings: {
      controllers: controllers.map((x) => x.toText()),
    },
  });
  return call;
}

async function postStartCanister(canisterId: Principal) {
  const call = await manager.startCanister(canisterId);
  return call;
}

async function postStopCanister(canisterId: Principal) {
  const call = await manager.stopCanister(canisterId);
  return call;
}

async function postUpdateCanisterSettings(
  canisterId: Principal,
  settings: {
    freezingThreshold?: bigint;
    controllers?: Principal[];
    memoryAllocation?: bigint;
    computeAllocation?: bigint;
    reservedCyclesLimit?: bigint;
    logVisibility?: LogVisibility;
    wasmMemoryLimit?: bigint;
  }
) {
  const call = await manager.updateSettings({
    canisterId,
    settings: {
      ...settings,
      controllers: settings.controllers?.map((x) => x.toText()),
      freezingThreshold: settings.freezingThreshold,
      memoryAllocation: settings.memoryAllocation,
      computeAllocation: settings.computeAllocation,
      reservedCyclesLimit: settings.reservedCyclesLimit,
      logVisibility: settings.logVisibility,
      wasmMemoryLimit: settings.wasmMemoryLimit,
    },
  });
  return call;
}

async function postTakeCanisterSnapshot(canisterId: Principal) {
  const call = await manager.takeCanisterSnapshot({
    canisterId,
  });
  return call;
}

async function postDeleteCanisterSnapshot(
  canisterId: Principal,
  snapshotId: Uint8Array
) {
  const call = await manager.deleteCanisterSnapshot({
    canisterId,
    snapshotId,
  });
  return call;
}

async function postLoadCanisterSnapshot(
  canisterId: Principal,
  snapshotId: Uint8Array
) {
  const call = await manager.loadCanisterSnapshot({
    canisterId,
    snapshotId,
  });
  return call;
}

export {
  postUpdateCanisterControllers,
  postStartCanister,
  postStopCanister,
  postUpdateCanisterSettings,
  postTakeCanisterSnapshot,
  postDeleteCanisterSnapshot,
  postLoadCanisterSnapshot,
};

// Mutate

function useCanisterControllersMutation() {
  return useMutation({
    async mutationFn({
      canisterId,
      controllers,
    }: {
      canisterId: Principal;
      controllers: Principal[];
    }) {
      return postUpdateCanisterControllers(canisterId, controllers);
    },
    onSuccess(_, variables) {
      toast.success("Controllers updated");
      queryClient.invalidateQueries({
        queryKey: ["canister-controllers", variables.canisterId.toText()],
      });
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to update controllers", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useStartCanisterMutation() {
  return useMutation({
    async mutationFn(canisterId: Principal) {
      return postStartCanister(canisterId);
    },
    onSuccess(_, canisterId) {
      // Update the cache with new status
      queryClient.setQueryData<canister_status_result>(
        ["canister-status", canisterId.toText()],
        (old) => ({
          ...old!,
          status: { running: null },
        })
      );

      toast.success("Canister started");

      // Then invalidate to refetch
      queryClient.invalidateQueries({
        queryKey: ["canister-status", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to start canister", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useStopCanisterMutation() {
  return useMutation({
    async mutationFn(canisterId: Principal) {
      return postStopCanister(canisterId);
    },
    onSuccess(_, canisterId) {
      // Update the cache with new status
      queryClient.setQueryData<canister_status_result>(
        ["canister-status", canisterId.toText()],
        (old) => ({
          ...old!,
          status: { stopped: null },
        })
      );

      toast.success("Canister stopped");

      // Then invalidate to refetch
      queryClient.invalidateQueries({
        queryKey: ["canister-status", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to stop canister", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useUpdateCanisterSettingsMutation() {
  return useMutation({
    async mutationFn({
      canisterId,
      settings,
    }: {
      canisterId: Principal;
      settings: Parameters<typeof postUpdateCanisterSettings>[1];
    }) {
      return postUpdateCanisterSettings(canisterId, settings);
    },
    onSuccess(_, { canisterId }) {
      toast.success("Settings updated");
      queryClient.invalidateQueries({
        queryKey: ["canister-status", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to update settings", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useTakeCanisterSnapshotMutation() {
  return useMutation({
    async mutationFn({ canisterId }: { canisterId: Principal }) {
      return postTakeCanisterSnapshot(canisterId);
    },
    onSuccess(_, { canisterId }) {
      toast.success("Snapshot created");
      queryClient.invalidateQueries({
        queryKey: ["canister-snapshots", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to create snapshot", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useDeleteCanisterSnapshotMutation() {
  return useMutation({
    async mutationFn({
      canisterId,
      snapshotId,
    }: {
      canisterId: Principal;
      snapshotId: Uint8Array;
    }) {
      return postDeleteCanisterSnapshot(canisterId, snapshotId);
    },
    onSuccess(_, { canisterId }) {
      toast.success("Snapshot deleted");
      queryClient.invalidateQueries({
        queryKey: ["canister-snapshots", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to delete snapshot", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

function useLoadCanisterSnapshotMutation() {
  return useMutation({
    async mutationFn({
      canisterId,
      snapshotId,
    }: {
      canisterId: Principal;
      snapshotId: Uint8Array;
    }) {
      return postLoadCanisterSnapshot(canisterId, snapshotId);
    },
    onSuccess(_, { canisterId }) {
      toast.success("Snapshot loaded");
      queryClient.invalidateQueries({
        queryKey: ["canister-snapshots", canisterId.toText()],
      });
    },
    onError(error) {
      toast.error("Failed to load snapshot", {
        description: error.message,
      });
      console.error(error);
    },
  });
}

export {
  useCanisterControllersMutation,
  useStartCanisterMutation,
  useStopCanisterMutation,
  useUpdateCanisterSettingsMutation,
  useTakeCanisterSnapshotMutation,
  useDeleteCanisterSnapshotMutation,
  useLoadCanisterSnapshotMutation,
};
