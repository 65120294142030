import { Principal } from "@dfinity/principal";
import { Trash, Trash2 } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

import PrincipalAbbr from "@/components/principal-abbr";
import { useBatchCanisterDeleteMutation } from "@/hooks/queries/canisters";

import { Button } from "../../ui/button";
import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";
import {
  CanistersCommandDialog,
  CanistersCommandDialogFooter,
} from "./command-dialog";

interface DeleteCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
}

export const useDeleteCommand = create<DeleteCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setCanisterId: (canisterId) => set({ canisterId }),
}));

export function DeleteCommandDialog() {
  const { isOpen, close, canisterId, setCanisterId } = useDeleteCommand();
  const selectedCanisters = useSelectedCanisters();
  const mutation = useBatchCanisterDeleteMutation();
  const [toastId, setToastId] = useState<string | undefined>(undefined);

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];

  const canisterCount = canisterIds.length;

  function handleDelete() {
    mutation.mutate(
      { canisterIds },
      {
        onSettled: () => {
          toast.dismiss(toastId);
        },
        onSuccess: () => {
          close();
          setCanisterId(undefined);
        },
      }
    );
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      // If closing while deletion is in progress, show the loading toast
      const id = toast.loading(
        `Deleting ${canisterCount} canister${canisterCount !== 1 ? "s" : ""}...`
      );
      setToastId(id as string);
    }
    close();
  }

  return (
    <CanistersCommandDialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CommandPillList canisterCount={selectedCanisters.length}>
        <CommandLabel className="bg-destructive/10 ring-destructive/30 text-destructive">
          <Trash className="w-3 h-3" />
          Delete
        </CommandLabel>
      </CommandPillList>

      <div className="p-4 flex flex-col gap-4">
        <div className="text-sm">
          Are you sure you want to delete{" "}
          {canisterCount > 1 ? (
            `${canisterCount} canisters`
          ) : (
            <PrincipalAbbr>{canisterIds[0]?.toText()}</PrincipalAbbr>
          )}
          ? This action cannot be undone.
        </div>
      </div>
      <CanistersCommandDialogFooter>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleDelete();
          }}
          className="flex gap-2 justify-end"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
        >
          <Button
            type="button"
            variant="ghost"
            onClick={close}
            disabled={mutation.isPending}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="destructive"
            className="gap-2"
            disabled={mutation.isPending}
          >
            <Trash2 className="w-4 h-4" />
            {mutation.isPending ? "Deleting..." : "Delete"}
          </Button>
        </form>
      </CanistersCommandDialogFooter>
    </CanistersCommandDialog>
  );
}
