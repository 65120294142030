import { Principal } from "@dfinity/principal";
import BigNumber from "bignumber.js";
import { useMemo, useState } from "react";

import { InputNumber } from "@/components/input-number";
import { TC } from "@/components/tc";
import { TokenAmount } from "@/components/token-amount";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useSubnetMetadataQuery } from "@/hooks/queries/canister-creation";
import {
  useCyclesMarginQuery,
  usePaymentMethodQuery,
} from "@/hooks/queries/customer";
import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { useCanisterMetadataQuery } from "@/hooks/queries/internet-computer";
import {
  useCustomerCyclesLedgerAllowanceQuery,
  useCustomerCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { AppLink } from "@/hooks/queries/team";

import useCanisterCreationStore from "../canister-creation-wizard-store";

export default function CyclesAllocation() {
  return (
    <div className="flex flex-col gap-6 h-full">
      <div className="flex-1 h-full">
        <div className="flex-1 h-full flex flex-col gap-2 overflow-hidden">
          <CyclesInput />
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Submit />
        <Back />
      </div>
    </div>
  );
}

function Submit() {
  const machine = useCanisterCreationStore((s) => s.machine);
  return (
    <Button
      size="lg"
      className="w-full max-w-[200px] h-12"
      onClick={() => machine.next()}
    >
      Continue
    </Button>
  );
}

function Back() {
  const machine = useCanisterCreationStore((s) => s.machine);
  return (
    <Button size="lg" className="h-12" variant="outline" onClick={machine.back}>
      Back
    </Button>
  );
}

function CyclesInput() {
  const subnetSelection = useCanisterCreationStore((s) => s.subnetSelection);
  const cyclesAllocation = useCanisterCreationStore((s) => s.cyclesAllocation);

  const [cycles, setCycles] = useState(
    BigNumber(cyclesAllocation.additionalCycles.toString()).div(1e12).toNumber()
  );

  const selectedCanister = useCanisterMetadataQuery(
    subnetSelection.selectedCanisterId?.toText()
  );
  const selectedCanisterSubnet = useSubnetMetadataQuery(
    selectedCanister.data
      ? Principal.fromText(selectedCanister.data.subnet)
      : undefined
  );
  const selectedSubnet = useSubnetMetadataQuery(
    subnetSelection.selectedSubnetId
  );
  const paymentMethod = usePaymentMethodQuery();
  const icpBalance = useCustomerICPBalanceQuery();
  const cyclesBalance = useCustomerCyclesLedgerBalanceQuery();
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();
  const cyclesPrice = useCyclesPriceQuery();
  const cyclesMargin = useCyclesMarginQuery();

  const subnetMetadata = subnetSelection.selectedCanisterId
    ? selectedCanisterSubnet
    : selectedSubnet;

  const creationCost = subnetMetadata.data?.cost ?? 500_000_000_000n;
  const cyclesBigInt = BigInt(
    BigNumber(cycles).times(1e12).integerValue().toNumber()
  );

  const totalCost = useMemo(() => {
    if (!paymentMethod.data) return undefined;
    if (paymentMethod.data === "cycles") {
      if (!cyclesMargin.data) return undefined;
      return BigInt(
        BigNumber(cyclesMargin.data + 1)
          .times((creationCost + cyclesBigInt).toString())
          .integerValue()
          .toNumber()
      );
    }
    if (!cyclesPrice.data) return undefined;
    return BigInt(
      BigNumber(cyclesPrice.data.icpPerTrillionCycles.e8s.toString())
        .times((creationCost + cyclesBigInt).toString())
        .div(1e12)
        .integerValue()
        .toNumber()
    );
  }, [cyclesBigInt, paymentMethod.data, cyclesMargin.data, cyclesPrice.data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCycles(Number(e.currentTarget.value));
    cyclesAllocation.setAdditionalCycles(
      BigInt(
        BigNumber(e.currentTarget.value).times(1e12).integerValue().toNumber()
      )
    );
  };

  return (
    <>
      <div className="flex-1 flex flex-col border border-border rounded-md overflow-y-auto">
        <div className="flex flex-col gap-4 p-4">
          <div className="grid grid-cols-[1fr,2fr] items-baseline gap-3">
            <div className="font-medium text-muted-foreground">
              Canister creation cost
            </div>
            {subnetMetadata.isLoading ? (
              <Skeleton className="h-6 w-[100px]" />
            ) : (
              <div className="text-lg">
                <TC value={creationCost} figs={2} includeUnit />
              </div>
            )}
            <div className="font-medium text-muted-foreground">
              Starting cycles balance
            </div>
            <InputNumber
              value={cycles}
              onChange={handleChange}
              className="text-sm"
              min={0.1}
            />
          </div>
          <Separator />
          <div className="grid grid-cols-[1fr,2fr] items-baseline gap-3">
            <div className="font-medium text-muted-foreground">Cost</div>
            {subnetMetadata.isLoading ? (
              <Skeleton className="h-6 w-[100px]" />
            ) : (
              <div className="text-lg font-bold">
                {paymentMethod.data === "cycles" ? (
                  <TC value={totalCost} figs={2} includeUnit />
                ) : (
                  <TokenAmount amount={totalCost} symbol="ICP" disableChange />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-[1fr,2fr] items-baseline gap-3 bg-muted/25 p-2 m-2 -mt-2 rounded-md">
          <div className="font-medium text-muted-foreground">
            {paymentMethod.data === "cycles"
              ? "Cycles Allowance"
              : "Wallet Balance"}
          </div>
          {subnetMetadata.isLoading ? (
            <Skeleton className="h-6 w-[100px]" />
          ) : (
            <div className="text-lg">
              {paymentMethod.data === "cycles" ? (
                <TC
                  value={cyclesAllowance.data?.allowance}
                  figs={2}
                  includeUnit
                />
              ) : (
                <TokenAmount
                  amount={icpBalance.data?.e8s}
                  symbol="ICP"
                  disableChange
                />
              )}
            </div>
          )}
        </div>

        {paymentMethod.data === "cycles" &&
          (cyclesAllowance.data?.allowance ?? 0n) < (totalCost ?? 0n) && (
            <div className="p-2 m-2 bg-destructive/25">
              You don't have enough Cycles.{" "}
              <AppLink className="underline" to="billing">
                Add funds
              </AppLink>
            </div>
          )}
        {paymentMethod.data === "icp" &&
          (icpBalance.data?.e8s ?? 0n) < (totalCost ?? 0n) && (
            <div className="p-2 m-2 bg-destructive/25">
              You don't have enough ICP.{" "}
              <AppLink className="underline" to="billing">
                Add funds
              </AppLink>
            </div>
          )}
      </div>
      <div className="text-xs text-muted-foreground">
        Canister will have a cycles balance of{" "}
        <TC value={cycles} figs={2} includeUnit />, you will be charged{" "}
        {paymentMethod.data === "cycles" ? (
          <TC value={totalCost} figs={2} includeUnit />
        ) : (
          <TokenAmount amount={totalCost} symbol="ICP" disableChange />
        )}
      </div>
    </>
  );
}
