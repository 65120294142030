import { Loader2 } from "lucide-react";
import { useEffect, useRef } from "react";

import { useCreateCanisterWizardMutation } from "@/hooks/queries/canister-creation";

export default function Execution() {
  const mutation = useCreateCanisterWizardMutation();
  const hasStarted = useRef(false);

  useEffect(() => {
    if (hasStarted.current || mutation.isPending) return;
    hasStarted.current = true;
    mutation.mutate();
  }, [mutation.isPending]);

  return (
    <div className="flex-1 flex flex-col items-center justify-center h-full gap-8 p-8">
      <div className="relative">
        <div className="absolute inset-0 animate-ping rounded-full bg-primary/20" />
        <div className="absolute inset-0 animate-pulse rounded-full bg-primary/10" />
        <div className="relative bg-background rounded-full p-6">
          <Loader2 className="w-12 h-12 animate-spin text-primary" />
        </div>
      </div>
      <div className="space-y-2 text-center">
        <h3 className="text-lg font-medium">Creating Your Canister</h3>
        <p className="text-muted-foreground">
          This may take a few moments. We're setting everything up for you...
        </p>
      </div>
    </div>
  );
}
