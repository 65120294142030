import { Actor, AnonymousIdentity, HttpAgent } from "@dfinity/agent";
import { Tokens } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import { useQuery, useMutation } from "@tanstack/react-query";
import posthog from "posthog-js";
import { toast } from "sonner";

import { idlFactory as cycleopsIDL } from "common/declarations/cycleops/cycleops.did";
import { CycleOpsService } from "common/declarations/cycleops/cycleops.did.d";

import type { CustomerMetadata } from "@/hooks/queries/team";

import {
  asTeamDefault,
  useAsTeamQuery,
  useActivePrincipalQuery,
} from "@/hooks/queries/team";
import { cyops, host, ic } from "@/lib/actors";
import { mapOptional, reverseOptional } from "@/lib/ic-utils";
import idp, { useIdp } from "@/state/stores/idp";

import { queriesAreFresh, queryClient } from ".";
import { useCyclesPriceQuery } from "./cycleops-service";

// Fetch

async function fetchCustomerEmail(asTeam = asTeamDefault) {
  return mapOptional(await cyops.getCustomerEmail(asTeam));
}

function fetchCustomerIsOnboarded({ asTeamPrincipal } = asTeamDefault) {
  return cyops.isOnboardingComplete({ asTeamPrincipal });
}

function fetchCustomerIsEmailVerified({ asTeamPrincipal } = asTeamDefault) {
  return cyops.isEmailVerified({ asTeamPrincipal });
}

function fetchIsCycleLedgerAccountApprovedForCustomer(
  { cycleLedgerAccountPrincipal }: { cycleLedgerAccountPrincipal: Principal },
  asTeam = asTeamDefault
) {
  return cyops.isCycleLedgerAccountApprovedForCustomer({
    cycleLedgerAccountPrincipal,
    ...asTeam,
  });
}

async function fetchCustomerPaymentConf({ asTeamPrincipal } = asTeamDefault) {
  const call = await cyops.getCustomerPaymentConfiguration({ asTeamPrincipal });
  if ("ok" in call) return call.ok;
  throw new Error("Failed to fetch customer payment configuration");
}

async function fetchNotificationSettings(asTeam = asTeamDefault) {
  const result = await cyops.getCustomerNotificationSettings({
    ...asTeam,
  });
  if ("err" in result) throw new Error("Failed to get notification settings");
  return {
    ...result.ok,
    notifyOnICPBelow: mapOptional(result.ok.notifyOnICPBelow),
    notifyOnCyclesApprovalBalanceBelow: mapOptional(
      result.ok.notifyOnCyclesApprovalBalanceBelow
    ),
  };
}

async function fetchCustomerMetadata(
  principal: Principal
): Promise<CustomerMetadata> {
  const call = await cyops.getCustomerById(principal);
  if ("err" in call) throw new Error(call.err);
  return {
    principal: call.ok.id,
    username: call.ok.metadata.username,
    displayName: mapOptional(call.ok.metadata.displayName),
    logoUrl: mapOptional(call.ok.metadata.logoUrl),
  };
}

export {
  fetchCustomerEmail,
  fetchCustomerIsOnboarded,
  fetchCustomerIsEmailVerified,
  fetchIsCycleLedgerAccountApprovedForCustomer,
  fetchCustomerPaymentConf,
  fetchNotificationSettings,
  fetchCustomerMetadata,
};

// Query

function useCustomerEmailQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-email", principal.data?.toString()],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchCustomerEmail({ ...asTeam.data });
      if (asTeam.data.asTeamPrincipal.length === 0)
        posthog.people.set({
          email: call?.address,
          emailVerified: !!call?.verified,
        });
      return call ?? null;
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: (query) => {
      if (!query.state.data?.verified) return 0;
      return 1000 * 60 * 60;
    },
    refetchInterval: 10_000,
  });
}

function useCustomerIsOnboardedQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-onboarded", principal.data?.toString()],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchCustomerIsOnboarded({ ...asTeam.data });
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 0,
  });
}

function useCustomerIsEmailVerifiedQuery({
  refetchInterval = 0,
  staleTime = 1000 * 60 * 60,
}) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-email-verified", principal.data],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchCustomerIsEmailVerified({ ...asTeam.data });
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime,
    refetchInterval,
  });
}

type UsePaymentConfResult = ReturnType<typeof useCustomerPaymentConfQuery>;

function useCustomerPaymentConfQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-payment-conf", principal.data],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchCustomerPaymentConf({ ...asTeam.data });
      posthog.people.set({ paymentMethod: Object.keys(call.paymentMethod)[0] });
      return call;
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 1000 * 60 * 60,
  });
}

function usePaymentMethodQuery() {
  const query = useCustomerPaymentConfQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-payment-conf", "payment-method", principal.data],
    queryFn: async () => {
      if (!query.data)
        throw new Error("Unexpected missing payment configuration");
      if (!query.data) return undefined;
      if ("icp" in query.data.paymentMethod) return "icp";
      return "cycles";
    },
    enabled: query.isFetched && principal.isFetched,
  });
}

function useCyclesMarginQuery() {
  return useQuery({
    queryKey: ["cycles-margin"],
    queryFn: async () => {
      const call = await cyops.getCyclesMargin();
      return call;
    },
  });
}

function useTopupCostFnQuery() {
  const paymentMethod = usePaymentMethodQuery();
  const principal = useActivePrincipalQuery();
  const cyclesPrice = useCyclesPriceQuery();
  const cyclesMargin = useCyclesMarginQuery();

  return useQuery({
    queryKey: ["topup-cost", principal.data],
    enabled: queriesAreFresh([
      paymentMethod,
      principal,
      cyclesPrice,
      cyclesMargin,
    ]),
    queryFn: async () => {
      return (query: { tc?: number; numCanisters?: number }) => {
        const { tc = 0, numCanisters = 1 } = query;
        if (paymentMethod.data === "cycles")
          return {
            amount: numCanisters * tc * (1 + cyclesMargin.data!),
            unit: "TC",
          };
        return {
          amount: (numCanisters * tc) / (cyclesPrice.data!.cyclesPerICP / 1e12),
          unit: "ICP",
        };
      };
    },
  });
}

function useCustomerNotificationSettingsQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-notification-settings", principal.data],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchNotificationSettings({ ...asTeam.data });
      posthog.people.set({
        notifyOnTopupSuccess: call.notifyOnTopupSuccess,
        notifyOnTopupFailure: call.notifyOnTopupFailure,
        notifyOnMemoryThresholdReached: call.notifyOnMemoryThresholdReached,
      });
      return call;
    },
    enabled: asTeam.isFetched && principal.isFetched,
  });
}

function useCustomerMetadataQuery() {
  const principal = useActivePrincipalQuery();
  const id = useIdp();
  return useQuery({
    queryKey: ["customer-metadata", principal.data],
    queryFn: async () => {
      const call = await fetchCustomerMetadata(principal.data!);
      if (id.principal.toText() === principal.data?.toText())
        posthog.people.set({
          username: call.username,
          displayName: call.displayName,
          logoUrl: call.logoUrl,
          principal: call.principal.toText(),
        });
      return call;
    },
    enabled: principal.isFetched,
  });
}

function useIndividualMetadataQuery() {
  const { principal } = useIdp();
  return useQuery({
    queryKey: ["customer-metadata", principal],
    queryFn: () => fetchCustomerMetadata(principal),
    enabled: !!principal,
  });
}

export type { UsePaymentConfResult };
export {
  useCustomerEmailQuery,
  useCustomerIsOnboardedQuery,
  useCustomerIsEmailVerifiedQuery,
  useCustomerPaymentConfQuery,
  useCustomerNotificationSettingsQuery,
  useCustomerMetadataQuery,
  useIndividualMetadataQuery,
  usePaymentMethodQuery,
  useTopupCostFnQuery,
  useCyclesMarginQuery,
};

// Post

type PaymentConfigurationUpdateRequest =
  | { icp: null }
  | {
      cycles: {
        walletProvider: { plug: null } | { external: null };
        account: {
          owner: Principal;
          subaccount: [] | [Uint8Array | number[]];
        };
      };
    };

async function postCustomerPaymentConfiguration(
  params: PaymentConfigurationUpdateRequest,
  asTeam = asTeamDefault
) {
  const call = await cyops.setCustomerPaymentConfiguration({
    ...asTeam,
    paymentMethod: params,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postOnboardingComplete({ asTeamPrincipal } = asTeamDefault) {
  const call = await cyops.setOnboardingComplete({ asTeamPrincipal });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCompleteEmailVerification({
  principalId,
  email,
  token,
}: {
  principalId: string;
  email: string;
  token: string;
}) {
  const call = await cyops.completeEmailVerification(principalId, email, token);
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCustomerMetadata(
  {
    username,
    displayName,
    logoUrl,
  }: {
    username: string;
    displayName?: string;
    logoUrl?: string;
  },
  asTeam = asTeamDefault
) {
  const call = await cyops.updateCustomerMetadata({
    username,
    displayName: reverseOptional(displayName),
    logoUrl: reverseOptional(logoUrl),
    description: [],
    website: [],
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCreateCustomer({
  username,
  displayName,
  logoUrl,
}: {
  username: string;
  displayName?: string;
  logoUrl?: string;
}) {
  const call = await cyops.createCustomerRecord({
    metadata: {
      username,
      displayName: reverseOptional(displayName),
      logoUrl: reverseOptional(logoUrl),
      description: [],
      website: [],
    },
  });
  if ("err" in call) throw new Error(call.err);
  // return call.ok;
  console.log(call.ok);
  return null;
}

export interface NotificationSettings {
  notifyOnTopupSuccess: boolean;
  notifyOnTopupFailure: boolean;
  notifyOnICPBelow?: Tokens;
  notifyOnCyclesApprovalBalanceBelow?: bigint;
  notifyOnMemoryThresholdReached: boolean;
  notifyOnReservedCyclesThresholdReached: boolean;
}

async function postCustomerNotificationSettings(
  {
    notifyOnICPBelow,
    notifyOnCyclesApprovalBalanceBelow,
    ...settings
  }: NotificationSettings,
  asTeam = asTeamDefault
) {
  const call = await cyops.setCustomerNotificationSettings({
    ...settings,
    ...asTeam,
    notifyOnICPBelow: reverseOptional(notifyOnICPBelow),
    notifyOnCyclesApprovalBalanceBelow: reverseOptional(
      notifyOnCyclesApprovalBalanceBelow
    ),
    channels: [{ email: null }],
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCustomerEmail(
  { email }: { email: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.updateCustomerEmail({ email, ...asTeam });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

export type { PaymentConfigurationUpdateRequest };

export {
  postCustomerPaymentConfiguration,
  postOnboardingComplete,
  postCompleteEmailVerification,
  postCustomerNotificationSettings,
  postCustomerEmail,
  postCustomerMetadata,
  postCreateCustomer,
};

// Mutate

function useOnboardingCompleteMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async () => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postOnboardingComplete({ ...data });
    },
    mutationKey: ["onboarding-complete"],
    onError: (error) => {
      console.error(error);
      toast.error(`Error completing onboarding: ${error.message}`, {
        id: "onboarding-complete-error",
      });
    },
  });
}

function useCompleteEmailVerificationMutation() {
  return useMutation({
    mutationFn: postCompleteEmailVerification,
    mutationKey: ["complete-email-verification"],
    onError: (error) => {
      console.error(error);
      toast.error(`Error completing email verification: ${error.message}`, {
        id: "complete-email-verification-error",
      });
    },
  });
}

function useCustomerPaymentConfigurationMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: PaymentConfigurationUpdateRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCustomerPaymentConfiguration(request, data);
    },
    onError: (error) => {
      console.error(error);
      toast.error(`Error updating payment configuration: ${error.message}`, {
        id: "update-payment-configuration-error",
      });
    },
  });
}

function useCustomerNotificationSettingsMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: NotificationSettings) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCustomerNotificationSettings(request, data);
    },
    onMutate: (request) => {
      // Cancel outgoing requests
      queryClient.cancelQueries({
        queryKey: ["customer-notification-settings"],
      });

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData([
        "customer-notification-settings",
        principal.data,
      ]);

      // Optimistically update the cache
      queryClient.setQueryData(
        ["customer-notification-settings", principal.data],
        (old: any) => ({
          ...old,
          ...request,
        })
      );

      return { previousValue };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-notification-settings"],
      });
      toast.success("Notification settings updated", {
        id: "update-notification-settings-success",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error(`Error updating notification settings: ${error.message}`, {
        id: "update-notification-settings-error",
      });

      // Rollback to the previous value
      queryClient.setQueryData(
        ["customer-notification-settings", principal.data],
        (old: any) => old.previousValue
      );
    },
  });
}

function useCustomerEmailMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: { email: string }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCustomerEmail(request, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-email"],
      });
      queryClient.invalidateQueries({
        queryKey: ["customer-email-verified"],
      });
    },
    onSuccess: async (_, { email }) => {
      // If production environment, call the actual send email lambda function
      if (ic.isLocal === false) {
        const { data } = await asTeam();
        const teamPrincipal = mapOptional(data?.asTeamPrincipal ?? []);
        const customerPrincipalId =
          teamPrincipal?.toText() ?? idp.getState().principal.toText();
        sendEmailVerificationProd(customerPrincipalId, email);
        // If running locally, mock out the lambda with the call to mockLambdaSendEmailVerificationToken
      } else {
        mockLambdaSendEmailVerificationToken(email);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(`Error updating email: ${error.message}`, {
        id: "update-email-error",
      });
    },
  });
}

function useCustomerMetadataMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: {
      username: string;
      displayName?: string;
      logoUrl?: string;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCustomerMetadata(request, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
      queryClient.invalidateQueries({
        queryKey: ["customer-metadata"],
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error(`Error updating profile: ${error.message}`, {
        id: "update-profile-error",
      });
    },
  });
}

function useCreateCustomerMutation() {
  return useMutation({
    mutationFn: postCreateCustomer,
    onError: (error) => {
      console.error(error);
      toast.error(`Error creating customer: ${error.message}`, {
        id: "create-customer-error",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-metadata"],
      });
    },
  });
}

export {
  useOnboardingCompleteMutation,
  useCompleteEmailVerificationMutation,
  useCustomerPaymentConfigurationMutation,
  useCustomerNotificationSettingsMutation,
  useCustomerEmailMutation,
  useCustomerMetadataMutation,
  useCreateCustomerMutation,
};

export interface SaveNotificationSettingsRequest {
  email: string;
  notificationSettings: NotificationSettings;
}

// Email

// Function that mocks the lambda function that sends the email verification token
async function mockLambdaSendEmailVerificationToken(email: string) {
  console.log("mockLambdaSendEmailVerificationToken");
  const customerPrincipalId = idp.getState().principal.toText();
  const token = "mockToken";
  // For local testing, assumes that the lambda is using the anonymous identity
  // This also makes sense to test as the anonymous identity since the user
  // opening the email link should be anonymous
  // (idp context is not set in the context of the email link)
  const agent = new HttpAgent({ host, identity: new AnonymousIdentity() });
  agent.fetchRootKey();
  const anonCycleOpsActor = Actor.createActor<CycleOpsService>(cycleopsIDL, {
    canisterId: import.meta.env.CYOPS_MAIN_CANISTER_ID,
    agent,
  });

  // calls cycleops to sends the email verification token
  const result = await anonCycleOpsActor.addVerificationEmailToken(
    customerPrincipalId,
    email,
    token
  );

  // mocks the process of the customer opening their email and clicking the link
  window.open(
    `http://localhost:5174/?email=${email}&token=${token}&principalId=${customerPrincipalId}`
  );

  return result;
}

// sends the customer principal and email address to the sendEmailVerification lambda
async function sendEmailVerificationProd(
  customerPrincipalId: string,
  email: string
) {
  const SEND_EMAIL_VERIFICATION_LAMBDA_URL =
    "https://60pwws3pcd.execute-api.us-west-2.amazonaws.com/prod/";
  let response;
  try {
    response = await fetch(SEND_EMAIL_VERIFICATION_LAMBDA_URL, {
      method: "POST",
      body: JSON.stringify({
        principalId: customerPrincipalId,
        email,
      }),
    });
  } catch (err) {
    return {
      err: "We're having trouble verifying emails right now. Please contact support to report this issue.",
    };
  }

  if (response.ok) return { ok: null };

  const errorMessage = await response.text();
  return { err: errorMessage };
}

export { mockLambdaSendEmailVerificationToken, sendEmailVerificationProd };
