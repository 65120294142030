import { CheckCircle2, ExternalLink, Plus, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { AppLink } from "@/hooks/queries/team";
import { copy } from "@/lib/ui-utils";

import useCanisterCreationStore from "../canister-creation-wizard-store";

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export default function Success() {
  const store = useCanisterCreationStore();
  const canisterId = store.execution.createdCanisterId?.toText();

  return (
    <div className="flex-1 flex flex-col items-center justify-center h-full gap-8 p-8">
      <div className="relative">
        <div className="absolute inset-0 animate-pulse rounded-full bg-green-500/10" />
        <div className="relative bg-background rounded-full p-4">
          <CheckCircle2 className="w-12 h-12 text-green-500" />
        </div>
      </div>

      <div className="text-center max-w-md">
        <p className="text-muted-foreground text-sm">
          Your new canister is ready to use. You can view it, create another
          one, or close this dialog.
        </p>
      </div>

      <div className="flex items-center gap-4 p-1 pl-4 bg-muted/50 border rounded-lg">
        <code className="text-xs font-mono">{canisterId}</code>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => canisterId && copy(canisterId)}
        >
          Copy
        </Button>
      </div>

      <div className="flex flex-col w-full gap-3 max-w-xs">
        <AppLink to={`/canisters/${canisterId}`} className="w-full flex">
          <Button className="gap-2 w-full">View Canister</Button>
        </AppLink>
        <Button
          variant="outline"
          className="gap-2"
          onClick={() => {
            store.reset();
          }}
        >
          Create Another
        </Button>
        <Button
          variant="ghost"
          className="gap-2"
          onClick={() => {
            store.dialog.close();
            store.reset();
          }}
        >
          <X className="w-4 h-4" />
          Close
        </Button>
      </div>
    </div>
  );
}
