import { Box, Check, Minus, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";

import { useCanistersProject } from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";

interface ProjectCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const useProjectCommand = create<ProjectCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
}));

export function ProjectCommandDialog() {
  const { isOpen, close } = useProjectCommand();
  const selectedCanisters = useSelectedCanisters();
  const existingProjects = useProjectsQuery();
  const selectionProjects = useCanistersProject(selectedCanisters);
  const mutation = useBatchCanisterProjectMutation();
  const navigate = useNavigate();
  const route = useRoute();

  function handleProject(project: string) {
    const checked = selectionProjects.data?.[project];
    mutation.mutate({
      canisterIds: selectedCanisters,
      projectName: checked === true ? undefined : project,
    });
  }

  const projects = existingProjects.data?.map((project) => {
    const checked = selectionProjects.data?.[project.name];
    return { project: project.name, checked };
  });

  return (
    <CommandDialog open={isOpen} onOpenChange={close}>
      <CommandPillList canisterCount={selectedCanisters.length}>
        <CommandLabel>
          <Box className="w-3 h-3" />
          Move to project
        </CommandLabel>
      </CommandPillList>
      <CommandInput placeholder="Search projects..." />
      <CommandList>
        <CommandEmpty>
          <div className="p-4 text-sm text-muted-foreground">
            No matching projects found
          </div>
        </CommandEmpty>
        <CommandGroup>
          {projects?.map(({ project, checked }) => (
            <CommandItem
              key={project}
              onSelect={() => handleProject(project)}
              className="gap-2 items-center"
            >
              <div className="flex items-center gap-2 flex-1">
                <div
                  className={cn(
                    "h-4 w-4 border rounded-sm flex items-center justify-center",
                    checked && "bg-muted/50 border-border"
                  )}
                >
                  {checked === true && <Check className="w-3 h-3" />}
                  {checked === "indeterminate" && (
                    <Minus className="w-3 h-3 text-muted-foreground" />
                  )}
                </div>
                {project}
              </div>
            </CommandItem>
          ))}

          <CommandItem
            onSelect={() => {
              close();
              navigate(route("/projects"));
            }}
            className="gap-2"
          >
            <Settings className="w-4 h-4" />
            Manage Projects
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
