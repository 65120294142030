// Post

import { Principal } from "@dfinity/principal";
import { useMutation, useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import posthog from "posthog-js";
import { toast } from "sonner";

import { CanisterTopup } from "common/declarations/cycleops/cycleops.did.d";

import { asTeamDefault, useAsTeamQuery } from "@/hooks/queries/team";
import { cyops } from "@/lib/actors";
import { mapTrillions, readableICP } from "@/lib/ic-utils";
import { formatAsDollar } from "@/lib/ui-utils";

import { queriesAreFresh, queryClient } from ".";
import { refetchCanisters } from "./canisters";
import { CMCMetricsData, useCMCMetricsQuery } from "./cmc";
import { usePaymentMethodQuery } from "./customer";
import {
  useCyclesPriceQuery,
  useICPtoCyclesConversionFunction,
} from "./cycleops-service";
import { useCustomerCyclesLedgerAllowanceQuery } from "./ledger-cycles";
import { useCustomerICPBalanceQuery } from "./ledger-icp-legacy";

// Fetch

// Query

function useMintingLimitBreakFnQuery() {
  const convertFn = useICPtoCyclesConversionFunction();
  const cmc = useCMCMetricsQuery();
  const paymentMethod = usePaymentMethodQuery();
  return useQuery({
    queryKey: ["minting-limit-break-fn"],
    queryFn: async () => {
      return (requests: CanisterTopup[]) => {
        if (!cmc.data || !paymentMethod.data || !convertFn.data)
          return undefined;
        const topupAmount = reduceTopUpRequestsCyclesTotal(
          requests,
          convertFn.data
        );
        return protocolMintingLimitBreak({
          topupAmount,
          protocolLimitRemaining: cmc.data.remainingCycles,
          paymentMethod: paymentMethod.data,
        });
      };
    },
    enabled: queriesAreFresh([cmc, paymentMethod, convertFn]),
  });
}

export { useMintingLimitBreakFnQuery };

// Post

async function postManualTopup(
  params: {
    canisterId: Principal;
    topupAmount: { icp: { e8s: bigint } } | { cycles: bigint };
  },
  asTeam = asTeamDefault
) {
  const call = await cyops.manualTopup({ ...params, ...asTeam });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postBatchManualTopup(
  canisterTopups: CanisterTopup[],
  asTeam = asTeamDefault
) {
  const call = await cyops.batchManualTopups({ ...asTeam, canisterTopups });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

export { postManualTopup, postBatchManualTopup };

// Mutate

function useManualTopupMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterId: Principal;
      topupAmount: { icp: { e8s: bigint } } | { cycles: bigint };
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postManualTopup(request, data);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to top up canister");
    },
  });
}

function useStringManualTopupMutation() {
  const topup = useManualTopupMutation();
  const { data: icpBalance } = useCustomerICPBalanceQuery();
  const { data: cyclesAllowance } = useCustomerCyclesLedgerAllowanceQuery();
  const { data: cyclesPrice } = useCyclesPriceQuery();
  const { data: cmcMetrics } = useCMCMetricsQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterId: Principal;
      amountAsString: string;
      currency: "icp" | "cycles";
    }) => {
      const params = verifyManualTopupParams(
        request.amountAsString,
        request.currency,
        icpBalance,
        cyclesAllowance ? { cycles: cyclesAllowance.allowance } : undefined,
        cyclesPrice?.cyclesPerICP,
        cmcMetrics
      );

      const topupAmount =
        request.currency === "icp"
          ? { icp: params.amountInICP! }
          : { cycles: params.amountInCycles!.e12s };

      return topup.mutateAsync({ canisterId: request.canisterId, topupAmount });
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onSuccess(result, variables) {
      const { amountInICP, amountInCycles, chargeInCycles } =
        convertManualTopupAmount({
          ...variables,
          trillionCyclesPerFullICPToken: cyclesPrice?.cyclesPerICP,
        });
      refetchCanisters();
      queryClient.invalidateQueries({
        queryKey: ["customer-icp-balance"],
      });
      posthog.capture("Manual Topup Success", {
        canisterId: variables.canisterId.toText(),
        usd:
          amountInCycles && cyclesPrice
            ? Number(
                (
                  (Number(amountInCycles.e12s) / 1e12) *
                  cyclesPrice.usdPerTrillionCycles
                ).toFixed(2)
              )
            : undefined,
        amountInICP: amountInICP
          ? (Number(amountInICP.e8s) / 1e8).toFixed(4)
          : undefined,
        amountInCycles: amountInCycles
          ? (Number(amountInCycles.e12s) / 1e12).toFixed(4)
          : undefined,
        chargeInCycles: chargeInCycles
          ? (Number(chargeInCycles.e12s) / 1e12).toFixed(4)
          : undefined,
      });
      toast.success(
        `Sent ${
          amountInCycles ? mapTrillions(Number(amountInCycles.e12s), true) : "-"
        } cycles to ${variables.canisterId.toText()}${
          variables.currency === "cycles"
            ? ` (${
                chargeInCycles
                  ? mapTrillions(Number(chargeInCycles.e12s), true)
                  : "-"
              })`
            : ` (${amountInICP ? readableICP(amountInICP) : "-"} / ${
                amountInICP && cyclesPrice
                  ? amountInCycles &&
                    formatAsDollar(
                      (Number(amountInCycles.e12s) / 1e12) *
                        cyclesPrice.usdPerTrillionCycles
                    )
                  : "-"
              })`
        }`
      );
    },
  });
}

function useBatchCanisterTopupMutation() {
  const asTeam = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: CanisterTopup[]) => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      return postBatchManualTopup(request, asTeam.data);
    },
    onSuccess() {
      refetchCanisters();
      toast.success("Canister(s) topped up successfully");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to top up canister(s)", {
        description: error.message,
      });
    },
  });
}

export { useStringManualTopupMutation, useBatchCanisterTopupMutation };

// Helper

function convertManualTopupAmount({
  amountAsString,
  currency,
  trillionCyclesPerFullICPToken,
}: {
  amountAsString: string;
  currency: "icp" | "cycles";
  trillionCyclesPerFullICPToken?: number;
}): {
  amountInICP?: { e8s: bigint };
  amountInCycles?: { e12s: bigint };
  chargeInCycles?: { e12s: bigint };
} {
  if (Number.isNaN(Number(amountAsString)))
    return {
      amountInICP: undefined,
      amountInCycles: undefined,
      chargeInCycles: undefined,
    };

  // If provided currency is ICP, calculate cycles equivalent
  if (currency === "icp") {
    const amount = BigNumber(amountAsString);
    const amountInICP = {
      e8s: BigInt(amount.multipliedBy(1e8).integerValue().toNumber() || 0),
    };
    const amountInCycles = !trillionCyclesPerFullICPToken
      ? undefined
      : {
          e12s: BigInt(
            amount
              .multipliedBy(trillionCyclesPerFullICPToken)
              .integerValue()
              .toNumber() || 0
          ),
        };
    const chargeInCycles = amountInCycles;
    return {
      amountInICP,
      amountInCycles,
      chargeInCycles,
    };
  }

  // If provided currency is cycles, calculate ICP equivalent
  if (currency === "cycles") {
    const amount = BigNumber(amountAsString);
    const amountInCycles = {
      e12s: BigInt(amount.multipliedBy(1e12).integerValue().toNumber() || 0),
    };

    const amountInICP = !trillionCyclesPerFullICPToken
      ? undefined
      : {
          e8s: BigInt(
            amount
              .multipliedBy(1e12)
              .div(trillionCyclesPerFullICPToken)
              .multipliedBy(1e8)
              .integerValue()
              .toNumber() || 0
          ),
        };
    const chargeInCycles = {
      e12s: BigInt(
        amount
          .multipliedBy(1e12)
          .multipliedBy(1.05)
          .integerValue()
          .toNumber() || 0
      ),
    };
    return {
      amountInICP,
      amountInCycles,
      chargeInCycles,
    };
  }

  throw new Error("Invalid currency");
}

export { convertManualTopupAmount };

export function verifyManualTopupParams(
  amountAsString: string,
  currency: "icp" | "cycles",
  icpBalance?: { e8s: bigint },
  cyclesAllowance?: { cycles: bigint },
  trillionCyclesPerFullICPToken?: number,
  cmcMetrics?: CMCMetricsData
) {
  const { amountInICP, amountInCycles, chargeInCycles } =
    convertManualTopupAmount({
      amountAsString,
      currency,
      trillionCyclesPerFullICPToken,
    });

  if (amountInICP && amountInICP.e8s < BigInt(100_000)) {
    throw new Error("You must send at least 0.001 ICP.");
  }

  if (currency === "icp") {
    if (!amountInICP) throw new Error("Unreachable.");
    if (icpBalance && amountInICP?.e8s > icpBalance.e8s) {
      throw new Error("You don't have enough ICP to send this top-up.");
    }
  }

  if (currency === "cycles") {
    if (!amountInCycles) throw new Error("Unreachable.");
    // if (cyclesAllowance && amountInCycles.e12s > cyclesAllowance.cycles) {
    //   throw new Error("You don't have enough cycles to send this top-up.");
    // }
  }

  // Check CMC minting limit
  if (cmcMetrics && cmcMetrics.remainingCycles && amountInCycles) {
    const remainingLimit = BigInt(cmcMetrics.remainingCycles);
    if (amountInCycles.e12s > remainingLimit) {
      throw new Error("Cycles mint limit exceeded. Try again later");
    }
  }

  return { amountInICP, amountInCycles, chargeInCycles };
}

/**
 * Ensures that we don't exceed the protocol minting limit.
 * ICP can only mint a set amount of cycles per period.
 * @param topupAmount The amount of cycles user is requesting to topup
 * @param protocolMintingLimit Amount of cycles remaining in the protocol minting limit (@useCMCMetricsQuery)
 * @param paymentMethod The payment method used to topup the canister (@usePaymentMethodQuery)
 */
function protocolMintingLimitBreak({
  topupAmount,
  protocolLimitRemaining,
  paymentMethod,
}: {
  topupAmount: bigint;
  protocolLimitRemaining?: bigint;
  paymentMethod: "icp" | "cycles";
}) {
  // Protocol limit might be undefined due to upstream API
  // In this case we want to allow the transaction to go through
  if (!protocolLimitRemaining) return false;
  // When paying with the cycles ledger, no cycles need to be minted
  if (paymentMethod === "cycles") return false;
  if (topupAmount > protocolLimitRemaining) return true;
  return false;
}

function reduceTopUpRequestsCyclesTotal(
  requests: CanisterTopup[],
  convertICPtoCycles: (icp: { e8s: bigint }) => number
) {
  return requests.reduce((acc, r) => {
    if ("cycles" in r.topupAmount) return acc + r.topupAmount.cycles;
    return (
      acc +
      BigInt(
        BigNumber(convertICPtoCycles(r.topupAmount.icp))
          .integerValue()
          .toNumber()
      )
    );
  }, 0n);
}

export { protocolMintingLimitBreak };
