import { Principal } from "@dfinity/principal";
import BigNumber from "bignumber.js";
import { BatteryWarning } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

import InputAffix from "@/components/input-affix";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { useBatchCanisterReservedCyclesThresholdMutation, useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import {
  useCustomerNotificationSettingsMutation,
  useCustomerNotificationSettingsQuery,
} from "@/hooks/queries/customer";

import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";
import {
  CanistersCommandDialog,
  CanistersCommandDialogFooter,
} from "./command-dialog";

interface ReservedCyclesAlertCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
  reservedCyclesThreshold?: number;
  setReservedCyclesThreshold: (reservedCyclesThreshold: number) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

export const useReservedCyclesAlertCommand = create<ReservedCyclesAlertCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  canisterId: undefined,
  setCanisterId: (canisterId) => set({ canisterId }),
  reservedCyclesThreshold: undefined,
  setReservedCyclesThreshold: (reservedCyclesThreshold) => set({ reservedCyclesThreshold }),
  enabled: true,
  setEnabled: (enabled) => set({ enabled }),
}));

export function ReservedCyclesAlertCommandDialog() {
  const {
    isOpen,
    close,
    canisterId,
    setCanisterId,
    reservedCyclesThreshold,
    setReservedCyclesThreshold,
    enabled,
    setEnabled,
  } = useReservedCyclesAlertCommand();
  const selectedCanisters = useSelectedCanisters();
  const mutation = useBatchCanisterReservedCyclesThresholdMutation();
  const [toastId, setToastId] = useState<string | undefined>(undefined);
  const { mutateAsync: updateNotificationSettings } =
    useCustomerNotificationSettingsMutation();

  const { data: notificationSettings} =
    useCustomerNotificationSettingsQuery();
  
  const { data: canisterConfig } = useCanisterTableDetailQuery(canisterId);

  useEffect(() => {
    if (!canisterConfig) return;
    const threshold = canisterConfig.reservedCyclesThreshold;

    setReservedCyclesThreshold(Number(threshold));
    setEnabled(selectedCanisters.length > 1 ? false : threshold !== undefined && Number(threshold) > 0);
  }, [canisterConfig])

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];

  const canisterCount = canisterIds?.length ?? 0;

  const plural = canisterCount > 1;


  
  function handleSubmit() {
    const mapped = reservedCyclesThreshold
      ? BigInt(
          BigNumber(reservedCyclesThreshold).integerValue().toNumber()
        )
      : undefined;
    
    mutation.mutate(
      {
        canisterIds,
        reservedCycles: mapped,
      },
      {
        onSettled: () => {
          toast.dismiss(toastId);
        },
        onSuccess: () => {
          close();
          setCanisterId(undefined);
        },
      }
    );
  }

  function handleAccountWideChange() {
    if (!notificationSettings) return;
    updateNotificationSettings({
      ...notificationSettings,
      notifyOnReservedCyclesThresholdReached:
        !notificationSettings.notifyOnReservedCyclesThresholdReached,
    });
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      const id = toast.loading(
        `Updating reserved cycles threshold alert for ${canisterCount} canister${
          canisterCount !== 1 ? "s" : ""
        }...`
      );
      setToastId(id as string);
    }
    if (!open) {
      close();
    }
  }

  return (
    <CanistersCommandDialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CommandPillList canisterCount={canisterCount}>
        <CommandLabel>
          <BatteryWarning className="w-4 h-4" /> Set reserved cycles threshold alert
        </CommandLabel>
      </CommandPillList>

      <div className="flex flex-col gap-4 p-4">
        <div className="text-sm">
          Receive an alert when{" "}
          {plural ? "any of these canisters'" : "this canister's"} reserved cycles usage exceeds a given percentage threshold.
        </div>
        <div className="flex flex-col gap-4 p-3 border border-muted/50 bg-muted/10 rounded-lg">
          <div className="text-xs font-normal text-muted-foreground">
            Reserved cycles threshold alert setting for{" "}
            {plural ? `these ${canisterCount} canisters` : "this canister"}
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="canister-threshold">Alert threshold</Label>
            <InputAffix affix="%">
              <Input
                id="canister-threshold"
                className="rounded-r-none"
                type="number"
                min="10"
                max="95"
                value={enabled ? reservedCyclesThreshold : ""}
                onChange={(e) => setReservedCyclesThreshold(Number(e.target.value))}
                disabled={!enabled}
              />
            </InputAffix>
          </div>
          <div className="flex gap-2 items-center">
            <Label htmlFor="canister-enable">Enable</Label>
            <Switch
              id="canister-enable"
              checked={enabled}
              onCheckedChange={setEnabled}
            />
          </div>
        </div>
        <Separator />
        <div className="text-sm">
          Reserved cycles threshold alerts can be enabled or disabled at the account level. This
          setting affects all canisters.
        </div>
        <div className="flex flex-col gap-4 p-3 border border-muted/50 bg-muted/10 rounded-lg">
          <div className="text-xs font-normal text-muted-foreground">
            Account-wide reserved cycles threshold alert setting
          </div>
          <div className="flex gap-2 items-center">
            <Label htmlFor="account-enable">Enable</Label>
            <Switch
              id="account-enable"
              checked={notificationSettings?.notifyOnReservedCyclesThresholdReached}
              onCheckedChange={handleAccountWideChange}
            />
          </div>
        </div>
      </div>
      <CanistersCommandDialogFooter>
        <Button
          type="submit"
          className="text-base"
          loading={mutation.isPending}
          onClick={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              handleSubmit();
            }
          }}
        >
          Save
        </Button>
      </CanistersCommandDialogFooter>
    </CanistersCommandDialog>
  );
} 