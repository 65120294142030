import { useQuery } from "@tanstack/react-query";

import { ic } from "@/lib/actors";

// Fetch

async function fetchCanisterMetadata(canisterId: string) {
  const id = ic.isLocal ? "qc4nb-ciaaa-aaaap-aawqa-cai" : canisterId;
  const response: {
    canister_id: string;
    controllers: string[];
    module_hash: string;
    subnet_id: string;
  } = await fetch(
    `https://ic-api.internetcomputer.org/api/v3/canisters/${id}`
  ).then((r) => r.json());
  for (const field of ["subnet_id", "controllers", "module_hash"]) {
    if (!(field in response)) {
      console.warn(`Expected "${field}" in IC API response.`);
    }
  }
  return {
    subnet: response.subnet_id,
    moduleHash: response.module_hash,
    controllers: response.controllers,
  };
}

export { fetchCanisterMetadata };

// Query

function useCanisterMetadataQuery(canisterId?: string) {
  return useQuery({
    queryKey: ["canisterMetadata", canisterId],
    queryFn: async () => {
      if (!canisterId) return undefined;
      return fetchCanisterMetadata(canisterId);
    },
    enabled: !!canisterId,
  });
}

export { useCanisterMetadataQuery };
export const ICP_FEE = { e8s: BigInt(10000) };
