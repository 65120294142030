import { Principal } from "@dfinity/principal";
import { format } from "date-fns";
import { AlertTriangle, Info } from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  TooltipProps,
  XAxis,
  YAxis,
  Label,
  Brush,
} from "recharts";
import { CartesianViewBox } from "recharts/types/util/types";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterReservedCyclesInsight } from "@/hooks/queries/insights";
import { latestValue } from "@/insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { TC } from "../tc";
import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { ChartFigure } from "./burn";
import { ChartLoading } from "./query-calls";
import { ThresholdLabel, TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

interface ReservedCyclesData {
  timestamp: Date;
  reservedCycles: number;
  reservedCyclesPct: number;
  reservedCyclesLimit: number;
}

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  const data = payload?.[0]?.payload as ReservedCyclesData | undefined;

  if (!active || !data) return null;

  if ([NaN, undefined].includes(data.reservedCyclesPct)) {
    return (
      <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
        <div className="font-medium mb-1">Reserved Cycles</div>
        <div className="flex gap-1 justify-between">
          <div className="text-muted-foreground">Time</div>
          <div className="font-mono">
            {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
          </div>
        </div>
        <div className="italic text-xs mt-1">
          Data unavailable for this period.
        </div>
      </div>
    );
  }

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Reserved Cycles</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Usage</div>
        <div className="font-mono">{data.reservedCyclesPct.toFixed(1)}%</div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Reserved</div>
        <div className="font-mono">
          <TC value={BigInt(data.reservedCycles)} includeUnit />
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Limit</div>
        <div className="font-mono">
          <TC value={BigInt(data.reservedCyclesLimit)} includeUnit />
        </div>
      </div>
    </div>
  );
}

function Chart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const reservedCyclesInsight = useCanisterReservedCyclesInsight(
    canisterId,
    timeSettings
  );
  const details = useCanisterTableDetailQuery(canisterId);

  const alertThreshold = details.data?.reservedCyclesThreshold
    ? Number(details.data.reservedCyclesThreshold)
    : undefined;

  const chartData = useMemo(
    () =>
      reservedCyclesInsight.points?.map(([date, data]) => ({
        timestamp: date,
        reservedCycles: data?.reservedCycles
          ? Number(data.reservedCycles)
          : undefined,
        reservedCyclesPct: data?.reservedCyclesPct
          ? Number(data.reservedCyclesPct)
          : undefined,
        reservedCyclesLimit: data?.reservedCyclesLimit
          ? Number(data.reservedCyclesLimit)
          : undefined,
      })) ?? [],
    [reservedCyclesInsight]
  );

  const { brushProps, formatString } = useChartBrush({
    data: chartData,
  });

  const chartConfig = {
    reservedCyclesPct: {
      label: "Reserved Cycles",
      color: "hsl(var(--primary))",
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig} className="h-[200px] w-full">
      <LineChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis
          dataKey="timestamp"
          tickFormatter={(value) => format(value, formatString)}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
        />
        <YAxis
          tickFormatter={(value) => `${value.toFixed(0)}%`}
          domain={[0, 100]}
          tickLine={false}
          axisLine={false}
          orientation="right"
        />
        <ChartTooltip
          content={<CustomTooltip />}
          cursor={{
            strokeWidth: 2,
            stroke: "hsl(var(--border))",
          }}
        />
        {alertThreshold && (
          <ReferenceLine
            y={alertThreshold}
            stroke="var(--warning)"
            strokeDasharray="3 3"
          >
            <Label
              position="insideLeft"
              content={({ viewBox }) => (
                <ThresholdLabel
                  viewBox={viewBox as CartesianViewBox}
                  icon={AlertTriangle}
                  text="Alert"
                  color="var(--warning)"
                />
              )}
            />
          </ReferenceLine>
        )}
        <Line
          type="monotone"
          dataKey="reservedCyclesPct"
          stroke={chartConfig.reservedCyclesPct.color}
          strokeWidth={1.5}
          dot={false}
          isAnimationActive={false}
        />
        <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
      </LineChart>
    </ChartContainer>
  );
}

function NoLimitWarning() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-blue-800/10 border-blue-800/25 rounded-sm border p-2 flex flex-col gap-2 hover:bg-blue-800/20 hover:border-blue-800/35 transition-colors">
        <div className="text-sm flex items-center gap-2">
          <Info className="w-4 h-4" /> Data unavailable
        </div>
        <div className="text-xs max-w-[20rem] opacity-75">
          This canister has no reserved cycles limit.
        </div>
      </div>
    </div>
  );
}

export function ReservedCyclesChart({
  canisterId,
}: {
  canisterId?: Principal;
}) {
  const canister = useCanisterTableDetailQuery(canisterId);
  const timeSettings = useTimeSettingsStore();
  const reservedCyclesInsight = useCanisterReservedCyclesInsight(
    canisterId,
    timeSettings
  );

  const chartData = useMemo(
    () =>
      reservedCyclesInsight.points?.map(([date, data]) => ({
        timestamp: date,
        reservedCycles: Number(data?.reservedCycles ?? 0),
        reservedCyclesPct: Number(data?.reservedCyclesPct ?? 0),
        reservedCyclesLimit: Number(data?.reservedCyclesLimit ?? 0),
      })) ?? [],
    [reservedCyclesInsight]
  );

  const { startDate, endDate, formatString } = useChartBrush({
    data: chartData,
  });

  // Get latest value
  const [lastTimestamp, latestReservedCycles] = useMemo(
    () => latestValue(reservedCyclesInsight),
    [reservedCyclesInsight, timeSettings]
  );

  const content = (() => {
    if (chartData.length === 0) return null;
    if (!lastTimestamp) return null;
    if (canister.isLoading || !canister.data) return <ChartLoading />;
    if (!canister.data.reservedCyclesLimit) return <NoLimitWarning />;
    return <Chart canisterId={canisterId} />;
  })();

  return (
    <Card className="rounded-sm pb-3 bg-transparent">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Reserved Cycles Usage{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        {chartData.length > 0 && lastTimestamp && (
          <ChartFigure
            main={<>{latestReservedCycles?.reservedCyclesPct ?? 0}%</>}
            subheader={
              lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"
            }
          />
        )}
      </CardHeader>
      <CardContent className="px-2 py-2">
        <div className="h-[200px] w-full">{content}</div>
      </CardContent>
    </Card>
  );
}
