import { Loader2 } from "lucide-react";
import { useMemo } from "react";
import { PieChart, Pie, Cell } from "recharts";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import {
  useCanisterTable,
  useCanisterTableStore,
} from "@/hooks/stores/canister-table-store";
import { CanisterTableData } from "@/lib/insights/canister-insights";
import { cn } from "@/lib/ui-utils";

import { ChartConfig, ChartContainer } from "../ui/chart";

type CanisterHealthStatus = "healthy" | "warning" | "low balance" | "pending";
interface CanisterHealthChartProps {
  className?: string;
}

const COLORS = {
  healthy: "var(--healthy)",
  warning: "var(--warning)",
  "low balance": "var(--unhealthy)",
  pending: "var(--pending)",
};

export function CanisterHealthChart({ className }: CanisterHealthChartProps) {
  const { filters, searchQuery } = useCanisterTableStore();
  const table = useCanisterTable();

  const select = useMemo(() => {
    if (!table) return (d: CanisterTableData[]) => d;

    return (d: CanisterTableData[]) => {
      // Get the filtered rows directly from the table's filtered model
      // This will respect both searchQuery and the applied filters
      const filteredCanisters = table
        .getFilteredRowModel()
        .rows
        .map((row) => row.original.id.toText());
        
      return d.filter((canister) =>
        filteredCanisters.includes(canister.id.toText())
      );
    };
  }, [table, filters, searchQuery]);
  const query = useCanisterTableQuery({ select });

  const data = useMemo(() => {
    if (!query.data) return [];
    const map = query.data.reduce((acc, c) => {
      acc[c.status as CanisterHealthStatus] =
        (acc[c.status as CanisterHealthStatus] || 0) + 1;
      return acc;
    }, {} as Record<CanisterHealthStatus, number>);
    return Object.entries(map).map(([status, count]) => ({
      status: status as CanisterHealthStatus,
      count,
    }));
  }, [query.data]);

  const totalCanisters = data.reduce((sum, item) => sum + item.count, 0);

  // Filter out statuses with zero canisters
  const activeData = data.filter((item) => item.count > 0);

  const CustomLegend = () => {
    return (
      <div className="flex flex-col gap-2 text-xs w-1/3 absolute inset-y-0 right-0 justify-center pr-4 items-start">
        {activeData.map((entry) => (
          <div key={entry.status} className="flex items-center gap-1.5">
            <div
              className="w-2.5 h-2.5 rounded-sm"
              style={{ backgroundColor: COLORS[entry.status] }}
            />
            <span className="capitalize whitespace-nowrap">
              {entry.count}/{totalCanisters} {entry.status}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const chartConfig = {} satisfies ChartConfig;

  return (
    <Card className={cn("bg-table flex flex-col", className)}>
      <CardHeader className="px-2 py-2">
        <CardTitle className="text-sm">Canister Health</CardTitle>
      </CardHeader>
      <CardContent className="p-0 lg:p-2 pb-0 relative">
        {query.isLoading ? (
          <div className="max-h-[200px] min-h-[120px] aspect-video absolute inset-0 flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        ) : query.data?.length === 0 ? (
          <div className="max-h-[200px] min-h-[120px] aspect-video h-full w-full flex items-center justify-center text-xs italic text-muted">
            Insufficient data
          </div>
        ) : (
          <ChartContainer
            config={chartConfig}
            className="max-h-[200px] min-h-[120px] w-full pr-[30%]"
          >
            <PieChart>
              <Pie
                data={activeData}
                dataKey="count"
                nameKey="status"
                cx="50%"
                cy="50%"
                outerRadius={65}
                innerRadius={35}
              >
                {activeData.map((entry) => (
                  <Cell
                    key={entry.status}
                    fill={COLORS[entry.status]}
                    strokeWidth={1}
                    stroke="hsl(var(--background))"
                  />
                ))}
              </Pie>
            </PieChart>
          </ChartContainer>
        )}
        <CustomLegend />
      </CardContent>
    </Card>
  );
}
