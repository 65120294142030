import { RowSelectionState } from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { createBinding, useKeyMap } from "@/hooks/keyboard";
import { useRoute } from "@/hooks/queries/team";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";

import { useCanisterCommand } from "../commands";
import { useDeleteCommand } from "../commands/delete-command";
import { useProjectCommand } from "../commands/project-command";
import { useTagCommand } from "../commands/tag-command";

export function useKeyboardNavigation() {
  const { table, rowSelection, setRowSelection } = useCanisterTableStore();
  const rows = table.getRowModel?.()?.rows || [];
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>(-1);
  const { open: openCommand } = useCanisterCommand();
  const { open: openTagsCommand } = useTagCommand();
  const { open: openProjectsCommand } = useProjectCommand();
  const { open: openDelete } = useDeleteCommand();
  const navigate = useNavigate();
  const route = useRoute();

  // Define navigation bindings
  const navigationBindings = [
    createBinding("ArrowUp")
      .withDescription("Move highlight to previous canister")
      .handle(() => {
        const newIndex =
          highlightedRowIndex === -1 ? 0 : Math.max(0, highlightedRowIndex - 1);
        setHighlightedRowIndex(newIndex);
      }),

    createBinding("ArrowDown")
      .withDescription("Move highlight to next canister")
      .handle(() => {
        const newIndex =
          highlightedRowIndex === -1
            ? 0
            : Math.min(rows.length - 1, highlightedRowIndex + 1);
        setHighlightedRowIndex(newIndex);
      }),
  ];

  // Define selection bindings
  const selectionBindings = [
    createBinding("ArrowUp")
      .withModifiers("shift")
      .withDescription("Select previous canister")
      .handle(() => {
        const newIndex = Math.max(0, highlightedRowIndex - 1);
        setHighlightedRowIndex(newIndex);
        if (rows[newIndex]?.id) {
          setRowSelection({
            ...rowSelection,
            [rows[newIndex].id]: true,
          });
        }
      }),

    createBinding("ArrowDown")
      .withModifiers("shift")
      .withDescription("Select next canister")
      .handle(() => {
        const newIndex = Math.min(rows.length - 1, highlightedRowIndex + 1);
        setHighlightedRowIndex(newIndex);
        if (rows[newIndex]?.id) {
          setRowSelection({
            ...rowSelection,
            [rows[newIndex].id]: true,
          });
        }
      }),

    createBinding("x")
      .withDescription("Toggle selection of highlighted canister")
      .handle(() => {
        if (highlightedRowIndex !== -1 && rows[highlightedRowIndex]?.id) {
          const rowId = rows[highlightedRowIndex].id;
          setRowSelection({
            ...rowSelection,
            [rowId]: !rowSelection[rowId],
          });
        }
      }),

    createBinding("a")
      .withModifiers("meta")
      .withDescription("Select all visible canisters")
      .handle(() => {
        // Only select rows that are visible after filtering
        const filteredRows = table.getFilteredRowModel?.()?.rows || [];
        
        const allSelected = filteredRows.reduce<RowSelectionState>((acc, row) => {
          if (row.id) {
            acc[row.id] = true;
          }
          return acc;
        }, {});
        setRowSelection(allSelected);
      }),

    createBinding("Escape")
      .withDescription("Deselect all canisters")
      .handle(() => {
        setRowSelection({});
        setHighlightedRowIndex(-1);
      }),

    createBinding("Enter")
      .withDescription("Open highlighted canister")
      .handle(() => {
        const highlightedCanister = rows[highlightedRowIndex];
        if (highlightedCanister?.id) {
          navigate(
            route(`canisters/${highlightedCanister.original.id.toText()}`)
          );
        }
      }),
  ];

  // Define command bindings
  const commandBindings = [
    createBinding("f")
      .withModifiers("meta")
      .withDescription("Focus search input")
      .handle((e) => {
        e.preventDefault();
        document.getElementById("canister-search")?.focus();
      }),

    createBinding("k")
      .withModifiers("meta")
      .withDescription("Open command dialog")
      .handle((e) => {
        const selectedCount = Object.values(rowSelection).filter(
          (x) => x
        ).length;
        if (selectedCount === 0) return;
        e.preventDefault();
        openCommand();
      }),

    createBinding("t")
      .withModifiers("shift")
      .withDescription("Add tags to highlighted canisters")
      .handle((e) => {
        const selectedCount = Object.values(rowSelection).filter(
          (x) => x
        ).length;
        if (selectedCount === 0) return;
        e.preventDefault();
        openTagsCommand();
      }),

    createBinding("p")
      .withModifiers("shift")
      .withDescription("Move highlighted canisters to project")
      .handle((e) => {
        const selectedCount = Object.values(rowSelection).filter(
          (x) => x
        ).length;
        if (selectedCount === 0) return;
        e.preventDefault();
        openProjectsCommand();
      }),
  ];

  // Define delete binding
  const deleteBinding = [
    createBinding("Backspace")
      .withModifiers("meta")
      .withDescription("Delete selected canisters")
      .handle((e) => {
        const selectedCount = Object.values(rowSelection).filter(
          (x) => x
        ).length;
        if (selectedCount === 0) return;
        e.preventDefault();
        openDelete();
      }),
    createBinding("Delete")
      .withModifiers("meta")
      .withDescription("Delete selected canisters")
      .handle((e) => {
        const selectedCount = Object.values(rowSelection).filter(
          (x) => x
        ).length;
        if (selectedCount === 0) return;
        e.preventDefault();
        openDelete();
      }),
  ];

  // Combine all bindings
  useKeyMap([
    ...navigationBindings,
    ...selectionBindings,
    ...commandBindings,
    ...deleteBinding,
  ]);

  return { highlightedRowIndex, setHighlightedRowIndex };
}
