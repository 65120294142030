import { Dialog, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/ui-utils";

function CanistersCommandDialog({
  children,
  className,
  isOpen,
  onOpenChange,
}: {
  children?: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onOpenChange: (o: boolean) => void;
}) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className={cn(className, "p-0")}>{children}</DialogContent>
    </Dialog>
  );
}

function CanistersCommandDialogFooter({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="bg-muted/10 border-t border-muted/25 p-4 flex justify-between gap-4">
      {children}
    </div>
  );
}

export { CanistersCommandDialog, CanistersCommandDialogFooter };
