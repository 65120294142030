import { Principal } from "@dfinity/principal";
import BigNumber from "bignumber.js";

import {
  CycleOpsService,
  LogVisibility,
  MonitoringCanisterType__1,
  SharedCanisterStatus,
  TopupRule,
} from "common/declarations/cycleops/cycleops.did.d";

import { burnInsight } from "@/insights";
import { CanisterData } from "@/insights/types";

import { uint8ArrayToHex } from "../ui-utils";

/// Status literal for a canister
export type CanisterHealth = "healthy" | "low balance" | "frozen" | "pending";

/// The raw backend response for one of a customer's canisters
export type RawCanisterResponse = Awaited<
  ReturnType<CycleOpsService["getCanisters"]>
>[0];

// https://github.com/dfinity/ic/blob/master/rs/cycles_account_manager/src/lib.rs#L326
/// Map freezing threshold configuration to seconds, days, cycles formats
export function mapFreezingThreshold({
  freezingThreshold,
  idleBurn,
}: {
  /** Dfinity canister settings defines this as a number of seconds. */
  freezingThreshold: number;
  /** Idle cycles burn per day (comes straight from the canister settings.) */
  idleBurn: number;
}) {
  const SECONDS_PER_DAY = 86400;
  const days = freezingThreshold / SECONDS_PER_DAY;
  const cycles = idleBurn * days;
  return {
    cycles,
    seconds: freezingThreshold,
    days,
  };
}

/// Derive canister status literal from a monitoring event
export function getCanisterHealth(status: RawCanisterResponse): CanisterHealth {
  const [latestStatusTimestamp, latestStatus] = getLastOkMonitor(status);
  if (
    !latestStatusTimestamp ||
    !latestStatus ||
    latestStatus.cycles === BigInt(0)
  )
    return "pending";
  const freezing = mapFreezingThreshold({
    freezingThreshold: Number(latestStatus.settings[0]!.freezing_threshold),
    idleBurn: Number(latestStatus.idle_cycles_burned_per_day),
  });
  if (Number(latestStatus.cycles) < freezing.cycles) return "frozen";
  if (Number(latestStatus.cycles) < Number(status[1].topupRule.threshold))
    return "low balance";
  return "healthy";
}

/// Derive canister status literal from a monitoring event from Byron's new leaner paginated API
export function getCanisterHealthPaginated(
  status: CanisterData
): CanisterHealth {
  const { latestTimeSeriesData } = getLastOkMonitorPaginated(status);

  if (!latestTimeSeriesData || latestTimeSeriesData.cycles === BigInt(0))
    return "pending";

  const freezing = mapFreezingThreshold({
    freezingThreshold: Number(
      status.latestStats?.settings?.freezingThreshold ?? 0n
    ),
    idleBurn: Number(status.latestStats?.idleCyclesBurnedPerDay ?? 0n),
  });
  if (Number(latestTimeSeriesData.cycles) < freezing.cycles) return "frozen";
  if (
    Number(latestTimeSeriesData.cycles) <
    Number(status.config.topupRule.threshold)
  )
    return "low balance";
  return "healthy";
}

/// Returns a canister's latest ok status and its timestamp
export function getLastOkMonitor(
  status: RawCanisterResponse
): [Date, SharedCanisterStatus] | [undefined, undefined] {
  const [timestamp, latestStatus] = status[2]?.findLast(
    ([, x]) => "ok" in x
  ) ?? [undefined, undefined];
  if (!latestStatus || !timestamp) return [undefined, undefined];
  if (!("ok" in latestStatus)) return [undefined, undefined];
  return [new Date(Number(timestamp) / 1e6), latestStatus.ok];
}

///
export function getLastOkMonitorPaginated(status: CanisterData) {
  const timestampRaw = status.latestStats?.timestamp;
  const timestamp = timestampRaw
    ? new Date(Number(timestampRaw) / 1e6)
    : undefined;

  const recentStats = status.latestStats;
  const latestTimeSeriesData = status.seriesStatus.at(-1)?.[1];

  return {
    timestamp,
    latestTimeSeriesData,
    recentStats,
  };
}

/// Estimates when a canister's next top-up will occur
export function estimateNextTopup({
  currentBalanceTC,
  averageBurnPerDayTC,
  topUpThresholdTC,
}: {
  currentBalanceTC: number;
  averageBurnPerDayTC: number;
  topUpThresholdTC: number;
}): Date | undefined {
  const daysUntilTopUp =
    (currentBalanceTC - topUpThresholdTC) / averageBurnPerDayTC;
  const estimate = new Date(Date.now() + daysUntilTopUp * 86400000);
  if (Number.isNaN(estimate.getTime())) return undefined;
  return estimate;
}

/// Estimates when a canister will freeze
export function estimateFreezeDate({
  currentBalanceTC,
  freezingThresholdTC,
  averageBurnPerDayTC,
}: {
  currentBalanceTC: number;
  freezingThresholdTC: number;
  averageBurnPerDayTC: number;
}): Date | undefined {
  const daysUntilFreeze =
    (currentBalanceTC - freezingThresholdTC) / averageBurnPerDayTC;
  const estimate = new Date(Date.now() + daysUntilFreeze * 86400000);
  if (Number.isNaN(estimate.getTime())) return undefined;
  return estimate;
}

export interface CanisterTableData {
  id: Principal;
  name: string;
  balance: bigint;
  burnPerDay?: bigint;
  burnTotal: bigint;
  rule: TopupRule;
  status: "healthy" | "low balance" | "frozen" | "pending";
  lastBalanceCheck: Date;
  nextBalanceCheck?: Date;
  nextTopUpEst?: Date;
  idleBurnPerDay: bigint;
  freezing: {
    threshold: {
      cycles: number;
      seconds: number;
      days: number;
    };
    timeUntilFreezeEst?: Date;
  };
  moduleHash: string;
  controllers?: Principal[];
  monitoringMechanism: MonitoringCanisterType__1;
  memorySize: bigint;
  memoryThreshold?: bigint;
  reservedCycles?: bigint;
  reservedCyclesThreshold?: bigint;
  reservedCyclesLimit?: bigint;
  reservedCyclesPctUsed?: number;
  memoryAllocation?: bigint;
  computeAllocation?: bigint;
  tags?: string[];
  project?: string;
  queryCallsTotal?: bigint;
  wasmMemoryLimit?: bigint;
  logVisibility?: LogVisibility;
}

/// Big function to generate state for the new canister list/table view.
export function generateCanisterTable(canisters: CanisterData[]) {
  return canisters?.map((canister) => {
    const burnData = burnInsight(canister.seriesStatus);
    const burnDataStartDate = burnData?.points[0]?.[0];
    const burnDataEndDate = burnData?.points[burnData.points.length - 1]?.[0];
    const burnDataDays = burnDataEndDate
      ? Math.round(
          ((burnDataEndDate.getTime() ?? 0) -
            (burnDataStartDate?.getTime() ?? 0)) /
            86400000
        )
      : undefined;
    const burnPerDayAvg = burnDataDays
      ? burnData.points.reduce((acc, [, v]) => acc + (v ?? 0n), 0n) /
        BigInt(burnDataDays)
      : undefined;
    const burnTotal = burnData?.points.reduce(
      (acc, [, v]) => acc + (v ?? 0n),
      0n
    );

    const {
      timestamp: latestStatusTimestamp,
      latestTimeSeriesData,
      recentStats,
    } = getLastOkMonitorPaginated(canister);

    const SIX_HOURS = 21600000;
    const nextBalanceCheck = latestStatusTimestamp
      ? new Date(latestStatusTimestamp.getTime() + SIX_HOURS)
      : undefined;
    const status = getCanisterHealthPaginated(canister);
    const settings = recentStats?.settings;
    const { name } = canister.config;

    const freezing = {
      threshold: mapFreezingThreshold({
        freezingThreshold: Number(settings?.freezingThreshold ?? 0n),
        idleBurn: Number(recentStats?.idleCyclesBurnedPerDay ?? 0n),
      }),
      timeUntilFreezeEst: estimateFreezeDate({
        averageBurnPerDayTC: Number(burnPerDayAvg ?? 0),
        currentBalanceTC: Number(latestTimeSeriesData?.cycles ?? 0),
        freezingThresholdTC: mapFreezingThreshold({
          freezingThreshold: Number(settings?.freezingThreshold ?? 0n),
          idleBurn: Number(recentStats?.idleCyclesBurnedPerDay ?? 0n),
        }).cycles,
      }),
    };

    const nextTopUpEst = estimateNextTopup({
      averageBurnPerDayTC: burnPerDayAvg ? Number(burnPerDayAvg) : NaN,
      currentBalanceTC: Number(latestTimeSeriesData?.cycles),
      topUpThresholdTC: Number(canister.config.topupRule.threshold),
    });

    const burnPerDay = (() => {
      const last = burnData?.points[burnData.points.length - 1];
      if (!last) return undefined;
      const [timestamp] = last;

      const twentyFourHoursAgo = timestamp.getTime() - 86400000;
      const data = burnData?.points.filter(
        ([t]) => t.getTime() > twentyFourHoursAgo
      );

      // Reduce the data to the average burn per day
      const result =
        data.reduce((acc, [, v]) => acc + (v ?? 0n), 0n) / BigInt(data.length);
      return result;
    })();

    const tags = canister.metadata?.keywordTags;
    const project = canister.metadata?.projectName;

    const { memoryThreshold, reservedCyclesPercentageThreshold } =
      canister.config;

    const reservedCyclesLimit = recentStats?.settings?.reservedCyclesLimit;
    const queryCallsTotal = latestTimeSeriesData?.queryStats?.num_calls_total;
    const wasmMemoryLimit = recentStats?.settings?.wasmMemoryLimit;
    const logVisibility = recentStats?.settings?.logVisibility;
    const reservedCyclesPctUsed =
      reservedCyclesLimit && canister.latestStats?.reservedCycles
        ? BigNumber(canister.latestStats.reservedCycles.toString())
            .div(BigNumber(reservedCyclesLimit.toString()))
            .times(100)
            .integerValue()
            .toNumber()
        : undefined;

    const result = {
      id: canister.canisterId,
      name,
      balance: latestTimeSeriesData?.cycles || 0n,
      burnPerDay,
      burnTotal,
      rule: canister.config.topupRule,
      status,
      memoryThreshold,
      lastBalanceCheck: latestStatusTimestamp
        ? new Date(Number(latestStatusTimestamp))
        : new Date(),
      nextBalanceCheck,
      nextTopUpEst,
      idleBurnPerDay: canister.latestStats?.idleCyclesBurnedPerDay || 0n,
      freezing,
      moduleHash: uint8ArrayToHex(canister.latestStats?.moduleHash) || "",
      controllers: canister.latestStats?.settings?.controllers,
      monitoringMechanism: canister.config.monitoringType,
      memorySize: latestTimeSeriesData?.memorySize || 0n,
      reservedCycles: canister.latestStats?.reservedCycles,
      reservedCyclesThreshold: reservedCyclesPercentageThreshold,
      reservedCyclesPctUsed,
      reservedCyclesLimit,
      memoryAllocation: canister.latestStats?.settings?.memoryAllocation,
      computeAllocation: canister.latestStats?.settings?.computeAllocation,
      tags,
      project,
      queryCallsTotal,
      wasmMemoryLimit,
      logVisibility,
    } satisfies CanisterTableData;
    return result;
  });
}
