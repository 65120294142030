import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";

import CanisterCreationWizard from "@/components/canister-create/canister-creation-wizard";
import useCanisterCreationStore from "@/components/canister-create/canister-creation-wizard-store";
import LayoutFocus, { FocusTopbar } from "@/components/layout/layout-focus";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  useCreateCanisterMutation,
  useKnownSubnetsQuery,
} from "@/hooks/queries/canister-creation";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { AppLink, useRoute } from "@/hooks/queries/team";

export default function ExperimentalCreateCanister() {
  const navigate = useNavigate();
  const {
    dialog: { open },
  } = useCanisterCreationStore();
  return (
    <LayoutFocus>
      <FocusTopbar>
        <SiteBreadcrumb items={[{ title: "Create Canister" }]} />
        <Button variant="outline" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </FocusTopbar>
      <div className="border border-border/75 flex flex-1 gap-4 p-2 md:p-4 pt-0 relative bg-table rounded-lg overflow-hidden">
        <div className="h-auto absolute inset-0 overflow-y-auto">
          <div className="flex flex-col gap-4 p-4">
            {/* <ExperimentalCreateButton />
            <KnownSubnets /> */}
            <CanisterCreationWizard />
            <Button onClick={() => open()}>Open</Button>
          </div>
        </div>
      </div>
    </LayoutFocus>
  );
}

function KnownSubnets() {
  const query = useKnownSubnetsQuery();
  if (query.isLoading) return <div>Loading...</div>;

  return (
    <div className="">
      Known subnets
      {query.data?.map((subnet) => (
        <div className="" key={subnet.id.toString()}>
          <div className="">id: {subnet.id.toString()}</div>
          <div className="">type: {subnet.subnetType}</div>
          <div className="">specialization: {subnet.subnetSpecialization}</div>
        </div>
      ))}
    </div>
  );
}

interface SuccessDialogState {
  canisterId?: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setCanisterId: (canisterId: string) => void;
}

const useSuccessDialog = create<SuccessDialogState>((set) => ({
  canisterId: undefined,
  isOpen: false,
  setOpen: (isOpen) => set({ isOpen }),
  setCanisterId: (canisterId) => set({ canisterId }),
}));

function SuccessDialog() {
  const { isOpen, setOpen, canisterId } = useSuccessDialog();
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className="gap-4">
        <DialogHeader>
          <DialogTitle>Canister creation complete</DialogTitle>
        </DialogHeader>
        <div className="">Your canister has been created.</div>
        <div className="">{canisterId}</div>
        <AppLink to={`/canisters/${canisterId}`}>
          <Button>View canister</Button>
        </AppLink>
      </DialogContent>
    </Dialog>
  );
}

function ExperimentalCreateButton() {
  const mutation = useCreateCanisterMutation();
  const canisters = useCanisterTableQuery();
  const { setCanisterId, setOpen } = useSuccessDialog();

  const handleClick = () => {
    mutation.mutate({
      controllers: [],
      withStartingCyclesBalance: BigInt(1e11),
    });
  };

  useEffect(() => {
    if (mutation.data?.ok) {
      canisters.refetch().then(() => {
        setCanisterId(mutation.data.ok.toText());
        setOpen(true);
      });
    }
  }, [mutation.data]);

  return (
    <>
      <Button onClick={handleClick} loading={mutation.isPending}>
        Create Canister
      </Button>
      <SuccessDialog />
    </>
  );
}
