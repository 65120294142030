import { Principal } from "@dfinity/principal";
import { useParams } from "react-router-dom";

import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";

import CanisterControllers from "./manage/controllers";
import CanisterLogs from "./manage/logs";
import CanisterSettings from "./manage/settings";
import CanisterSnapshots from "./manage/snapshots";
import CanisterStatus from "./manage/status";

function useCanister() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = Principal.fromText(canisterIdString!);
  return useCanisterTableDetailQuery(canisterId);
}

export default function ManageCanisterPage() {
  const { canisterId: canisterIdString } = useParams();
  const canister = useCanister();

  return (
    <div className="px-1 py-4 md:p-4 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex max-md:flex-col gap-4">
          <h1 className="text-lg font-semibold max-md:px-2">
            {canister?.data?.name}
          </h1>
        </div>
        {/* <TimeSettings /> */}
      </div>
      <div className="@container">
        <div className="flex flex-col gap-4">
          <CanisterStatus canisterIdString={canisterIdString!} />
          <CanisterControllers canisterIdString={canisterIdString!} />
          <CanisterSettings canisterIdString={canisterIdString!} />
          <CanisterSnapshots canisterIdString={canisterIdString!} />
          <CanisterLogs canisterIdString={canisterIdString!} />
        </div>
      </div>
    </div>
  );
}
