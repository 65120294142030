import { Principal } from "@dfinity/principal";
import {
  CellContext,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
} from "@tanstack/react-table";
import { CheckIcon } from "lucide-react";
import React from "react";
import { create } from "zustand";

import {
  DataTableColumnHeader,
  DataTableColumnHeaderProps,
} from "@/components/data-table/column-header";
import TableSkeleton from "@/components/table-skeleton";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import {
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  Table,
} from "@/components/ui/table";
import { useSubnetMetadataQuery } from "@/hooks/queries/canister-creation";
import {
  useCanisterTableDetailQuery,
  useCanisterTableQuery,
} from "@/hooks/queries/canisters";
import { useCanisterMetadataQuery } from "@/hooks/queries/internet-computer";
import { CanisterTableData } from "@/lib/insights/canister-insights";
import { cn } from "@/lib/ui-utils";

import { TC, TCtoUSD } from "../tc";
import useCanisterCreationStore from "./canister-creation-wizard-store";

function NameCell({ getValue }: CellContext<CanisterTableData, string>) {
  const name = (() => {
    const n = getValue();
    try {
      const p = Principal.fromText(n);
      return p.toText().slice(0, 5);
    } catch {
      return n;
    }
  })();

  return (
    <div className="text-xs max-w-[100px] truncate text-ellipsis">{name}</div>
  );
}

function SubnetCell(cell: CellContext<CanisterTableData, unknown>) {
  const subnet = useCanisterMetadataQuery(cell.row.original.id.toText());
  if (subnet.isLoading) return <Skeleton className="w-full h-4" />;
  return (
    <div className="font-mono text-xs">{subnet.data?.subnet?.slice(0, 5)}</div>
  );
}

function useSubnetMeta(canisterId: Principal) {
  const subnet = useCanisterMetadataQuery(canisterId.toText());
  const principal = subnet.data?.subnet
    ? Principal.fromText(subnet.data.subnet)
    : undefined;
  return useSubnetMetadataQuery(principal);
}

function TypeCell(cell: CellContext<CanisterTableData, unknown>) {
  const meta = useSubnetMeta(cell.row.original.id);
  if (meta.isLoading) return <Skeleton className="w-full h-4" />;
  return <div className="text-xs">{meta.data?.subnetType}</div>;
}

function NodesCell(cell: CellContext<CanisterTableData, unknown>) {
  const meta = useSubnetMeta(cell.row.original.id);
  if (meta.isLoading) return <Skeleton className="w-full h-4" />;
  return <div className="text-xs">{meta.data?.totalNodes}</div>;
}

function CostCell(cell: CellContext<CanisterTableData, unknown>) {
  const meta = useSubnetMeta(cell.row.original.id);
  if (meta.isLoading) return <Skeleton className="w-full h-4" />;
  return (
    <div className="text-xs text-nowrap">
      <TC value={meta.data?.cost} figs={2} includeUnit />{" "}
      <span className="text-xs text-muted-foreground">
        (<TCtoUSD value={meta.data?.cost} />)
      </span>
    </div>
  );
}

function SelectCell(cell: CellContext<CanisterTableData, unknown>) {
  const { subnetSelection } = useCanisterCreationStore();
  const isSelected =
    cell.row.original.id.toText() ===
    subnetSelection.selectedCanisterId?.toText();
  return (
    <div className="flex items-center justify-end">
      <div
        className={cn(
          "h-4 w-4 rounded-full border flex items-center justify-center",
          isSelected
            ? "border-primary bg-primary"
            : "border-muted-foreground/20"
        )}
      >
        {isSelected && <CheckIcon className="h-4 w-4 text-background" />}
      </div>
    </div>
  );
}

interface CanisterTableState {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

const useCanisterTableState = create<CanisterTableState>((set) => ({
  globalFilter: "",
  setGlobalFilter: (value) => set({ globalFilter: value }),
}));

function Header<TData, TValue>({
  column,
}: DataTableColumnHeaderProps<TData, TValue>) {
  return (
    <div className="py-1">
      <DataTableColumnHeader
        column={column}
        className="text-xs py-0 h-8 rounded-sm"
      />
    </div>
  );
}

function SearchHeader() {
  const { globalFilter, setGlobalFilter } = useCanisterTableState();

  return (
    <Input
      placeholder="Search canisters..."
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value)}
      className="h-7 text-xs w-[150px]"
    />
  );
}

const column = createColumnHelper<CanisterTableData>();

const columns = [
  column.accessor("name", {
    id: "Name",
    cell: NameCell,
    enableSorting: true,
    header: Header,
  }),
  column.display({
    id: "Subnet",
    cell: SubnetCell,
    enableSorting: false,
    header: Header,
  }),
  column.display({
    id: "Type",
    cell: TypeCell,
    enableSorting: false,
    header: Header,
  }),
  // column.display({
  //   id: "Nodes",
  //   cell: NodesCell,
  //   enableSorting: false,
  //   header: Header,
  // }),
  column.display({
    id: "Cost",
    cell: CostCell,
    enableSorting: false,
    header: Header,
  }),
  column.display({
    id: "select",
    header: SearchHeader,
    cell: SelectCell,
    enableSorting: false,
    size: 200,
  }),
];

function DataTable() {
  const subnetSelection = useCanisterCreationStore((s) => s.subnetSelection);
  const { data: canisters, isLoading } = useCanisterTableQuery();
  const { globalFilter } = useCanisterTableState();

  const [sorting, setSorting] = React.useState([{ id: "Name", desc: false }]);

  const table = useReactTable<CanisterTableData>({
    data: canisters ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter },
    initialState: { globalFilter },
    globalFilterFn: (row, columnId, filterValue) => {
      if (typeof filterValue === "string") {
        return (
          row.original.id
            .toText()
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          row.original.name.toLowerCase().includes(filterValue.toLowerCase())
        );
      }
      return false;
    },
  });

  if (isLoading) return <TableSkeleton />;

  return (
    <Table>
      <TableHeader className="sticky top-0 h-6 py-0 ring-1 ring-border/50 bg-background">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="h-6 py-0">
            {headerGroup.headers.map((header) => (
              <TableHead
                key={header.id}
                className="py-1 text-xs font-medium h-6 py-0"
                style={{ width: header.column.getSize() }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              className={cn(
                "cursor-pointer h-8 hover:bg-muted/30",
                subnetSelection.selectedCanisterId?.toText() ===
                  row.original.id.toText() &&
                  "dark:bg-blue-950 bg-blue-50 ring-1 ring-blue-600 sticky top-10 bottom-0"
              )}
              onClick={() =>
                subnetSelection.setSelectedCanisterId(row.original.id)
              }
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="py-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="h-8 text-center text-sm"
            >
              No canisters found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default function CanisterTable() {
  const canisterId = useCanisterCreationStore(
    (s) => s.subnetSelection.selectedCanisterId
  );
  const canister = useCanisterTableDetailQuery(canisterId);
  return (
    <div className="flex-1 flex flex-col overflow-hidden gap-2">
      <div className="flex-1 border rounded-sm overflow-y-auto">
        <DataTable />
      </div>
      <div className="text-xs text-muted-foreground">
        {canisterId
          ? `Canister will be deployed to the same subnet as canister "${canister.data?.name}"`
          : "Select a canister to deploy with."}
      </div>
    </div>
  );
}
