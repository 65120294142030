import { Principal } from "@dfinity/principal";
import { format, formatDuration } from "date-fns";
import { Info, Loader2 } from "lucide-react";
import { useMemo } from "react";
import {
  Brush,
  CartesianGrid,
  TooltipProps,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
} from "recharts";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterQueryCallsInsight } from "@/hooks/queries/insights";
import { AppLink } from "@/hooks/queries/team";
import { CanisterTableData } from "@/lib/insights/canister-insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { Button } from "../ui/button";
import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { ChartFigure } from "./burn";
import { TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

interface QueryCallData {
  timestamp: Date;
  amount: number;
  periodDuration: number;
}

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  const data = payload?.[0]?.payload as QueryCallData | undefined;

  if (!data) return null;

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Query Calls</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Calls This Period</div>
        <div className="font-mono">{data.amount} calls</div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Period Duration</div>
        <div className="">
          {data.periodDuration &&
            formatDuration({
              hours: Math.round(data.periodDuration / 60 / 60 / 1000),
            })}
        </div>
      </div>
    </div>
  );
}

export function ChartLoading() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Loader2 className="w-4 h-4 animate-spin" />
    </div>
  );
}

function ChartBlackholeCTA() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <AppLink
        to="/blackhole-upgrade"
        className="bg-blue-800/10 border-blue-800/25 rounded-sm border p-2 flex flex-col gap-2 hover:bg-blue-800/20 hover:border-blue-800/35 transition-colors cursor-pointer"
      >
        <div className="text-sm flex items-center gap-2">
          <Info className="w-4 h-4" /> Upgrade blackhole now
        </div>
        <div className="text-xs max-w-[20rem] opacity-75">
          This canister is monitored with an old blackhole version. Upgrade in a
          few minutes for improved monitoring.
        </div>
        <Button
          className="w-auto self-start text-xs px-2 py-1 h-6 rounded-sm"
          size="sm"
        >
          Upgrade now
        </Button>
      </AppLink>
    </div>
  );
}

function ChartMonitoringWarning() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-yellow-800/10 border-yellow-800/25 rounded-sm border p-2 flex flex-col gap-2 hover:bg-yellow-800/20 hover:border-yellow-800/35 transition-colors cursor-pointer">
        <div className="text-sm flex items-center gap-2">
          <Info className="w-4 h-4" /> Data unavailable
        </div>
        <div className="text-xs max-w-[20rem] opacity-75">
          This metric is currently not supported by your monitoring mechanism.
        </div>
      </div>
    </div>
  );
}

function Title({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterQueryCallsInsight(canisterId, timeSettings);
  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, data]) => ({
        timestamp,
        amount: Number(data),
      })),
    [insight]
  );
  const { startDate, endDate, formatString } = useChartBrush({
    data: chartData,
  });
  return (
    <CardTitle className="text-sm">
      Query Calls{" "}
      <TimeSpanString
        startDate={startDate}
        endDate={endDate}
        formatString={formatString}
      />
    </CardTitle>
  );
}

function Figure({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterQueryCallsInsight(canisterId, timeSettings);
  const canister = useCanisterTableDetailQuery(canisterId);

  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, data]) => ({
        timestamp,
        amount: Number(data),
      })),
    [insight]
  );

  const totalCalls = Number(canister.data?.queryCallsTotal || 0);
  const lastTimestamp = chartData?.[chartData.length - 1]?.timestamp;

  if (!chartData.length) return null;
  return (
    <ChartFigure
      main={`${totalCalls.toLocaleString()} calls`}
      subheader={lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"}
    />
  );
}

function Header({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterQueryCallsInsight(canisterId, timeSettings);
  const canister = useCanisterTableDetailQuery(canisterId);

  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, data]) => ({
        timestamp,
        amount: Number(data),
      })),
    [insight]
  );

  const totalCalls = Number(canister.data?.queryCallsTotal || 0);

  const lastTimestamp = chartData?.[chartData.length - 1]?.timestamp;
  return (
    <CardHeader className="px-3 py-3 gap-2">
      {chartData.length > 0 && (
        <ChartFigure
          main={`${totalCalls.toLocaleString()} calls`}
          subheader={
            lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"
          }
        />
      )}
    </CardHeader>
  );
}

function Chart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterQueryCallsInsight(canisterId, timeSettings);

  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, data]) => ({
        timestamp,
        amount: Number(data),
      })),
    [insight]
  );

  const { brushProps, formatString } = useChartBrush({
    data: chartData,
  });

  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  const chartConfig = {
    amount: {
      label: "Query Calls",
      color: "hsl(var(--foreground))",
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig} className="h-[200px] w-full">
      <ComposedChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 20,
          right: 0,
          top: 20,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="timestamp"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={tickFormatter}
        />
        <YAxis
          yAxisId="right"
          key="amount"
          tickLine={true}
          axisLine={false}
          unit=" calls"
          orientation="right"
        />
        <ChartTooltip
          cursor={{
            strokeWidth: 2,
          }}
          content={<CustomTooltip />}
        />
        <Bar
          yAxisId="right"
          dataKey="amount"
          barSize={20}
          fill="hsl(var(--foreground))"
        />
        <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
      </ComposedChart>
    </ChartContainer>
  );
}

export function QueryCallsCard({ canisterId }: { canisterId?: Principal }) {
  const canister = useCanisterTableDetailQuery(canisterId);
  const migrate = blackholeVersion(canister.data) === 1n;

  const content = (() => {
    if (migrate) return <ChartBlackholeCTA />;
    if (canister.isLoading || !canister.data) return <ChartLoading />;
    if ("sns" in canister.data.monitoringMechanism)
      return <ChartMonitoringWarning />;
    if ("nns" in canister.data.monitoringMechanism)
      return <ChartMonitoringWarning />;
    return <Chart canisterId={canisterId} />;
  })();

  const figure = (() => {
    if (migrate) return null;
    if (canister.isLoading || !canister.data) return null;
    if ("sns" in canister.data.monitoringMechanism) return null;
    if ("nns" in canister.data.monitoringMechanism) return null;
    return <Figure canisterId={canisterId} />;
  })();

  return (
    <Card className="rounded-sm pb-3 bg-transparent flex flex-col">
      <CardHeader className="px-3 py-3 gap-2">
        <Title canisterId={canisterId} />
        {figure}
      </CardHeader>
      <CardContent className="px-2 py-2 flex-1">{content}</CardContent>
    </Card>
  );
}

function blackholeVersion(c?: CanisterTableData) {
  if (!c) return false;
  if (!("blackhole" in c.monitoringMechanism)) return false;
  return c.monitoringMechanism.blackhole;
}
