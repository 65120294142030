import {
  Actor,
  ActorSubclass,
  CanisterStatus,
  getManagementCanister,
} from "@dfinity/agent";
import { IDL } from "@dfinity/candid";
import { Principal } from "@dfinity/principal";
import { useMutation } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/ui-utils";

import { alwaysMainnetAgent } from "../lib/actors";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "./ui/collapsible";
import { Input } from "./ui/input";
import { ScrollArea } from "./ui/scroll-area";
import { Separator } from "./ui/separator";
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";

type ResultDisplayType = "text" | "ui" | "json";

type MethodResult = {
  raw: unknown;
  processed: unknown[];
};

interface MethodProps {
  canister: ActorSubclass;
  name: string;
  idlFunc: IDL.FuncClass;
  onCallComplete?: () => void;
}

interface TextResultProps {
  result: unknown[];
  retTypes: IDL.Type[];
  duration?: number;
}

interface UIResultProps {
  result: unknown[];
  retTypes: IDL.Type[];
}

interface JsonResultProps {
  result: unknown;
}

interface LogEntryProps {
  timestamp: Date;
  content: string;
}

const isQuery = (func: IDL.FuncClass): boolean => {
  return (
    func.annotations.includes("query") ||
    func.annotations.includes("composite_query")
  );
};

function TextResult({ result, retTypes, duration }: TextResultProps) {
  const formattedResult = useMemo(() => {
    const candidString = IDL.FuncClass.argsToString(retTypes, result);
    return candidString.split(/([<>\n ])/g).map((part, index) => {
      switch (part) {
        case " ":
          return <span key={index}>&nbsp;</span>;
        case "<":
          return <span key={index}>&lt;</span>;
        case ">":
          return <span key={index}>&gt;</span>;
        case "\n":
          return <br key={index} />;
        default:
          return <span key={index}>{part}</span>;
      }
    });
  }, [result, retTypes]);

  return (
    <div className="bg-muted rounded-md p-4">
      <div className="font-mono text-sm whitespace-pre-wrap">
        {formattedResult}
      </div>
      {duration && (
        <div className="text-muted-foreground text-xs mt-2">({duration}s)</div>
      )}
    </div>
  );
}

function UIResult({ result, retTypes }: UIResultProps) {
  return (
    <div className="space-y-4">
      {retTypes.map((type, index) => (
        <div key={index} className="space-y-2">
          <div className="text-sm text-muted-foreground font-mono">
            {type.display()}
          </div>
          <div className="bg-muted rounded-md p-4">
            <pre className="text-sm font-mono whitespace-pre-wrap">
              {formatCandidValue(type, result[index])}
            </pre>
          </div>
        </div>
      ))}
    </div>
  );
}

interface CandidRecord {
  [key: string]: unknown;
}

interface CandidVariant {
  [key: string]: unknown;
}

function formatCandidValue(type: IDL.Type, value: unknown): string {
  if (value === null || value === undefined) return "null";

  if (type instanceof IDL.RecordClass) {
    const record = value as CandidRecord;
    const recordType = type as unknown as {
      _fields: Array<[string, IDL.Type]>;
    };
    const fields = recordType._fields.map(
      ([name, fieldType]) =>
        `${name}: ${formatCandidValue(fieldType, record[name])}`
    );
    return `{\n  ${fields.join(",\n  ")}\n}`;
  }

  if (type instanceof IDL.VecClass) {
    const array = value as unknown[];
    if (array.length === 0) return "[]";
    const vecType = type as unknown as { _type: IDL.Type };
    const items = array.map((item) => formatCandidValue(vecType._type, item));
    return `[\n  ${items.join(",\n  ")}\n]`;
  }

  if (type instanceof IDL.OptClass) {
    if (Array.isArray(value) && value.length === 0) return "null";
    if (Array.isArray(value) && value.length === 1) {
      const optType = type as unknown as { _type: IDL.Type };
      return `opt ${formatCandidValue(optType._type, value[0])}`;
    }
    return "null";
  }

  if (type instanceof IDL.VariantClass) {
    const variant = value as CandidVariant;
    const field = Object.entries(variant)[0];
    if (!field) return "null";
    const [name, val] = field;
    const variantType = type as unknown as {
      _fields: Array<[string, IDL.Type]>;
    };
    const fieldType = variantType._fields.find(([n]) => n === name)?.[1];
    if (!fieldType) return "null";
    return `variant ${name} ${formatCandidValue(fieldType, val)}`;
  }

  if (type instanceof IDL.PrincipalClass) {
    return value instanceof Principal ? value.toString() : String(value);
  }

  if (type instanceof IDL.BoolClass) {
    return String(value);
  }

  if (type instanceof IDL.IntClass || type instanceof IDL.NatClass) {
    return typeof value === "bigint" ? value.toString() : String(value);
  }

  if (type instanceof IDL.TextClass) {
    return `"${String(value)}"`;
  }

  return String(value);
}

function JsonResult({ result }: JsonResultProps) {
  const jsonString = useMemo(() => {
    return JSON.stringify(
      result,
      (_, v) => (typeof v === "bigint" ? v.toString() : v),
      2
    );
  }, [result]);

  return (
    <div className="bg-muted rounded-md p-4">
      <pre className="font-mono text-sm whitespace-pre-wrap overflow-x-auto">
        {jsonString}
      </pre>
    </div>
  );
}

export function formatSignature(signature: string): string {
  const isQueryFunc = signature.includes("query");
  const cleanSignature = signature.replace(" query", "").trim();

  // Helper to format a complex type (record, variant, vec, opt)
  const formatType = (type: string, indent = 0): string => {
    // Handle vec and opt types - keep them on the same line
    if (type.startsWith("vec ")) {
      const innerType = type.slice(4);
      return `vec ${formatType(innerType, indent)}`;
    }

    if (type.startsWith("opt ")) {
      const innerType = type.slice(4);
      return `opt ${formatType(innerType, indent)}`;
    }

    // Handle record and variant types
    if (type.includes("record {") || type.includes("variant {")) {
      const typeMatch = type.match(/^(record|variant)\s*\{(.*)\}$/);
      if (!typeMatch || !typeMatch[1] || !typeMatch[2]) {
        return type;
      }

      const [, prefix, content] = typeMatch;
      const fields = content
        .split(/[,;]/)
        .map((f) => f.trim())
        .filter(Boolean);

      // Keep simple variants on one line
      if (
        prefix === "variant" &&
        fields.every((f) => !f.includes("record {") && !f.includes("variant {"))
      ) {
        return `${prefix} {${fields.join("; ")}}`;
      }

      return formatComplexType(prefix, content, indent);
    }

    return type;
  };

  const formatComplexType = (
    prefix: string,
    content: string,
    indent = 0
  ): string => {
    const fields = [];
    let currentField = "";
    let braceCount = 0;

    // Parse fields handling nested braces
    for (let i = 0; i < content.length; i++) {
      const char = content[i];
      if (char === "{") braceCount++;
      if (char === "}") braceCount--;

      if ((char === ";" || char === ",") && braceCount === 0) {
        if (currentField.trim()) fields.push(currentField.trim());
        currentField = "";
      } else {
        currentField += char;
      }
    }
    if (currentField.trim()) fields.push(currentField.trim());

    if (fields.length === 0) {
      return `${prefix.trim()} {}`;
    }

    const formattedFields = fields.map((field) => {
      const parts = field.split(":");
      const name = parts[0]?.trim() || field.trim();
      const value = parts.slice(1).join(":").trim();

      if (!value) {
        return `${" ".repeat(indent + 2)}${name}`;
      }

      const formattedValue = formatType(value, indent + 2);
      const needsNewline = formattedValue.includes("\n");

      if (needsNewline) {
        return `${" ".repeat(indent + 2)}${name}:${formattedValue}`;
      }

      return `${" ".repeat(indent + 2)}${name}:${formattedValue}`;
    });

    return [
      `${prefix.trim()} {`,
      formattedFields.join(";\n"),
      `${" ".repeat(indent)}}`,
    ].join("\n");
  };

  // Split into input and output parts
  const arrowParts = cleanSignature.split("→");
  if (arrowParts.length !== 2) return signature;

  const [input, output] = arrowParts.map((s) => s.trim());
  if (!input || !output) return signature;

  // Format input (removing outer parentheses)
  const inputContent = input.slice(1, -1).trim();
  const formattedInput = inputContent ? formatType(inputContent) : "";

  // Format output (removing outer parentheses)
  const outputContent = output.slice(1, -1).trim();
  const formattedOutput = outputContent ? formatType(outputContent) : "";

  // If both input and output are simple, keep it on one line
  const isSimpleInput =
    !inputContent.includes("record {") && !inputContent.includes("variant {");
  const isSimpleOutput =
    !outputContent.includes("record {") && !outputContent.includes("variant {");

  if (isSimpleInput && isSimpleOutput) {
    return `(${formattedInput}) → (${formattedOutput})${
      isQueryFunc ? " query" : ""
    }`;
  }

  // For complex types, format with appropriate line breaks
  return `(${formattedInput}) → (${formattedOutput})${
    isQueryFunc ? " query" : ""
  }`;
}

function MethodSignature({
  name,
  signature,
}: {
  name: string;
  signature: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const formattedSignature = useMemo(() => {
    const formatted = formatSignature(signature);
    return formatted;
  }, [signature]);

  const shouldCollapse =
    signature.includes("record {") ||
    signature.includes("variant {") ||
    signature.length > 80;

  return (
    <div className="mb-4">
      <span className="font-semibold text-lg">{name}</span>
      {shouldCollapse ? (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} className="mt-2">
          <CollapsibleTrigger className="flex items-center gap-2 w-full text-left px-3 py-2 bg-muted rounded-md text-sm text-muted-foreground font-mono">
            <span className="flex-1 truncate">
              {signature.replace("→ query", "").trim()}
            </span>
            <ChevronDown
              className={cn(
                "h-4 w-4 shrink-0 transition-transform duration-200",
                isOpen && "transform rotate-180"
              )}
            />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="mt-2 px-3 py-2 bg-muted rounded-md text-sm text-muted-foreground font-mono whitespace-pre-wrap">
              <pre className="whitespace-pre">{formattedSignature}</pre>
            </div>
          </CollapsibleContent>
        </Collapsible>
      ) : (
        <div className="mt-2 px-3 py-2 bg-muted rounded-md text-sm text-muted-foreground font-mono">
          <pre className="whitespace-pre">{signature}</pre>
        </div>
      )}
    </div>
  );
}

function ResultDisplay({
  result,
  error,
  duration,
  type,
  retTypes,
  onTypeChange,
}: {
  result: MethodResult;
  error?: string;
  duration?: number;
  type: ResultDisplayType;
  retTypes: IDL.Type[];
  onTypeChange: (type: ResultDisplayType) => void;
}) {
  if (error) {
    return (
      <div className="text-destructive bg-destructive/10 p-4 rounded-md">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <ToggleGroup
        type="single"
        value={type}
        onValueChange={(value) =>
          value && onTypeChange(value as ResultDisplayType)
        }
        className="border rounded-md p-0.5"
        size="sm"
      >
        <ToggleGroupItem value="text" className="text-xs">
          Text
        </ToggleGroupItem>
        <ToggleGroupItem value="ui" className="text-xs">
          UI
        </ToggleGroupItem>
        <ToggleGroupItem value="json" className="text-xs">
          JSON
        </ToggleGroupItem>
      </ToggleGroup>

      <div className="pt-2">
        {(() => {
          switch (type) {
            case "text":
              return (
                <TextResult
                  result={result.processed}
                  retTypes={retTypes}
                  duration={duration}
                />
              );
            case "ui":
              return <UIResult result={result.processed} retTypes={retTypes} />;
            case "json":
              return <JsonResult result={result.raw} />;
            default:
              return (
                <div className="text-yellow-600 bg-yellow-50 p-4 rounded-md">
                  Unknown display type: {type}
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
}

function MethodInput({
  type,
  value,
  onChange,
}: {
  type: IDL.Type;
  value: string;
  onChange: (value: string) => void;
}) {
  const placeholder = useMemo(() => {
    if (type instanceof IDL.PrincipalClass) return "Principal ID";
    if (type instanceof IDL.TextClass) return "Text";
    if (type instanceof IDL.NatClass) return "Natural number";
    if (type instanceof IDL.IntClass) return "Integer";
    if (type instanceof IDL.BoolClass) return "true/false";
    return type.display();
  }, [type]);

  return (
    <div className="space-y-2">
      <div className="text-sm text-muted-foreground font-mono">
        {type.display()}
      </div>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}

function LogEntry({ timestamp, content }: LogEntryProps) {
  return (
    <div className="py-2 border-b last:border-0 border-border">
      <span className="text-muted-foreground text-xs">
        [{timestamp.toISOString()}]
      </span>
      <span className="ml-2 text-sm">{content}</span>
    </div>
  );
}

function MethodRenderer({
  canister,
  name,
  idlFunc,
  onCallComplete,
}: MethodProps) {
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [displayType, setDisplayType] = useState<ResultDisplayType>("text");

  useEffect(() => {
    setInputValues(new Array(idlFunc.argTypes.length).fill(""));
  }, [idlFunc]);

  const parseValue = useCallback((type: IDL.Type, value: string): unknown => {
    try {
      if (type instanceof IDL.PrincipalClass) return Principal.fromText(value);
      if (type instanceof IDL.TextClass) return value;
      if (type instanceof IDL.NatClass) return BigInt(value);
      if (type instanceof IDL.IntClass) return BigInt(value);
      if (type instanceof IDL.BoolClass) return value === "true";
      // Add more type parsing as needed
      return value;
    } catch (err) {
      throw new Error(`Invalid value for type ${type.display()}: ${value}`);
    }
  }, []);

  const methodCall = useMutation({
    mutationFn: async (args: unknown[]) => {
      const tStart = Date.now();
      const callResult = await canister[name]!(...args);
      const duration = (Date.now() - tStart) / 1000;

      const processedResult =
        idlFunc.retTypes.length === 0
          ? []
          : idlFunc.retTypes.length === 1
          ? [callResult]
          : callResult;

      return {
        raw: callResult,
        processed: Array.isArray(processedResult)
          ? processedResult
          : [processedResult],
        duration,
      };
    },
    onSuccess: () => {
      onCallComplete?.();
    },
  });

  const handleCall = useCallback(() => {
    try {
      const args = idlFunc.argTypes.map((type, index) =>
        parseValue(type, inputValues[index]!)
      );
      methodCall.mutate(args);
    } catch (err) {
      console.error("Error parsing inputs:", err);
    }
  }, [inputValues, idlFunc.argTypes, parseValue, methodCall]);

  const handleRandom = useCallback(() => {
    // TODO: Implement random value generation for each type
    console.warn("Random value generation not implemented yet");
  }, []);

  return (
    <Card id={name}>
      <CardHeader>
        <MethodSignature name={name} signature={idlFunc.display()} />
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="flex flex-col gap-4">
            {idlFunc.argTypes.map((type, i) => (
              <MethodInput
                key={i}
                type={type}
                value={inputValues[i]!}
                onChange={(value) => {
                  const newValues = [...inputValues];
                  newValues[i] = value;
                  setInputValues(newValues);
                }}
              />
            ))}
          </div>

          <div className="flex gap-3">
            <Button
              variant={isQuery(idlFunc) ? "outline" : "default"}
              onClick={handleCall}
              loading={methodCall.isPending}
            >
              {isQuery(idlFunc) ? "Query" : "Call"}
            </Button>
            <Button
              variant="secondary"
              onClick={handleRandom}
              disabled={methodCall.isPending}
            >
              Random
            </Button>
          </div>

          {(methodCall.data || methodCall.error) && (
            <div className="mt-6">
              <ResultDisplay
                result={methodCall.data!}
                error={methodCall.error?.message}
                duration={methodCall.data?.duration}
                type={displayType}
                retTypes={idlFunc.retTypes}
                onTypeChange={setDisplayType}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function MethodList({ methods }: { methods: Array<[string, IDL.FuncClass]> }) {
  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Methods</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          {methods.map(([name]) => (
            <a href={`#${name}`}>
              <Button
                key={name}
                variant="ghost"
                className="justify-start font-mono text-sm"
              >
                {name}
              </Button>
            </a>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

interface CanisterUIProps {
  canisterId: Principal;
  canister: ActorSubclass;
}

export function CanisterUI({ canisterId, canister }: CanisterUIProps) {
  const [logs, setLogs] = useState<Array<{ timestamp: Date; content: string }>>(
    []
  );

  const appendLog = useCallback((timestamp: Date, content: string) => {
    setLogs((prev) => [...prev, { timestamp, content }]);
  }, []);

  const fetchCanisterLogs = useCallback(async () => {
    try {
      const actor = getManagementCanister({ agent: alwaysMainnetAgent });
      const result = await actor.fetch_canister_logs({
        canister_id: canisterId,
      });

      result.canister_log_records.forEach((record) => {
        const timestamp = new Date(Number(record.timestamp_nanos / 1000000n));
        const content = new TextDecoder().decode(
          new Uint8Array(record.content)
        );
        appendLog(timestamp, content);
      });
    } catch (err) {
      console.warn("Failed to fetch canister logs:", err);
    }
  }, [canisterId, appendLog]);

  useEffect(() => {
    fetchCanisterLogs();
  }, [fetchCanisterLogs]);

  const canisterInterface = (() => {
    try {
      return Actor.interfaceOf(canister);
    } catch (err) {
      return null;
    }
  })();

  if (!canisterInterface) {
    return (
      <div className="p-4">
        <Card>
          <CardHeader>
            <CardTitle>Error</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-destructive">
              Could not load canister interface
            </p>
          </CardContent>
        </Card>
      </div>
    );
  }

  const methods = canisterInterface._fields.sort(([a], [b]) =>
    a > b ? 1 : -1
  );

  return (
    <div className="p-4 max-w-[1200px] mx-auto">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Canister Interface</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="font-mono text-sm text-muted-foreground">
            {canisterId.toString()}
          </div>
        </CardContent>
      </Card>

      <div className="space-y-8">
        {/* <MethodList methods={methods} /> */}

        <div className="space-y-6">
          {methods.map(([name, func]) => (
            <MethodRenderer
              key={name}
              canister={canister}
              name={name}
              idlFunc={func}
              onCallComplete={fetchCanisterLogs}
            />
          ))}
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Canister Logs</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[200px] w-full rounded-md border">
              <div className="p-4">
                {logs.map((log, i) => (
                  <LogEntry
                    key={i}
                    timestamp={log.timestamp}
                    content={log.content}
                  />
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
