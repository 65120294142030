import { Principal } from "@dfinity/principal";
import { useParams } from "react-router-dom";

import { CanisterUI } from "@/components/candid";
import { useCanisterCandid } from "@/hooks/queries/candid";

export default function CanisterCandidInterface() {
  const { canisterId } = useParams();
  const candid = useCanisterCandid(canisterId!);
  const principal = Principal.fromText(canisterId!);

  if (candid.isLoading) {
    return <div>Loading candid interface...</div>;
  }

  if (candid.isError) {
    return <div>Error loading candid interface: {candid.error.message}</div>;
  }

  if (!candid.data) {
    return <div>No candid interface found</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Canister Candid Interface</h1>
      <CanisterUI canisterId={principal} canister={candid.data} />
    </div>
  );
}
