import { Principal } from "@dfinity/principal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  ConnectionTtlSettings,
  DEFAULT_CONNECTION_TTL,
  getConnectionTtlStorageKey,
  useIdp,
} from "@/state/stores/idp";

// Fetch

async function fetchConnectionTtlSettings(
  principal: Principal
): Promise<ConnectionTtlSettings> {
  const storageKey = getConnectionTtlStorageKey(principal);
  const stored = localStorage.getItem(storageKey);
  if (!stored) return DEFAULT_CONNECTION_TTL;
  try {
    return JSON.parse(stored) as ConnectionTtlSettings;
  } catch {
    return DEFAULT_CONNECTION_TTL;
  }
}

// Query

export function useConnectionTtl() {
  const { principal } = useIdp();
  return useQuery({
    queryKey: ["connectionTtl", principal.toText()],
    queryFn: () => fetchConnectionTtlSettings(principal),
    enabled: !principal.isAnonymous(),
  });
}

// Post

async function postConnectionTtlSettings(
  principal: Principal,
  settings: ConnectionTtlSettings
): Promise<void> {
  const storageKey = getConnectionTtlStorageKey(principal);
  localStorage.setItem(storageKey, JSON.stringify(settings));
}

// Mutate

export function useUpdateConnectionTtl() {
  const queryClient = useQueryClient();
  const { principal } = useIdp();

  return useMutation({
    mutationFn: (settings: ConnectionTtlSettings) => {
      return postConnectionTtlSettings(principal, settings);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["connectionTtl", principal.toText()],
      });
    },
  });
}
