import { Principal } from "@dfinity/principal";
import { useEffect, useRef, useState } from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useCanisterLogsQuery } from "@/hooks/queries/canister-management";

interface CanisterLogsProps {
  canisterIdString: string;
}

export default function CanisterLogs({ canisterIdString }: CanisterLogsProps) {
  const [tailing, setTailing] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const canisterId = Principal.fromText(canisterIdString);
  const { data: logs, isLoading } = useCanisterLogsQuery(canisterId, tailing);

  useEffect(() => {
    if (!tailing || !scrollRef.current) return;
    scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight });
  }, [logs, tailing]);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Logs</CardTitle>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="tailing"
            checked={tailing}
            onCheckedChange={(checked) => setTailing(checked as boolean)}
          />
          <label
            htmlFor="tailing"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Auto-refresh
          </label>
        </div>
      </CardHeader>
      <CardContent>
        <ScrollArea
          ref={scrollRef}
          className="h-[400px] w-full rounded-md border p-4 font-mono text-xs"
        >
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              Loading logs...
            </div>
          ) : logs?.canister_log_records.length === 0 ? (
            <div className="flex h-full items-center justify-center text-muted-foreground">
              No logs available
            </div>
          ) : (
            logs?.canister_log_records.map((record) => (
              <div key={record.idx.toString()} className="whitespace-pre-wrap">
                {new Date(
                  Number(record.timestamp_nanos) / 1_000_000
                ).toISOString()}{" "}
                {new TextDecoder().decode(new Uint8Array(record.content))}
              </div>
            ))
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
