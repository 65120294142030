import { Principal } from "@dfinity/principal";
import { CheckCircle2 } from "lucide-react";
import { useMemo, useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

import Code from "@/components/code";
import { Button } from "@/components/ui/button";
import {
  useCanisterTableQuery,
  useVerifyBlackholeBulkMutation,
} from "@/hooks/queries/canisters";
import { useBalanceChecker } from "@/hooks/queries/cycleops-service";
import { ic } from "@/lib/actors";

import {
  DFX_COMMAND_REST_INTERVAL_SECONDS,
  DFX_PARALLEL_COMMANDS,
} from "../filters/constants";
import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";
import {
  CanistersCommandDialog,
  CanistersCommandDialogFooter,
} from "./command-dialog";

interface VerifyCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
}

export const useVerifyCommand = create<VerifyCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  canisterId: undefined,
  setCanisterId: (canisterId) => set({ canisterId }),
}));

export function VerifyCommandDialog() {
  const { isOpen, close, canisterId, setCanisterId } = useVerifyCommand();
  const selectedCanisters = useSelectedCanisters();
  const mutation = useVerifyBlackholeBulkMutation();
  const canisterTable = useCanisterTableQuery();
  const statusChecker = useBalanceChecker();
  const [toastId, setToastId] = useState<string | undefined>(undefined);

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];

  const eligibleCanisters = useMemo(() => {
    return canisterTable.data?.filter((canister) => {
      if (!canisterIds.map((x) => x?.toText()).includes(canister.id.toText()))
        return false;
      if (canister.status !== "pending") return false;
      if (!("blackhole" in canister.monitoringMechanism)) return false;
      return true;
    });
  }, [canisterTable, canisterIds]);

  const canisterCount = eligibleCanisters?.length ?? 0;

  const plural = canisterCount > 1;

  const dfxCommand = useMemo(
    () =>
      `canisters=(${eligibleCanisters
        ?.map((x) => `"${x.id.toText()}"`)
        .join(" ")})
  printf "%s\\n" "\${canisters[@]}" | xargs -P${DFX_PARALLEL_COMMANDS} -I{} bash -c "
    dfx canister${
      ic.isLocal ? "" : " --ic"
    } update-settings {} --add-controller ${
        statusChecker.data || "BALANCE_CHECKER_CANISTER_ID"
      }
    sleep ${DFX_COMMAND_REST_INTERVAL_SECONDS}
  "`,
    [eligibleCanisters, statusChecker]
  );

  function handleVerify() {
    mutation.mutate(eligibleCanisters?.map((x) => x.id.toText()) ?? [], {
      onSettled: () => {
        toast.dismiss(toastId);
      },
      onSuccess: () => {
        setCanisterId(undefined);
      },
    });
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      const id = toast.loading(
        `Verifying ${canisterCount} canister${
          canisterCount !== 1 ? "s" : ""
        }...`
      );
      setToastId(id as string);
    }
    if (!open) {
      close();
    }
  }

  return (
    <CanistersCommandDialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CommandPillList canisterCount={canisterCount}>
        <CommandLabel>
          <CheckCircle2 className="w-4 h-4" /> Finish setup
        </CommandLabel>
      </CommandPillList>

      <div className="flex flex-col gap-4 p-4">
        <div className="text-sm">
          You have selected <strong>{canisterCount}</strong>{" "}
          {plural ? "canisters " : "canister "}
          that {plural ? "have " : "has "} not yet added the blackhole as a
          controller. Run this dfx command, then press "verify" to finish setup.
        </div>
        <Code
          copyable={dfxCommand}
          className="w-full max-w-[480px] max-h-[300px] overflow-y-auto whitespace-pre text-xs"
        >
          {dfxCommand}
        </Code>
      </div>
      <CanistersCommandDialogFooter>
        <Button
          type="submit"
          className="text-base"
          loading={mutation.isPending}
          onClick={handleVerify}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              handleVerify();
            }
          }}
        >
          Verify now
        </Button>
      </CanistersCommandDialogFooter>
    </CanistersCommandDialog>
  );
}
