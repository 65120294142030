import { Principal } from "@dfinity/principal";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronRight, Copy, CopyIcon, HelpCircle } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import {
  Link,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { profileFormSchema } from "@/hooks/forms/customer-metadata";
import {
  useAddCanisterBulkMutation,
  useAddCanisterMutation,
  useAddNNSMonitoredCanisterMutation,
  useVerifyBlackholeBulkMutation,
  useVerifyBlackholeMutation,
} from "@/hooks/queries/canisters";
import { useCheckCanisterInTeamsQuery } from "@/hooks/queries/check-canister-in-teams";
import {
  useCreateCustomerMutation,
  useCustomerEmailMutation,
  useCustomerIsEmailVerifiedQuery,
  useCustomerMetadataMutation,
  useCustomerMetadataQuery,
  useCustomerNotificationSettingsMutation,
} from "@/hooks/queries/customer";
import { useBalanceChecker } from "@/hooks/queries/cycleops-service";
import { useCycleOpsAccountTextQuery } from "@/hooks/queries/ledger-icp-legacy";
import {
  useAddSNSCanistersMutation,
  useAllSNSsQuery,
} from "@/hooks/queries/sns";
import {
  route,
  useCallerTeamsQuery,
  useRoute as useTeamsRoute,
} from "@/hooks/queries/team";
import { ic } from "@/lib/actors";
import { copy } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import { App, AppContents, AppFooter } from "./app";
import {
  DFX_COMMAND_REST_INTERVAL_SECONDS,
  DFX_PARALLEL_COMMANDS,
} from "./canister-list/filters/constants";
import Code from "./code";
import CustomerDropdown from "./customer-dropdown";
import CycleOpsMarketRate from "./cycleops-market-rate";
import Logo from "./logo";
import PrincipalAbbr from "./principal-abbr";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Badge } from "./ui/badge";
import { Checkbox } from "./ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import Hashatar from "./ui/hashatar";
import { Input } from "./ui/input";
import { Separator } from "./ui/separator";
import { Skeleton } from "./ui/skeleton";
import { Switch } from "./ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Textarea } from "./ui/textarea";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export function RouteContainer() {
  const location = useLocation();
  return (
    <App>
      <SimpleNavbar />
      <AppContents>
        <TransitionGroup className="h-full relative">
          <style>
            {`
            .fade-enter {
              opacity: 0;
              transform: scale(0.9) translateX(240px);
            }
            .fade-enter-active {
              opacity: 1;
              transform: scale(1) translateX(0);
              transition: opacity 500ms, transform 500ms;
            }
            .fade-exit {
              opacity: 1;
              transform: scale(1) translateX(0);
            }
            .fade-exit-active {
              opacity: 0;
              transform: scale(0.9) translateX(-240px);
              transition: opacity 0ms, transform 0ms;
            }`}
          </style>
          <CSSTransition key={location.key} classNames="fade" timeout={500}>
            <main className="container flex flex-col gap-4 mt-4 min-h-full inset-0 items-center justify-center absolute">
              <Outlet />
            </main>
          </CSSTransition>
        </TransitionGroup>
      </AppContents>
      <AppFooter />
    </App>
  );
}

function SimpleNavbar() {
  return (
    <header>
      <div className="container my-2">
        <div className="mb-2 grid gap-2 justify-between grid-cols-[auto_1fr_auto]">
          <Logo />

          <aside className="order-3 md:order-3 flex items-center gap-2 justify-end">
            <CycleOpsMarketRate />
            <CustomerDropdown />
          </aside>
        </div>
      </div>
      <Separator />
    </header>
  );
}

export function Start() {
  const { connected, connect } = useIdp();
  const navigate = useNavigate();
  const [connecting, setConnecting] = useState(false);

  const handleConnection = () => {
    setConnecting(true);
    connect()
      .then(() => {
        navigate("/onboarding/metadata");
      })
      .catch((e) => {
        toast.error("Failed to connect to the Internet Identity service");
      })
      .finally(() => setConnecting(false));
  };

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">Account Setup</CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <div className="text-8xl">🧘</div>
        <div>You are five minutes from cycles nirvana</div>
        {connected ? (
          <Link to="/onboarding/metadata" className="plain">
            <Button size="lg" className="text-lg h-[62px] w-[162px]">
              Begin
            </Button>
          </Link>
        ) : (
          <Button
            size="lg"
            className="text-lg h-[62px] w-[162px]"
            onClick={handleConnection}
            disabled={connecting}
            loading={connecting}
          >
            Begin
          </Button>
        )}
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function AccountMetadata() {
  const customer = useCustomerMetadataQuery();
  const {
    isPending: isPendingUpdate,
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
  } = useCustomerMetadataMutation();
  const {
    isPending: isPendingCreate,
    mutate: mutateCreate,
    isSuccess: isSuccessCreate,
    data,
  } = useCreateCustomerMutation();

  const mutate = customer?.data ? mutateUpdate : mutateCreate;
  const isPending = customer?.data ? isPendingUpdate : isPendingCreate;
  const isSuccess = customer?.data ? isSuccessUpdate : isSuccessCreate;

  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: "",
      displayName: "",
      logoUrl: "",
    },
  });

  const previewDisplayName = form.watch("displayName");
  const previewUsername = form.watch("username");
  const previewLogoUrl = form.watch("logoUrl");

  const displayName = useWatch({
    control: form.control,
    name: "displayName",
  });

  useEffect(() => {
    if (customer?.data) return;
    form.setValue(
      "username",
      displayName
        ?.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .slice(0, 62) ?? ""
    );
  }, [displayName, form, customer.data]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!customer?.isSuccess) return;
    form.reset({
      username: customer.data.username || "",
      displayName: customer.data.displayName || "",
      logoUrl: customer.data.logoUrl || "",
    });
  }, [customer.data?.principal]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/onboarding/bifurcate");
    }
  }, [isSuccess]);

  return (
    <>
      <Card className="">
        <CardHeader className="text-lg border-b pb-5">
          What should we call you?
        </CardHeader>
        <CardContent className="min-h-[240px] flex items-center gap-8 pt-8">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit((d) => mutate(d))}
              className="space-y-8"
            >
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Display Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Cycles Lord" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input placeholder="cycles-lord" {...field} />
                    </FormControl>
                    <FormDescription>
                      Used as a unique identifier and URL namespace.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="logoUrl"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Avatar URL</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="https://example.com/avatar.png"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Please provide the URL of an image file.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-4">
                <Button
                  size="lg"
                  className="text-lg h-[62px] w-[162px]"
                  loading={isPending}
                  disabled={isPending}
                  type="submit"
                >
                  Submit
                </Button>
                {customer?.data && (
                  <Link className="plain" to="/onboarding/bifurcate">
                    <Button
                      size="lg"
                      className="text-lg h-[62px] w-[100px] text-muted-foreground"
                      variant="ghost"
                    >
                      Skip
                    </Button>
                  </Link>
                )}
              </div>
            </form>
          </Form>
          <div className="min-w-[240px] flex flex-col items-center gap-4">
            <Avatar className="h-24 w-24" radius="rounded-full">
              <AvatarImage src={previewLogoUrl} />
              <AvatarFallback>
                <Hashatar
                  name={previewUsername || "undefined"}
                  radius="rounded-full"
                />
              </AvatarFallback>
            </Avatar>

            <>
              <div className="text-xl">{previewDisplayName}</div>
              <div className="text-sm text-muted-foreground">
                {previewUsername}
              </div>
            </>
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </>
  );
}

export function Bifurcate() {
  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        How would you like to start using CycleOps?
      </CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <Link
          className="plain w-full rounded-sm ring-1 ring-muted hover:bg-muted flex gap-4 items-center p-4 group hover:ring-muted hover:bg-muted hover:ring"
          to="/onboarding/individual"
        >
          <div className="text-8xl group-hover:scale-150 group-hover:-rotate-6 transition-all duration-300 ease-in-out">
            🧑
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold">Start from scratch</div>
            <div className="">
              I'd like to start monitoring some canisters today.
            </div>
          </div>
        </Link>
        <Link
          className="plain w-full rounded-sm ring-1 ring-muted hover:bg-muted flex gap-4 items-center p-4 group hover:ring-muted hover:bg-muted hover:ring"
          to="/onboarding/join-a-team"
        >
          <div className="text-8xl group-hover:scale-150 group-hover:-rotate-6 transition-all duration-300 ease-in-out">
            👨‍👩‍👧‍👦
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold">Join an existing team</div>
            <div className="">There's a team on CycleOps waiting for me!</div>
          </div>
        </Link>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function Team() {
  const { principal } = useIdp();
  const teams = useCallerTeamsQuery({ staleTime: 5000, refetchInterval: 5000 });
  const navigate = useNavigate();
  useEffect(() => {
    if ((teams.data?.length ?? 0) > 0) {
      navigate(route("/app", teams.data?.[0]!.username));
    }
  }, [teams.data]);
  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Join an existing team
      </CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <div>
          To join a team, copy your principal below and send it to the owner of
          the team.
        </div>
        <div
          className="relative cursor-pointer  w-full"
          onClick={() => copy(principal.toText())}
        >
          <Input
            className="pointer-events-none"
            value={principal?.toText() ?? "Loading..."}
            disabled
          />
          <div className="h-full aspect-square absolute right-0 top-0 bg-muted text-foreground rounded flex items-center justify-center">
            <Copy size="16px" />
          </div>
        </div>
        <div>
          Once the owner has added you to the team, you'll be redirected to the
          team's dashboard.
        </div>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function AddCanister({ root = "/onboarding/" }) {
  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">Add a canister</CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <div>Select a monitoring mechanism that best suits your canister.</div>
        <Link
          className="plain w-full rounded-sm ring-2 ring-muted flex gap-4 items-center p-4 group hover:ring-muted hover:bg-muted hover:ring"
          to={`${root}blackhole`}
        >
          <div className="text-8xl group-hover:scale-150 group-hover:-rotate-6 transition-all duration-300 ease-in-out">
            🌌
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold flex gap-4 items-baseline">
              Blackhole Monitoring
              <Badge>Most Popular</Badge>
            </div>
            <div className="">
              Our secure blackhole keeps your data private and is best for most
              canisters.
            </div>
          </div>
        </Link>
        <Link
          className="plain w-full rounded-sm ring-1 ring-muted hover:bg-muted flex gap-4 items-center p-4 group hover:ring-muted hover:ring"
          to={`${root}nns`}
        >
          <div className="text-8xl group-hover:scale-150 group-hover:-rotate-6 transition-all duration-300 ease-in-out">
            🧠
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold flex gap-4 items-baseline">
              NNS Monitoring
            </div>
            <div className="">
              Canisters controlled by the NNS can be monitored without adding an
              additional controller.
            </div>
            <div className="text-xs text-muted-foreground">
              Note: this method relies on public data and as such canisters
              using it will be displayed in CycleOps' public canister
              directories.
            </div>
          </div>
        </Link>
        <Link
          className="plain w-full rounded-sm ring-1 ring-muted hover:bg-muted flex gap-4 items-center p-4 group hover:ring-muted hover:ring"
          to={`${root}sns`}
        >
          <div className="text-8xl group-hover:scale-150 group-hover:-rotate-6 transition-all duration-300 ease-in-out">
            🌍
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold flex gap-4 items-baseline">
              SNS Monitoring
            </div>
            <div className="">
              Easily select any canisters controlled by an SNS for monitoring.
            </div>
            <div className="text-xs text-muted-foreground">
              Note: sns canister data is public and will be displayed in
              CycleOps' public directories.
            </div>
          </div>
        </Link>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

const addCanisterSchema = z.object({
  canisterId: z
    .string()
    .min(1, "Canister ID is required")
    .refine((v) => {
      try {
        Principal.fromText(v);
        return true;
      } catch {
        return false;
      }
    }, "Canister ID is not a valid principal"),
  name: z.string().optional(),
  threshold: z.coerce
    .number()
    .positive("Must be positive")
    .min(0.1, "0.1 minimum"),
  amount: z.coerce
    .number()
    .positive("Must be positive")
    .min(0.1, "0.1 minimum"),
  method: z.enum(["by_amount", "to_balance"]),
});

// zod schema to apply transformations to form data before mutation
const addCanisterMutationSchema = z.object({
  canisterId: z.string().transform((v) => Principal.fromText(v)),
  name: z.string().optional(),
  threshold: z.number().transform((v) => {
    const floatV = typeof v === "string" ? parseFloat(v) : v;
    return floatV * 1e12;
  }),
  amount: z.number().transform((v) => {
    const floatV = typeof v === "string" ? parseFloat(v) : v;
    return floatV * 1e12;
  }),
  method: z.enum(["by_amount", "to_balance"]),
});

function CanisterTransferDialog({
  open,
  onOpenChange,
  teamName,
  teamUsername,
  canisterId,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  teamName: string;
  teamUsername: string;
  canisterId?: string;
}) {
  const navigate = useNavigate();

  const handleViewCanister = () => {
    onOpenChange(false);

    if (teamUsername && canisterId) {
      navigate(route(`/canisters/${canisterId}`, teamUsername));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[480px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <div className="text-2xl">🔄</div>
            <span>Canister Already Monitored</span>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4 py-4">
          <p>
            This canister is already added to your <strong>{teamName}</strong>{" "}
            team!
          </p>
          <p>
            Instead of adding this canister twice, you can transfer the canister
            between teams.
          </p>
          <div className="bg-muted p-4 rounded-md border">
            <a
              href="https://docs.cycleops.dev/docs/transfer-canisters"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-primary hover:underline"
            >
              <span>How to transfer canisters between teams</span>
              <ChevronRight size={16} />
            </a>
          </div>
        </div>
        <DialogFooter className="flex justify-between sm:justify-between gap-2">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Close
          </Button>
          <Button
            variant="default"
            onClick={handleViewCanister}
            disabled={!teamUsername || !canisterId}
          >
            View canister in {teamName} team
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function Blackhole({
  handlers,
  includeBulk = false,
}: {
  includeBulk?: boolean;
  handlers?: {
    success: (id: string) => void;
  };
}) {
  const { mutate, isSuccess, isPending } = useAddCanisterMutation();
  const teams = useCallerTeamsQuery({ staleTime: 5000 });
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [transferTeamName, setTransferTeamName] = useState("");
  const [transferTeamUsername, setTransferTeamUsername] = useState("");
  const [transferCanisterId, setTransferCanisterId] = useState<string>("");

  const form = useForm<z.infer<typeof addCanisterSchema>>({
    resolver: zodResolver(addCanisterSchema),
    defaultValues: {
      canisterId: "",
      name: "",
      threshold: 1,
      amount: 3,
      method: "by_amount",
    },
  });

  const canisterId = form.watch("canisterId");

  const getPrincipalFromCanisterId = (id: string) => {
    try {
      return Principal.fromText(id);
    } catch (error) {
      return undefined;
    }
  };

  const currentCanisterPrincipal = getPrincipalFromCanisterId(canisterId);
  const canisterInTeams = useCheckCanisterInTeamsQuery(
    currentCanisterPrincipal
  );

  const navigate = useNavigate();

  const r = useTeamsRoute();

  const handleSuccess =
    handlers?.success ??
    ((id) => navigate(`/onboarding/blackhole-verification/${id}`));

  const onSubmit = (d: z.infer<typeof addCanisterSchema>) => {
    const isUserInTeams = (teams.data?.length ?? 0) > 0;
    if (isUserInTeams) {
      const isCanisterInTeams = !!canisterInTeams.data?.isInTeam;
      const teamWithCanister = canisterInTeams.data?.teamName;
      const teamPrincipal = canisterInTeams.data?.teamPrincipal;

      let teamUsername = "";
      if (teamPrincipal && teams.data) {
        const team = teams.data.find(
          (t) => t.principal.toString() === teamPrincipal.toString()
        );
        teamUsername = team?.username || "";
      }

      if (isCanisterInTeams && teamWithCanister) {
        setTransferTeamName(teamWithCanister);
        setTransferTeamUsername(teamUsername);
        setTransferCanisterId(d.canisterId);
        setTransferDialogOpen(true);
        return; // Don't proceed with the mutation
      }
    }

    mutate(addCanisterMutationSchema.parse(d));
  };

  useEffect(() => {
    if (isSuccess) handleSuccess(canisterId);
  }, [isSuccess, canisterId]);

  return (
    <>
      <Card className="w-full max-w-[640px]">
        <CardHeader className="text-lg border-b pb-5">
          Add a canister
        </CardHeader>
        <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
          <div className="text-sm">
            To begin, let's start monitoring just one of your existing
            canisters. You can add more in just a minute.
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4 w-full"
            >
              <FormField
                control={form.control}
                name="canisterId"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-0 space-y-1">
                    <div className="flex items-center gap-2 w-full">
                      <FormLabel className="w-24 flex-shrink-0">
                        Canister ID
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Example: nges7-xyaaa-aaaaa-aaaaa-cai"
                          {...field}
                        />
                      </FormControl>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle size="16px" />
                        </TooltipTrigger>
                        <TooltipContent align="end" side="bottom">
                          The ID of the canister you want to monitor.
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <FormMessage className="pl-28 text-xs" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-0 space-y-1">
                    <div className="flex items-center gap-2 w-full">
                      <FormLabel className="w-24 flex-shrink-0">
                        Canister Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Defaults to canister ID"
                          {...field}
                        />
                      </FormControl>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle size="16px" />
                        </TooltipTrigger>
                        <TooltipContent align="end" side="bottom">
                          Give your canister a nice name!
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <FormMessage className="pl-28 text-xs" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="threshold"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-0 space-y-1">
                    <div className="flex items-center gap-2 w-full">
                      <FormLabel className="w-24 flex-shrink-0">
                        Threshold
                      </FormLabel>
                      <FormControl>
                        <div className="flex w-full">
                          <Input {...field} className="w-full rounded-r-none" />
                          <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                            Trillion Cycles
                          </div>
                        </div>
                      </FormControl>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle size="16px" />
                        </TooltipTrigger>
                        <TooltipContent align="end" side="bottom">
                          The cycles balance at which an automated top-up will
                          be triggered.
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <FormMessage className="pl-28 text-xs" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-0 space-y-1">
                    <div className="flex items-center gap-2 w-full">
                      <FormLabel className="w-24 flex-shrink-0">
                        Amount
                      </FormLabel>
                      <FormControl>
                        <div className="flex w-full">
                          <Input {...field} className="w-full rounded-r-none" />
                          <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                            Trillion Cycles
                          </div>
                        </div>
                      </FormControl>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle size="16px" />
                        </TooltipTrigger>
                        <TooltipContent align="end" side="bottom">
                          The amount of cycles to top up with when the threshold
                          is reached.
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <FormMessage className="pl-28 text-xs" />
                  </FormItem>
                )}
              />
              <Button
                size="lg"
                className="text-lg h-[62px] w-[162px] mt-6"
                loading={isPending}
                disabled={isPending}
                type="submit"
              >
                Submit
              </Button>
            </form>
          </Form>
        </CardContent>
        <CardFooter>
          {includeBulk && (
            <div className="text-sm text-muted-foreground">
              <Link
                to={r("/canisters/new/blackhole/bulk")}
                className="text-primary"
              >
                Add in bulk
              </Link>
            </div>
          )}
        </CardFooter>
      </Card>

      <CanisterTransferDialog
        open={transferDialogOpen}
        onOpenChange={setTransferDialogOpen}
        teamName={transferTeamName}
        teamUsername={transferTeamUsername}
        canisterId={transferCanisterId}
      />
    </>
  );
}

const addCanisterSchemaBulk = z.object({
  canisterId: z
    .string()
    .min(1, "Canister ID is required")
    .refine((v) => {
      return v
        .split("\n")
        .map((line) => {
          let pass = true;
          try {
            const principal = Principal.fromText(line);
            pass = principal._isPrincipal;
          } catch {
            toast.error(`Invalid principal "${line}"`);
            pass = false;
          }
          return pass;
        })
        .every((x) => !!x);
    }, "Canister ID is not a valid principal"),
  threshold: z.coerce
    .number()
    .positive("Must be positive")
    .min(0.1, "0.1 minimum"),
  amount: z.coerce
    .number()
    .positive("Must be positive")
    .min(0.1, "0.1 minimum"),
  method: z.enum(["by_amount", "to_balance"]),
});

// zod schema to apply transformations to form data before mutation
const addCanisterMutationSchemaBulk = z
  .object({
    canisterId: z.string().transform((v) =>
      v.split("\n").map((x) => {
        try {
          return Principal.fromText(x);
        } catch {
          toast.error(`Invalid principal "${x}"`);
          throw new Error(`Invalid principal "${x}"`);
        }
      })
    ),
    threshold: z.coerce.number().transform((v) => {
      const floatV = typeof v === "string" ? parseFloat(v) : v;
      return floatV * 1e12;
    }),
    amount: z.coerce.number().transform((v) => {
      const floatV = typeof v === "string" ? parseFloat(v) : v;
      return floatV * 1e12;
    }),
    method: z.enum(["by_amount", "to_balance"]),
  })
  .transform((v) => {
    return v.canisterId.map((canisterId) => ({
      ...v,
      canisterId,
    }));
  });

export function BlackholeBulk({
  handlers,
}: {
  handlers?: { success: (ids: Principal[]) => void };
}) {
  const { mutate, isPending, isSuccess } = useAddCanisterBulkMutation();

  const form = useForm<z.infer<typeof addCanisterSchemaBulk>>({
    resolver: zodResolver(addCanisterSchemaBulk),
    defaultValues: {
      canisterId: "",
      threshold: 1,
      amount: 3,
      method: "by_amount",
    },
  });

  const canisterIds = form
    .watch("canisterId")
    .split("\n")
    .map((x) => {
      try {
        return Principal.fromText(x);
      } catch {
        return null;
      }
    })
    .filter((x) => !!x);

  const navigate = useNavigate();

  const handleSuccess =
    handlers?.success ??
    ((ids) =>
      navigate(
        `/onboarding/blackhole-verification/bulk/${ids
          .map((x) => x.toText())
          .join("\n")}`
      ));

  useEffect(() => {
    if (isSuccess) handleSuccess(canisterIds);
  }, [isSuccess, canisterIds]);

  function handle(d: z.infer<typeof addCanisterSchemaBulk>) {
    const transformed = addCanisterMutationSchemaBulk.parse(form.getValues());
    mutate(transformed);
  }

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Add canisters in bulk
      </CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <div className="text-sm">
          Add as many canisters as you like, separated by newlines. Each
          canister will share the topup threshold and amount you specify.
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handle)}
            className="flex flex-col gap-4 w-full"
          >
            <FormField
              control={form.control}
              name="canisterId"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">
                      Canister IDs
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder={`ctiya-peaaa-aaaaa-qaaja-cai\ncuj6u-c4aaa-aaaaa-qaajq-cai\ncbopz-duaaa-aaaaa-qaaka-cai\ncgpjn-omaaa-aaaaa-qaakq-cai`}
                        className="min-h-[120px]"
                        {...field}
                      />
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The ID of the canisters you want to monitor.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="threshold"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">
                      Threshold
                    </FormLabel>
                    <FormControl>
                      <div className="flex w-full">
                        <Input {...field} className="w-full rounded-r-none" />
                        <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                          Trillion Cycles
                        </div>
                      </div>
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The cycles balance at which an automated top-up will be
                        triggered.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">Amount</FormLabel>
                    <FormControl>
                      <div className="flex w-full">
                        <Input {...field} className="w-full rounded-r-none" />
                        <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                          Trillion Cycles
                        </div>
                      </div>
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The amount of cycles to top up with when the threshold
                        is reached.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <Button
              size="lg"
              className="text-lg h-[62px] w-[162px] mt-6"
              loading={isPending}
              disabled={isPending}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function NNS({
  handlers,
}: {
  handlers?: {
    success: () => void;
  };
}) {
  const { mutate, isSuccess, isPending } = useAddNNSMonitoredCanisterMutation();

  const form = useForm<z.infer<typeof addCanisterSchema>>({
    resolver: zodResolver(addCanisterSchema),
    defaultValues: {
      canisterId: "",
      name: "",
      threshold: 1,
      amount: 3,
      method: "by_amount",
    },
  });

  const navigate = useNavigate();

  const handleSuccess =
    handlers?.success ?? (() => navigate(`/onboarding/notifications`));

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [isSuccess]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Add an NNS controlled canister
      </CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((d) =>
              mutate(addCanisterMutationSchema.parse(d))
            )}
            className="flex flex-col gap-4 w-full"
          >
            <FormField
              control={form.control}
              name="canisterId"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">
                      Canister ID
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Example: nges7-xyaaa-aaaaa-aaaaa-cai"
                        {...field}
                      />
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The ID of the canister you want to monitor.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">
                      Canister Name
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Defaults to canister ID" {...field} />
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        Give your canister a nice name!
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="threshold"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">
                      Threshold
                    </FormLabel>
                    <FormControl>
                      <div className="flex w-full">
                        <Input {...field} className="w-full rounded-r-none" />
                        <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                          Trillion Cycles
                        </div>
                      </div>
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The cycles balance at which an automated top-up will be
                        triggered.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <FormLabel className="w-24 flex-shrink-0">Amount</FormLabel>
                    <FormControl>
                      <div className="flex w-full">
                        <Input {...field} className="w-full rounded-r-none" />
                        <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
                          Trillion Cycles
                        </div>
                      </div>
                    </FormControl>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle size="16px" />
                      </TooltipTrigger>
                      <TooltipContent align="end" side="bottom">
                        The amount of cycles to top up with when the threshold
                        is reached.
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <FormMessage className="pl-28 text-xs" />
                </FormItem>
              )}
            />
            <Button
              size="lg"
              className="text-lg h-[62px] w-[162px] mt-6"
              loading={isPending}
              disabled={isPending}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function BlackholeVerificationCard({
  canisterId,
  handlers,
}: {
  canisterId: string;
  handlers: {
    success: () => void;
  };
}) {
  const statusChecker = useBalanceChecker();
  const { mutate, isPending, isSuccess, data } = useVerifyBlackholeMutation();
  const dfxCommand = useMemo(
    () =>
      `dfx canister${
        ic.isLocal ? "" : " --network ic"
      } update-settings ${canisterId} \\\n--add-controller ${
        statusChecker.data || "BALANCE_CHECKER_CANISTER_ID"
      }`,
    [canisterId, statusChecker]
  );
  const nnsCommand = useMemo(
    () => statusChecker.data || "BALANCE_CHECKER_CANISTER_ID",
    [canisterId, statusChecker]
  );

  useEffect(() => {
    if (data) {
      if (
        "verified" in data ||
        ("err" in data && "already_added_and_verified" in data.err)
      ) {
        handlers.success();
      } else {
        toast.error("Verification failed. Please try again.");
      }
    }
  }, [isSuccess]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Grant control to the blackhole
      </CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <div className="text-sm">
          The CycleOps blackhole is ready to start monitoring your canister!
          Just add it as a controller of your canister.
        </div>
        <div className="text-xs text-muted-foreground">
          Our blackhole is verifiably secure.{" "}
          <a
            href="https://github.com/CycleOperators/BalanceCheckerVerification"
            target="_blank"
          >
            Learn more
          </a>
        </div>
        <Tabs defaultValue="dfx" className="space-y-6">
          <TabsList>
            <TabsTrigger value="dfx">Using DFX</TabsTrigger>
            <TabsTrigger value="nns">Using NNS Dapp</TabsTrigger>
          </TabsList>
          <TabsContent value="dfx" className="space-y-4">
            <div className="text-sm">
              Run this from the project directory in your terminal, then press
              verify.
            </div>
            <Code copyable={dfxCommand} children={dfxCommand} />
          </TabsContent>
          <TabsContent value="nns" className="space-y-4">
            <ol className="list-decimal pl-6">
              <li>
                Navigate to the <em>"My Canisters"</em> tab in
                <a target="_blank" href="https://nns.ic0.app/">
                  {" "}
                  NNS
                </a>
              </li>
              <li>
                Select your canister: <em>({canisterId})</em>
              </li>
              <li>
                Select <em>"Add Controller"</em>
              </li>
              <li>Add balance checker principal and select verify:</li>
            </ol>
            <Code copyable={nnsCommand} children={nnsCommand} />
          </TabsContent>
        </Tabs>
        <Button
          loading={isPending}
          disabled={isPending}
          size="lg"
          className="text-lg h-[62px] w-[162px]"
          onClick={() => mutate(canisterId!)}
        >
          Verify
        </Button>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function BlackholeVerification({
  handlers,
}: {
  handlers?: {
    success: () => void;
  };
}) {
  const { canisterId } = useParams();

  const navigate = useNavigate();

  const handleSuccess =
    handlers?.success ?? (() => navigate(`/onboarding/notifications`));

  return (
    <BlackholeVerificationCard
      canisterId={canisterId!}
      handlers={{ success: handleSuccess }}
    />
  );
}

export function BlackholeVerificationBulk({
  handlers,
}: {
  handlers: { success: () => void };
}) {
  const { canisterIds } = useParams();
  const principals = canisterIds?.split(",").map((x) => Principal.fromText(x));
  const statusChecker = useBalanceChecker();
  const { mutate, isPending, isSuccess } = useVerifyBlackholeBulkMutation();
  const dfxCommand = useMemo(
    () =>
      `canisters=(${principals?.map((x) => `"${x.toText()}"`).join(" ")})
printf "%s\\n" "\${canisters[@]}" | xargs -P${DFX_PARALLEL_COMMANDS} -I{} bash -c "
  dfx canister${
    ic.isLocal ? "" : " --ic"
  } update-settings {} --add-controller ${
        statusChecker.data || "BLACKHOLE_CANISTER_ID"
      }
  sleep ${DFX_COMMAND_REST_INTERVAL_SECONDS}
" 

echo "New blackhole added to \${#canisters[@]} canisters"`,
    [canisterIds, statusChecker]
  );

  useEffect(() => {
    if (isSuccess) {
      handlers.success();
    }
  }, [isSuccess]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Grant control to the blackhole
      </CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <div className="text-sm">
          The CycleOps blackhole is ready to start monitoring your canister!
          Just add it as a controller of your canister.
        </div>
        <div className="text-xs text-muted-foreground">
          Our blackhole is verifiably secure.{" "}
          <a
            href="https://github.com/CycleOperators/BalanceCheckerVerification"
            target="_blank"
          >
            Learn more
          </a>
        </div>
        <div className="text-sm">
          Run this from the project directory in your terminal, then press
          verify.
        </div>
        <Code
          copyable={dfxCommand}
          children={dfxCommand}
          className="whitespace-pre overflow-x-auto text-xs"
        />
        <Button
          loading={isPending}
          disabled={isPending}
          size="lg"
          className="text-lg h-[62px] w-[162px]"
          onClick={() => mutate(principals!.map((x) => x.toText()))}
        >
          Verify
        </Button>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function SNS({ root = "/onboarding/" }) {
  const { data, isLoading } = useAllSNSsQuery();
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = useMemo(() => {
    if (!data || !searchQuery) return data;
    const query = searchQuery.toLowerCase();
    return data.filter(
      (sns) =>
        sns.metadata.name?.toLowerCase().includes(query) ||
        sns.root.toText().toLowerCase().includes(query)
    );
  }, [data, searchQuery]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        SNS canister monitoring
      </CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <div className="">Select the SNS that you'd like to monitor.</div>
        <div className="w-full">
          <Input
            placeholder="Search SNS by name or ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mb-4"
          />
          <div className="w-full max-h-[640px] border rounded-md overflow-auto">
            <div className="px-2 flex flex-col text-sm">
              {isLoading &&
                Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <Skeleton key={i} className="w-full h-[52px]" />
                  ))}
              {filteredData?.map((sns) => {
                const name = sns.metadata?.name ?? sns.root.toText();
                return (
                  <React.Fragment key={name}>
                    <Link
                      className="px-4 py-4 hover:bg-muted/60 plain"
                      to={`${root}sns/${sns.root.toText()}`}
                    >
                      <div className="flex items-center gap-4 w-full">
                        <div className="flex gap-2 items-center w-full">
                          <img
                            src={sns.metadata?.icon}
                            className="h-5 w-5"
                            alt=""
                          />
                          {name}
                        </div>
                        <ChevronRight size="16" />
                      </div>
                    </Link>
                    <Separator key={`sep-${name}`} />
                  </React.Fragment>
                );
              })}
              {filteredData?.length === 0 && !isLoading && (
                <div className="px-4 py-8 text-center text-muted-foreground">
                  No SNS found matching your search
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function SNSCanisters({
  handlers,
}: {
  handlers?: {
    success: () => void;
  };
}) {
  const { snsId } = useParams();
  const { data, isFetched, isLoading } = useAllSNSsQuery();

  const sns = data?.find((x) => x.root.toText() === snsId);

  const canisters = (() => {
    if (!sns) return [];
    const c = Object.entries(sns?.canisters).map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((principal: Principal | undefined) => ({
          type: key,
          principal,
        }));
      }
      return {
        type: key,
        principal: value,
      };
    });
    return c.flat();
  })();

  const [selected, setSelected] = useState<boolean[]>([]);

  useEffect(() => {
    if (isFetched) {
      setSelected(Array(canisters.length).fill(true));
    }
  }, [isFetched]);

  const handleCheckboxChange = (index: number) => {
    setSelected((prev) => {
      const next = [...prev];
      next[index] = !prev[index];
      return next;
    });
  };

  const toggleAll = () => {
    const areAllSelected = selected.every(Boolean);
    setSelected(Array(canisters.length).fill(!areAllSelected));
  };

  const selectedCount = selected.filter(Boolean).length;
  const totalCount = canisters.length;

  const { mutate, isPending, isSuccess } = useAddSNSCanistersMutation();
  const navigate = useNavigate();

  const handleSuccess =
    handlers?.success ??
    (() => {
      navigate(`/onboarding/notifications`);
    });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    if (!snsId) return;
    const root = Principal.fromText(snsId);
    if (!root) return;
    const snsCanistersToMonitor = canisters
      .filter((x, i) => selected[i] && !!x.principal)
      .map((x) => x.principal!);
    mutate({
      snsRootCanisterId: root,
      snsCanistersToMonitor,
    });
  };

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5 flex flex-row items-center gap-4">
        <img src={sns?.metadata.icon} className="h-8 w-8" alt="" />
        {sns?.metadata.name} canister monitoring
      </CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8">
        <div className="">Select the canisters that you'd like to monitor.</div>
        <div className="w-full max-h-[640px] border rounded-md overflow-auto">
          <div className="px-0 flex flex-col text-sm">
            {isLoading &&
              Array(10)
                .fill(0)
                .map((_, i) => (
                  <Skeleton key={i} className="w-full h-[52px]" />
                ))}
            <div className="sticky top-0 bg-background border-b px-4 py-3 flex items-center gap-2">
              <Checkbox
                id="select-all"
                onCheckedChange={toggleAll}
                checked={
                  selected.some(Boolean) && !selected.every(Boolean)
                    ? "indeterminate"
                    : selected.every(Boolean)
                }
                className="data-[state=indeterminate]:bg-primary"
              />
              <label
                htmlFor="select-all"
                className="text-sm cursor-pointer select-none"
              >
                {selectedCount === 0
                  ? "Select all"
                  : selectedCount === totalCount
                  ? "Deselect all"
                  : `Selected ${selectedCount} of ${totalCount}`}
              </label>
            </div>
            <div className="px-2 py-2">
              {canisters.map((x, i) => (
                <>
                  {i !== 0 && (
                    <Separator key={`sep-${x.principal?.toText()}`} />
                  )}
                  <label
                    htmlFor={`checkbox-${i}`}
                    key={x.principal?.toText()}
                    className="flex items-center gap-4 w-full px-4 py-2 hover:bg-muted/50 cursor-pointer"
                  >
                    <Checkbox
                      id={`checkbox-${i}`}
                      onCheckedChange={() => handleCheckboxChange(i)}
                      checked={selected[i]}
                    />
                    <div className="flex gap-2 items-center justify-between w-full">
                      <PrincipalAbbr copyable={false}>
                        {x.principal?.toText()}
                      </PrincipalAbbr>
                      <div className="text-muted-foreground text-xs">
                        {x.type}
                      </div>
                    </div>
                  </label>
                </>
              ))}
            </div>
          </div>
        </div>
        <Button
          size="lg"
          className="text-lg h-[62px] w-[162px] mt-6"
          onClick={handleSubmit}
          disabled={isPending || selectedCount === 0}
        >
          {selectedCount === 0 ? "Select canisters" : "Add Canisters"}
        </Button>
        {isPending && (
          <div className="text-sm text-muted-foreground text-center">
            Hang with us for ~20 seconds while we set up SNS canister
            monitoring.
          </div>
        )}
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

const notificationsFormSchema = z.object({
  email: z.string().email(),
  enableFailureNotifications: z.boolean(),
  enableSuccessNotifications: z.boolean(),
});

export function Notifications() {
  const form = useForm({
    resolver: zodResolver(notificationsFormSchema),
    defaultValues: {
      email: "",
      enableFailureNotifications: true,
      enableSuccessNotifications: false,
    },
  });

  const email = useWatch({
    control: form.control,
    name: "email",
  });

  const {
    mutate: mutateEmail,
    isPending: isPendingEmail,
    isSuccess: isSuccessEmail,
  } = useCustomerEmailMutation();
  const {
    mutate: mutateNotifications,
    isPending: isPendingNotifications,
    isSuccess: isSuccessNotifications,
  } = useCustomerNotificationSettingsMutation();

  const handleSubmit = form.handleSubmit(async (d) => {
    // Update both the customer email and the notification settings in parallel
    await Promise.all([
      mutateEmail({ email: d.email }),
      mutateNotifications({
        notifyOnMemoryThresholdReached: false,
        // by default, keep these enabled as a security measure
        notifyOnReservedCyclesThresholdReached: true,
        notifyOnTopupSuccess: d.enableSuccessNotifications,
        notifyOnTopupFailure: d.enableFailureNotifications,
      }),
    ]);
  });

  const isSuccess = isSuccessEmail && isSuccessNotifications;
  const isPending = isPendingEmail || isPendingNotifications;

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/onboarding/verify-email/${email}`);
    }
  }, [isSuccess, email]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Configure notifications
      </CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <div className="text-sm">
          CycleOps keeps an eye on things so you don't have to. We'll email you
          if anything needs your attention.
        </div>
        <Form {...form}>
          <form className="space-y-8" onSubmit={handleSubmit}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Address</FormLabel>
                  <FormControl>
                    <Input placeholder="tom@myspace.com" {...field} autoFocus />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="enableFailureNotifications"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                    <div className="space-y-0.5">
                      <FormLabel>Enable failure notifications</FormLabel>
                      <FormDescription>
                        We'll email you if something goes wrong.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="enableSuccessNotifications"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                    <div className="space-y-0.5">
                      <FormLabel>Enable success notifications</FormLabel>
                      <FormDescription>
                        We'll email you everytime we top up your canisters.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <Button
              loading={isPending}
              disabled={isPending}
              size="lg"
              className="text-lg h-[62px] w-[162px]"
            >
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function VerifyEmail() {
  const { email } = useParams();
  const { data } = useCustomerIsEmailVerifiedQuery({
    refetchInterval: 5000,
    staleTime: 5000,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data === true) {
      navigate("/onboarding/deposit-funds");
    }
  }, [data]);

  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">
        Verify your email
      </CardHeader>
      <CardContent className="min-h-[240px] flex items-center justify-center flex-col gap-8 pt-8 text-center">
        <div className="text-8xl">📨</div>
        <div className="text-lg">
          Please click the verification link in the email we just sent to{" "}
          <strong>{email}</strong>.
        </div>
        <div>Onboarding will continue automatically ✨</div>
        <div>
          <Link to="/onboarding/notifications">Edit email or resend</Link>
        </div>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export function DepositFunds() {
  const { data } = useCycleOpsAccountTextQuery();
  const dfxCommand = `dfx ledger${
    ic.isLocal ? "" : " --ic"
  } transfer --amount 0.1 --memo 0 \\\n${data}`;
  return (
    <Card className="w-full max-w-[640px]">
      <CardHeader className="text-lg border-b pb-5">Payment method</CardHeader>
      <CardContent className="min-h-[240px] flex justify-center flex-col gap-8 pt-8">
        <div className="">
          The default payment method is ICP. To fund your account, send ICP to
          your top-up account address.
        </div>
        <Code copyable={data} children={data} />
        <Code copyable={dfxCommand} children={dfxCommand} />
        <div className="text-sm">
          ICP can be withdrawn any time and is only charged to top-up your
          canisters.
        </div>
        <div className="">
          We recommend sending a small amount of ICP now to get started. You can
          also switch your payment method to the cycles ledger in your account
          settings.
        </div>
        <Link to="/app" className="plain">
          <Button size="lg" className="text-lg h-[62px] w-[162px]">
            Let's go!
          </Button>
        </Link>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}
