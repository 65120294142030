import { Wand2 } from "lucide-react";
import { useMemo } from "react";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/ui-utils";

import useCanisterCreationStore, {
  subnetOptions,
} from "../canister-creation-wizard-store";
import CanisterTable from "../canister-table";
import SubnetTable from "../subnet-table";

export default function SubnetSelection() {
  const subnetSelection = useCanisterCreationStore((s) => s.subnetSelection);
  return (
    <div className="flex flex-col gap-4 text-base font-normal h-full relative">
      <RadioGroup
        value={subnetSelection.strategy}
        onValueChange={(v) =>
          subnetSelection.setStrategy(v as typeof subnetSelection.strategy)
        }
        className="flex flex-col gap-3 text-base font-normal"
      >
        {subnetOptions.map((option) => (
          <div key={option.value} className="flex items-center gap-2">
            <RadioGroupItem value={option.value} id={option.value} />
            <Label htmlFor={option.value}>{option.label}</Label>
          </div>
        ))}
      </RadioGroup>
      <SubnetStrategyContent strategy={subnetSelection.strategy} />
      <Submit />
    </div>
  );
}

function Submit() {
  const subnetSelection = useCanisterCreationStore((s) => s.subnetSelection);
  const machine = useCanisterCreationStore((s) => s.machine);
  const canSubmit = useMemo(() => {
    if (subnetSelection.strategy === "default") return true;
    if (subnetSelection.strategy === "with-canister")
      return !!subnetSelection.selectedCanisterId;
    return !!subnetSelection.selectedSubnetId;
  }, [
    subnetSelection.strategy,
    subnetSelection.selectedSubnetId,
    subnetSelection.selectedCanisterId,
  ]);
  return (
    <Button
      size="lg"
      onClick={machine.next}
      disabled={!canSubmit}
      className={cn(
        "max-w-[200px] h-12 transition-colors border border-transparent duration-300",
        !canSubmit &&
          "bg-background border-muted text-muted-foreground cursor-not-allowed"
      )}
    >
      {canSubmit ? "Continue" : "Continue"}
    </Button>
  );
}

function SubnetStrategyContent({
  strategy,
}: {
  strategy: (typeof subnetOptions)[number]["value"];
}) {
  switch (strategy) {
    case "default":
      return (
        <div className="flex-1 overflow-y-auto border rounded-sm">
          <div className="w-full h-full flex flex-col gap-8 items-center justify-center text-sm text-muted-foreground">
            <Wand2 className="w-12 h-12 stroke-1" />
            <div>
              The protocol will select a subnet for your canister automatically.
            </div>
          </div>
        </div>
      );
    case "with-canister":
      return <CanisterTable />;
    case "specify":
      return <SubnetTable />;
    default:
      return null;
  }
}
