import { Principal } from "@dfinity/principal";
import { useQuery } from "@tanstack/react-query";

import { fetchActor } from "@/lib/candid";

// Fetch

async function fetchCandid(id: string) {
  return fetchActor(Principal.fromText(id));
}

export { fetchCandid };

// Query

function useCanisterCandid(id: string) {
  return useQuery({
    queryKey: ["candid", id],
    queryFn: () => fetchCandid(id),
  });
}

export { useCanisterCandid };
