import { Command } from "lucide-react";
import { ReactNode } from "react";

import { cn } from "@/lib/ui-utils";

interface CanisterCountProps {
  count: number;
  className?: string;
}

export function CommandPillContainer({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "p-3 flex gap-2 items-stretch border-b border-muted/25",
        className
      )}
    >
      {children}
    </div>
  );
}

export function CommandPillList({
  children,
  className,
  canisterCount,
}: {
  children?: ReactNode;
  className?: string;
  canisterCount: number;
}) {
  return (
    <CommandPillContainer className={cn(className)}>
      <CanisterCommand />
      {children}
      <CanisterCount count={canisterCount} />
    </CommandPillContainer>
  );
}

function CommandPill({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "px-3 py-2 bg-muted/25 ring-1 ring-muted/50 rounded-sm flex items-center text-xs font-medium text-muted-foreground cursor-default",
        className
      )}
    >
      {children}
    </div>
  );
}

export function CanisterCount({ count, className }: CanisterCountProps) {
  return (
    <CommandPill className={className}>
      {count} Canister{count !== 1 ? "s" : ""}
    </CommandPill>
  );
}

export function CanisterCommand({ className }: { className?: string }) {
  return (
    <CommandPill className={cn("aspect-square", className)}>
      <Command className="w-3 h-3" />
    </CommandPill>
  );
}

export function CommandLabel({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <CommandPill className={cn("flex items-center gap-2", className)}>
      {children}
    </CommandPill>
  );
}
