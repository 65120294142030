import { Principal } from "@dfinity/principal";
import BigNumber from "bignumber.js";

import {
  CustomerCanisterData as CanisterDataRaw,
  CanisterConfig__1 as CanisterConfigRaw,
  DashboardReducedSharedStatusHistory as CanisterStatsRaw,
  RecentDPSharedStats as CanisterLatestStatsRaw,
  SharedSeriesStatusDatum as CanisterSeriesDatumRaw,
  DefiniteCanisterSettings__1 as CanisterSettingsRaw,
  ReturnableCanisterSearchMetadata as CanisterMetadataRaw,
  LogVisibility,
  MonitoringCanisterType__1,
  QueryStats,
  TopupRule,
} from "common/declarations/cycleops/cycleops.did.d";

import { mapOptional } from "@/lib/ic-utils";

import { ReductionStrategy } from "./reduction";
import { TimeResolution } from "./time-resolution";

// Idiomatic types definitions

/** Idiomatic remap of canister data returned from the backend paginated endpoint. */
interface CanisterData {
  canisterId: Principal;
  config: CanisterConfig;
  latestStats?: CanisterLatestStats;
  seriesStatus: CanisterSeriesStats;
  metadata?: CanisterMetadata;
  monitor: MonitoringCanisterType__1;
}

/** Idiomatic remap of canister config returned from the backend. */
interface CanisterConfig {
  id: Principal;
  topupRule: TopupRule;
  name: string;
  memoryThreshold?: bigint;
  reservedCyclesPercentageThreshold?: bigint;
  createdTimestamp?: bigint;
  monitoringType: MonitoringCanisterType__1;
}

/** Idiomatic remap of time series stats returned from the backend. */
type CanisterSeriesStats = [Date, CanisterSeriesDatum][];

/** Idiomatic remap of time series datum returned from the backend. */
interface CanisterSeriesDatum {
  memorySize?: bigint;
  cycles: bigint;
  queryStats?: QueryStats;
  reservedCycles?: bigint;
  reservedCyclesPct?: number;
}

/** Idiomatic remap of canister running status returned from the backend. */
type CanisterRunningStatus = "stopped" | "stopping" | "running";

/** Idiomatic remap of latest snapshot stats returned from the backend. */
interface CanisterLatestStats {
  status?: CanisterRunningStatus;
  settings?: CanisterSettings;
  idleCyclesBurnedPerDay?: bigint;
  timestamp: bigint;
  moduleHash?: Uint8Array | number[];
  reservedCycles?: bigint;
}

/** Idiomatic remap of canister settings returned from the backend. */
interface CanisterSettings {
  freezingThreshold?: bigint;
  controllers?: Principal[];
  reservedCyclesLimit?: bigint;
  logVisibility?: LogVisibility;
  wasmMemoryLimit?: bigint;
  memoryAllocation?: bigint;
  computeAllocation?: bigint;
}

/** Idiomatic remap of canister metadata returned from the backend. */
interface CanisterMetadata {
  projectName?: string;
  owner: Principal;
  isCanisterNameVerified?: boolean;
  keywordTags: string[];
  isPublic: boolean;
}

export type {
  CanisterData,
  CanisterConfig,
  CanisterSeriesStats,
  CanisterSeriesDatum,
  CanisterLatestStats,
  CanisterSettings,
  CanisterMetadata,
};

// Mapping functions

/** Map canister raw data to idiomatic type. */
function mapCanisterData(input: CanisterDataRaw): CanisterData {
  const [canisterId, config, stats, metadata, monitor] = input;
  return {
    canisterId,
    monitor,
    config: mapCanisterConfig(config),
    latestStats: mapCanisterLatestStats(stats.recentStats[0]),
    seriesStatus: mapCanisterSeriesStats(stats.seriesStatusHistory),
    metadata: mapCanisterMetadata(mapOptional(metadata)),
  };
}

/** Map canister config raw data to idiomatic type. */
function mapCanisterConfig(input: CanisterConfigRaw): CanisterConfig {
  return {
    ...input,
    memoryThreshold: mapOptional(input.memoryThreshold),
    createdTimestamp: mapOptional(input.createdTimestamp),
    reservedCyclesPercentageThreshold: mapOptional(
      input.reservedCyclesPercentageThreshold
    ),
  };
}

/** Map canister latest stats to idiomatic type. */
function mapCanisterLatestStats(
  input?: CanisterLatestStatsRaw
): CanisterLatestStats | undefined {
  if (!input) return input;
  const status = mapCanisterRunningStatus(mapOptional(input.status));
  const settings = mapCanisterSettings(mapOptional(input.settings));
  return {
    timestamp: input.timestamp,
    status,
    settings,
    reservedCycles: mapOptional(input.reserved_cycles),
    idleCyclesBurnedPerDay: mapOptional(input.idle_cycles_burned_per_day),
    moduleHash: mapOptional(input.module_hash),
  };
}

/** Map canister series status to idiomatic type. */
function mapCanisterSeriesStats(
  input: [bigint, CanisterSeriesDatumRaw][]
): CanisterSeriesStats {
  return input.map(([timestamp, datum]) => [
    new Date(Number(timestamp) / 1e6),
    mapCanisterSeriesDatum(datum),
  ]);
}

/** Map canister series datum to idiomatic type. */
function mapCanisterSeriesDatum(
  input: CanisterSeriesDatumRaw
): CanisterSeriesDatum {
  return {
    ...input,
    memorySize: mapOptional(input.memory_size),
    queryStats: mapOptional(input.query_stats),
    reservedCycles: mapOptional(input.reserved_cycles),
  };
}

/** Map canister running status to idiomatic type. */
function mapCanisterRunningStatus(
  input?: CanisterLatestStatsRaw["status"][0]
): CanisterRunningStatus | undefined {
  if (!input) return input;
  if ("stopped" in input) return "stopped";
  if ("stopping" in input) return "stopping";
  if ("running" in input) return "running";
  throw new Error("Invalid canister running status");
}

/** Map canister settings to idiomatic type. */
function mapCanisterSettings(
  input?: CanisterSettingsRaw
): CanisterSettings | undefined {
  if (!input) return input;
  return {
    freezingThreshold: mapOptional(input.freezing_threshold),
    controllers: input.controllers,
    reservedCyclesLimit: mapOptional(input.reserved_cycles_limit),
    logVisibility: mapOptional(input.log_visibility),
    wasmMemoryLimit: mapOptional(input.wasm_memory_limit),
    memoryAllocation: mapOptional(input.memory_allocation),
    computeAllocation: mapOptional(input.compute_allocation),
  };
}

/** Map canister metadata to idiomatic type. */
function mapCanisterMetadata(
  input?: CanisterMetadataRaw
): CanisterMetadata | undefined {
  if (!input) return input;
  return {
    ...input,
    isCanisterNameVerified: mapOptional(input.isCanisterNameVerified),
    projectName: mapOptional(input.projectName),
  };
}

export { mapCanisterData };

// Insights

interface InsightConfig<Status, T, R = T> {
  selector: (status: Status) => T;
  timeResolution: TimeResolution;
  reduction: ReductionStrategy<T, R>;
}

export type { InsightConfig };
