import { Principal } from "@dfinity/principal";
import { PlayIcon, SquareIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  useCanisterStatusQuery,
  useStartCanisterMutation,
  useStopCanisterMutation,
} from "@/hooks/queries/canister-management";

interface CanisterStatusProps {
  canisterIdString: string;
}

export default function CanisterStatus({
  canisterIdString,
}: CanisterStatusProps) {
  const canisterId = Principal.fromText(canisterIdString);
  const { data: status, isLoading } = useCanisterStatusQuery(canisterId);
  const startMutation = useStartCanisterMutation();
  const stopMutation = useStopCanisterMutation();

  const isRunning = "running" in (status?.status ?? {});
  const isStopped = "stopped" in (status?.status ?? {});

  const handleToggle = () => {
    if (isRunning) {
      stopMutation.mutate(canisterId);
    } else if (isStopped) {
      startMutation.mutate(canisterId);
    }
  };

  const getStatusText = () => {
    if (isLoading) return "Loading...";
    if (isRunning) return "Running";
    if (isStopped) return "Stopped";
    return "Unknown";
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Status</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <div
            className={`h-3 w-3 rounded-full ${
              isRunning
                ? "bg-green-500"
                : isStopped
                ? "bg-red-500"
                : "bg-gray-500"
            }`}
          />
          <span className="capitalize">{getStatusText()}</span>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={handleToggle}
          disabled={isLoading || (!isRunning && !isStopped)}
          loading={startMutation.isPending || stopMutation.isPending}
          className="self-start"
        >
          {isRunning ? (
            <>
              <SquareIcon className="h-4 w-4 mr-2" />
              Stop
            </>
          ) : (
            <>
              <PlayIcon className="h-4 w-4 mr-2" />
              Start
            </>
          )}
        </Button>
      </CardContent>
    </Card>
  );
}
