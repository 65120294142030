import { Principal } from "@dfinity/principal";
import BigNumber from "bignumber.js";
import { BatteryCharging, Copy, RefreshCw } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

import { InputNumber } from "@/components/input-number";
import { TokenAmount } from "@/components/token-amount";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  useCustomerPaymentConfQuery,
  usePaymentMethodQuery,
  useTopupCostFnQuery,
} from "@/hooks/queries/customer";
import {
  useCustomerCyclesLedgerAllowanceQuery,
  useCustomerCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { useIsTeamAdminQuery } from "@/hooks/queries/team";
import {
  useBatchCanisterTopupMutation,
  useMintingLimitBreakFnQuery,
} from "@/hooks/queries/top-up";
import { mapOptional } from "@/lib/ic-utils";
import { cn, copy, formatFloat } from "@/lib/ui-utils";

import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";
import {
  CanistersCommandDialog,
  CanistersCommandDialogFooter,
} from "./command-dialog";

interface TopupCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
}

export const useTopupCommand = create<TopupCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setCanisterId: (canisterId) => set({ canisterId }),
}));

function CustomerBalanceICP() {
  const query = useCustomerICPBalanceQuery();
  const [loading, setLoading] = useState(false);

  function refetch() {
    if (loading) return;
    query.refetch();
  }

  useEffect(() => {
    if (query.isRefetching) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [query.isRefetching]);

  return (
    <div className="flex items-center gap-2 text-lg">
      <Button
        variant="ghost"
        size="icon"
        className="w-8 h-8"
        disabled={loading}
        onClick={refetch}
      >
        <RefreshCw
          className={cn(
            "transition-all ease-in-out w-4 h-4",
            loading && "animate-spin opacity-50"
          )}
        />
      </Button>
      <TokenAmount amount={query.data?.e8s} symbol="ICP" />
    </div>
  );
}

function CustomerBalanceCycles() {
  const query = useCustomerCyclesLedgerBalanceQuery();
  const [loading, setLoading] = useState(false);

  function refetch() {
    if (loading) return;
    query.refetch();
  }

  useEffect(() => {
    if (query.isRefetching) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [query.isRefetching]);

  return (
    <div className="flex items-center gap-2">
      <TokenAmount
        amount={query.data}
        symbol="TC"
        decimals={12}
        loading={query.data === undefined}
      />
      <Button
        variant="ghost"
        size="icon"
        className="w-8 h-8"
        disabled={loading}
        onClick={refetch}
      >
        <RefreshCw
          className={cn(
            "transition-all ease-in-out w-4 h-4",
            loading && "animate-spin opacity-50"
          )}
        />
      </Button>
    </div>
  );
}

function CustomerAllowanceCycles() {
  const query = useCustomerCyclesLedgerAllowanceQuery();
  const [loading, setLoading] = useState(false);

  function refetch() {
    if (loading) return;
    query.refetch();
  }

  useEffect(() => {
    if (query.isRefetching) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [query.isRefetching]);

  return (
    <div className="flex items-center gap-2 text-lg">
      <Button
        variant="ghost"
        size="icon"
        className="w-8 h-8"
        disabled={loading}
        onClick={refetch}
      >
        <RefreshCw
          className={cn(
            "transition-all ease-in-out w-4 h-4",
            loading && "animate-spin opacity-50"
          )}
        />
      </Button>
      <TokenAmount
        amount={query.data?.allowance}
        symbol="TC"
        decimals={12}
        loading={query.data === undefined}
      />
    </div>
  );
}

function CopyableAddress({
  address,
  loading,
}: {
  address?: string;
  loading?: boolean;
}) {
  function handleCopy() {
    if (!address) return;
    copy(address);
  }
  return (
    <div
      className="flex items-center gap-2 relative cursor-pointer"
      onClick={handleCopy}
    >
      <Input value={address} readOnly className="cursor-pointer px-2 text-xs" />
      {loading ? (
        <Skeleton className="absolute left-2 inset-y-2 w-8" />
      ) : (
        <Button
          variant="outline"
          size="icon"
          className="absolute right-1 inset-y-1 rounded-[2px] h-8 w-8"
        >
          <Copy className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
}

function CyclesAddress() {
  const query = useCustomerPaymentConfQuery();
  const address = (() => {
    if (!query.data) return undefined;
    const account = mapOptional(query.data.cyclesAccount);
    if (!account) return undefined;
    return account.account.owner.toText();
  })();
  return <CopyableAddress address={address} loading={query.isLoading} />;
}

function CyclesDetails() {
  return (
    <div className="flex items-center justify-between gap-2">
      <Label className="text-base">Cycles Allowance</Label>
      <div className="flex items-center">
        <CustomerAllowanceCycles />
      </div>
    </div>
  );
}

function ICPDetails() {
  return (
    <div className="flex items-center justify-between gap-2">
      <Label className="text-base">ICP Balance</Label>
      <div className="flex items-center">
        <CustomerBalanceICP />
      </div>
    </div>
  );
}

function PaymentMethodDetails() {
  const method = usePaymentMethodQuery();
  if (!method.data) return <Skeleton className="h-4 w-10" />;
  if (method.data === "icp") return <ICPDetails />;
  return <CyclesDetails />;
}

function Cost({ tc, canisterCount }: { tc?: number; canisterCount: number }) {
  const { data: costFn } = useTopupCostFnQuery();
  const cost = costFn?.({ tc, numCanisters: canisterCount }) ?? undefined;

  return (
    <div className="flex items-center justify-between gap-2">
      <Label className="text-base">
        Cost
        {canisterCount > 1 && (
          <span className="text-sm text-normal ml-1 text-muted-foreground">
            (Total)
          </span>
        )}
      </Label>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="text-lg text-nowrap overflow-hidden overflow-ellipsis flex items-center">
            {formatFloat(cost?.amount ?? 0)} {cost?.unit}
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <span className="">
            {cost?.amount} {cost?.unit}
          </span>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}

export function TopupCommandDialog() {
  const { isOpen, close, canisterId, setCanisterId } = useTopupCommand();
  const selectedCanisters = useSelectedCanisters();
  const mutation = useBatchCanisterTopupMutation();
  const [toastId, setToastId] = useState<string | undefined>(undefined);
  const mintingLimitFn = useMintingLimitBreakFnQuery();
  const isAdmin = useIsTeamAdminQuery();
  
  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];

  const canisterCount = canisterIds.length;

  const [tc, setTC] = useState<number>();
  const requests = useMemo(
    () =>
      tc
        ? canisterIds.map((id) => ({
            canisterId: id,
            topupAmount: {
              cycles: BigInt(
                new BigNumber(tc!).times(1e12).integerValue().toNumber()
              ),
            },
          }))
        : [],
    [tc, canisterIds]
  );
  const mintingLimitExceeded = useMemo(
    () => mintingLimitFn.data?.(requests) ?? false,
    [tc, mintingLimitFn]
  );

  function handleTopup() {
     if (!isAdmin) {
      toast('You are not allowed to top up manually. Only admins of the team can do that.');
    } else {
      mutation.mutate(requests, {
        onSettled: () => {
          toast.dismiss(toastId);
        },
        onSuccess: () => {
          setCanisterId(undefined);
          close();
        },
      });
    }
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      // If closing while mutation is in progress, show the loading toast
      const id = toast.loading(
        `Topping up ${canisterCount} canister${
          canisterCount !== 1 ? "s" : ""
        }...`
      );
      setToastId(id as string);
    }
    if (!open) {
      close();
    }
  }

  return (
    <CanistersCommandDialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CommandPillList canisterCount={canisterCount}>
        <CommandLabel>
          <BatteryCharging className="w-4 h-4" /> Top-up Now
        </CommandLabel>
      </CommandPillList>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex items-center justify-between gap-2">
          <Label className="text-base">
            Topup Amount
            {canisterCount > 1 && (
              <span className="text-sm text-normal ml-1 text-muted-foreground">
                (Per Canister)
              </span>
            )}
          </Label>
          <div className="flex gap-1">
            <InputNumber
              step={0.5}
              affix="TC"
              autoFocus
              className="rounded-r-none text-lg"
              value={tc}
              onChange={(e) => setTC(e.currentTarget.valueAsNumber)}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  handleTopup();
                }
              }}
            />
          </div>
        </div>
        <Cost canisterCount={canisterCount} tc={tc} />
        <PaymentMethodDetails />
      </div>
      <CanistersCommandDialogFooter>
        <Button
          type="submit"
          className="text-base"
          loading={mutation.isPending}
          onClick={handleTopup}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              handleTopup();
            }
          }}
          disabled={mintingLimitExceeded}
        >
          Top up now
        </Button>
        {mintingLimitExceeded === true && (
          <Alert
            variant="destructive"
            className="text-xs p-1 px-2 leading-none rounded-sm flex items-center bg-red-600/5 border-red-400/25"
          >
            This topup would exceed protocol minting limits. Please try a
            smaller amount.
          </Alert>
        )}
      </CanistersCommandDialogFooter>
    </CanistersCommandDialog>
  );
}
