import { Minus, Plus } from "lucide-react";
import { forwardRef, useRef, useImperativeHandle } from "react";

import { cn } from "@/lib/ui-utils";

import { Button } from "./ui/button";
import { Input } from "./ui/input";

export interface NumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  step?: number;
  variant?: "outline" | "secondary" | "default";
  affix?: string;
}

const InputNumber = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ className, step = 0.1, variant = "outline", affix, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    function handleIncrement() {
      if (!inputRef.current) return;
      inputRef.current.stepUp();
      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }

    function handleDecrement() {
      if (!inputRef.current) return;
      inputRef.current.stepDown();
      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === "ArrowUp") {
        handleIncrement();
        event.preventDefault();
      } else if (event.key === "ArrowDown") {
        handleDecrement();
        event.preventDefault();
      }
    }

    return (
      <div className={cn("flex gap-2", className)}>
        <Button
          size="icon"
          type="button"
          variant={variant}
          className={cn("w-10 h-10")}
          onClick={handleDecrement}
          tabIndex={-1}
        >
          <Minus className="w-4 h-4" />
        </Button>
        <div className="relative">
          <Input
            className="text-lg px-2 h-10 min-w-20 w-full max-w-32 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            min={0}
            step={step}
            {...props}
            ref={inputRef}
            onKeyDownCapture={handleKeyDown}
          />
          {affix && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-sm leading-none font-medium text-muted-foreground/50 pointer-events-none">
              {affix}
            </div>
          )}
        </div>
        <Button
          size="icon"
          type="button"
          variant={variant}
          className={cn("w-10 h-10")}
          onClick={handleIncrement}
          tabIndex={-1}
        >
          <Plus className="w-4 h-4" />
        </Button>
      </div>
    );
  }
);

export { InputNumber };
