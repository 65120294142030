import {
  BatteryCharging,
  CommandIcon,
  Box,
  Tag,
  X,
  Trash2,
  CheckCircle2,
  BatteryWarning,
  ArrowUpCircle,
  Cpu,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";

import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { useRoute } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { KeyCombo } from "../key";
import { useBlackholeStore } from "../pages/blackhole-upgrade";
import { Button } from "../ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import {
  CanisterCommand,
  CanisterCount,
  CommandPillContainer,
} from "./commands/canister-count";
import {
  DeleteCommandDialog,
  useDeleteCommand,
} from "./commands/delete-command";
import {
  MemoryAlertCommandDialog,
  useMemoryAlertCommand,
} from "./commands/memory-alert-command";
import {
  ProjectCommandDialog,
  useProjectCommand,
} from "./commands/project-command";
import {
  ReservedCyclesAlertCommandDialog,
  useReservedCyclesAlertCommand
} from "./commands/reserved-cycles-alert-command";
import { TagCommandDialog, useTagCommand } from "./commands/tag-command";
import { TopupCommandDialog, useTopupCommand } from "./commands/topup-command";
import {
  TopupRuleCommandDialog,
  useTopupRuleCommand,
} from "./commands/topup-rule-command";
import {
  useVerifyCommand,
  VerifyCommandDialog,
} from "./commands/verify-command";
import { useSelectedCanisters } from "./table/context-menu";

function ProjectDropdownMenu({ className }: { className?: string }) {
  const { open } = useProjectCommand();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
          onClick={() => open()}
        >
          <Box className="w-3 h-3" />
          Move to project
        </Button>
      </TooltipTrigger>
      <TooltipContent className="flex gap-2 text-xs items-center">
        Move to project <KeyCombo keys={["shift", "P"]} />
      </TooltipContent>
    </Tooltip>
  );
}

function TagsDropdownMenu({ className }: { className?: string }) {
  const { open } = useTagCommand();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
          onClick={() => open()}
        >
          <Tag className="w-3 h-3" />
          Add tags
        </Button>
      </TooltipTrigger>
      <TooltipContent className="flex gap-2 text-xs items-center">
        Add tags <KeyCombo keys={["shift", "T"]} />
      </TooltipContent>
    </Tooltip>
  );
}

function SelectedCanisterCommandPrompt({ className }: { className?: string }) {
  const table = useCanisterTable();
  const count = Object.values(table.getState().rowSelection).filter(
    (x) => !!x
  ).length;
  const visible = count > 0;

  function handleClear() {
    table.setRowSelection({});
  }

  const { open } = useCanisterCommand();

  return (
    <div
      className={cn(
        "flex justify-center w-full py-4 opacity-0 translate-y-full",
        "pointer-events-none",
        visible &&
          "translate-y-0 opacity-100 transition-all duration-200 ease-out",
        className
      )}
    >
      <div
        className={cn(
          "bg-background border border-border rounded-md p-2 px-4 pointer-events-auto flex gap-3 items-center"
        )}
      >
        <div className="rounded-sm border-dashed border flex items-center overflow-hidden">
          <div className="px-3 py-1 h-full font-medium">{count} selected</div>
          <Button
            variant="ghost"
            size="sm"
            className="text-muted-foreground border-l border-border border-dashed rounded-none hover:bg-muted/50"
            onClick={handleClear}
          >
            <X className="w-3 h-3" />
          </Button>
        </div>
        <div className="h-4/5 w-0 border-l-2 border-border" />
        <TagsDropdownMenu />
        <ProjectDropdownMenu />
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="secondary"
              className="gap-3 gap-3 bg-muted/50 border-border border"
              onClick={() => open()}
            >
              <CommandIcon className="w-3 h-3" />
              Command
            </Button>
          </TooltipTrigger>
          <TooltipContent className="flex gap-2 text-xs items-center">
            Command <KeyCombo keys={["command", "K"]} />
          </TooltipContent>
        </Tooltip>
        <CanisterCommandDialog />
        <TagCommandDialog />
        <ProjectCommandDialog />
        <DeleteCommandDialog />
        <TopupCommandDialog />
        <TopupRuleCommandDialog />
        <VerifyCommandDialog />
        <MemoryAlertCommandDialog />
        <ReservedCyclesAlertCommandDialog />
      </div>
    </div>
  );
}

interface CanisterCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export const useCanisterCommand = create<CanisterCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
}));

function TagsCommandListItem() {
  const { open } = useTagCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      className="gap-3"
      onSelect={() => {
        toggle();
        open();
      }}
    >
      <Tag className="w-3 h-3" />
      <span>Change tags</span>
    </CommandItem>
  );
}

function ProjectCommandListItem() {
  const { open } = useProjectCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      className="gap-3"
      onSelect={() => {
        toggle();
        open();
      }}
    >
      <Box className="w-3 h-3" />
      <span>Move to project</span>
    </CommandItem>
  );
}

function DeleteCommandListItem() {
  const { open } = useDeleteCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      className={cn(
        "gap-2 text-destructive",
        "[&[aria-selected]]:text-destructive",
        "[&[aria-selected]]:bg-destructive/10",
        "[&[aria-selected]]:ring-1 [&[aria-selected]]:ring-destructive/30"
      )}
      onSelect={() => {
        toggle();
        open();
      }}
    >
      <Trash2 className="w-3 h-3" />
      <span>Delete</span>
    </CommandItem>
  );
}

function TopupCommandListItem() {
  const { open } = useTopupCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      className="gap-3"
      onSelect={() => {
        toggle();
        open();
      }}
    >
      <BatteryCharging className="w-3 h-3" />
      <span>Top up</span>
    </CommandItem>
  );
}

function TopupRuleCommandListItem() {
  const { open } = useTopupRuleCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      onSelect={() => {
        open();
        toggle();
      }}
      className="gap-2"
    >
      <BatteryWarning className="w-4 h-4" />
      Set top-up rule
    </CommandItem>
  );
}

function MemoryAlertCommandListItem() {
  const { open } = useMemoryAlertCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      onSelect={() => {
        open();
        toggle();
      }}
      className="gap-2"
    >
      <Cpu className="w-4 h-4" />
      Set memory alert
    </CommandItem>
  );
}

function ReservedCyclesAlertCommandListItem() {
  const { open } = useReservedCyclesAlertCommand();
  const { toggle } = useCanisterCommand();
  return (
    <CommandItem
      onSelect={() => {
        open();
        toggle();
      }}
      className="gap-2"
    >
      <BatteryWarning className="w-4 h-4" />
      Set reserved cycles alert
    </CommandItem>
  );
}

function VerifyCommandListItem() {
  const { open } = useVerifyCommand();
  const { toggle } = useCanisterCommand();
  const table = useCanisterTable();

  const selectedCanisters = Object.entries(table.getState().rowSelection)
    .filter(([_, x]) => !!x)
    .map(([x]) => x);
  const areCanistersPending = selectedCanisters.some(
    (x) => table.getRowModel().rows[Number(x)]?.original.status === "pending"
  );

  if (!areCanistersPending) return null;

  return (
    <CommandItem
      className="gap-3"
      onSelect={() => {
        toggle();
        open();
      }}
    >
      <CheckCircle2 className="w-3 h-3" />
      <span>Finish canister setup</span>
    </CommandItem>
  );
}

function BlackholeUpgradeCommandListItem() {
  const selectedCanisters = useSelectedCanisters();
  const { setSelectedCanisters } = useBlackholeStore();
  const navigate = useNavigate();
  const route = useRoute();
  const canisters = useCanisterTableQuery();

  const elligibleCanisters = canisters.data?.filter((canister) => {
    const cans = selectedCanisters.length ? selectedCanisters : [];
    if (!cans.map((x) => x.toText()).includes(canister.id.toText()))
      return false;
    return (
      "blackhole" in canister.monitoringMechanism &&
      canister.monitoringMechanism.blackhole === 1n
    );
  });

  function handle() {
    setSelectedCanisters(elligibleCanisters?.map((x) => x.id) ?? []);
    navigate(route("blackhole-upgrade"));
  }

  if (!elligibleCanisters?.length) return null;

  return (
    <CommandItem
      className="gap-3"
      onSelect={() => {
        handle();
      }}
    >
      <ArrowUpCircle className="w-3 h-3" />
      <span>Upgrade blackhole</span>
    </CommandItem>
  );
}

function CanisterCommandDialog() {
  const table = useCanisterTable();
  const { isOpen, toggle } = useCanisterCommand();

  const selectedCanisters = Object.entries(table.getState().rowSelection)
    .filter(([_, x]) => !!x)
    .map(([x]) => x);
  const selectedCount = selectedCanisters.length;

  return (
    <CommandDialog open={isOpen} onOpenChange={toggle}>
      <CommandPillContainer>
        <CanisterCommand />
        <CanisterCount count={selectedCount} />
      </CommandPillContainer>
      <CommandInput placeholder="Type a command or search" />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandList>
            <VerifyCommandListItem />
            <BlackholeUpgradeCommandListItem />
            <TopupCommandListItem />
            <TagsCommandListItem />
            <ProjectCommandListItem />
            <TopupRuleCommandListItem />
            <MemoryAlertCommandListItem />
            <ReservedCyclesAlertCommandListItem />
            <DeleteCommandListItem />
          </CommandList>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}

export { SelectedCanisterCommandPrompt };
