import { Principal } from "@dfinity/principal";
import { BatteryWarning } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

import {
  TopUpRuleFormRoot,
  TopUpRuleFormFields,
  TopUpRuleFormErrors,
  TopUpRuleFormSubmit,
  TopUpRuleFormValues,
} from "@/hooks/forms/top-up-rule";
import { useCanisterTopupRuleMutation } from "@/hooks/queries/canisters";

import { useSelectedCanisters } from "../table/context-menu";
import { CommandLabel, CommandPillList } from "./canister-count";
import {
  CanistersCommandDialog,
  CanistersCommandDialogFooter,
} from "./command-dialog";

interface TopupRuleCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
}

export const useTopupRuleCommand = create<TopupRuleCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setCanisterId: (canisterId) => set({ canisterId }),
}));

export function TopupRuleCommandDialog() {
  const { isOpen, canisterId, setCanisterId, close } = useTopupRuleCommand();
  const [toastId, setToastId] = useState<string | undefined>(undefined);
  const selectedCanisters = useSelectedCanisters();
  const mutation = useCanisterTopupRuleMutation();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];
  const canisterCount = canisterIds.length;

  function handleSubmit(values: TopUpRuleFormValues) {
    mutation.mutate(
      {
        canisterIds,
        rule: {
          threshold: BigInt(values.threshold * 1e12),
          method:
            values.method === "by_amount"
              ? { by_amount: BigInt(values.amount * 1e12) }
              : { to_balance: BigInt(values.amount * 1e12) },
        },
      },
      {
        onSettled: () => {
          toast.dismiss(toastId);
        },
        onSuccess: () => {
          close();
          setCanisterId(undefined);
        },
      }
    );
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      const id = toast.loading(
        `Updating top-up rule for ${canisterCount} canister${
          canisterCount !== 1 ? "s" : ""
        }...`
      );
      setToastId(id as string);
    }
    if (!open) {
      close();
    }
  }

  return (
    <CanistersCommandDialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CommandPillList canisterCount={canisterCount}>
        <CommandLabel>
          <BatteryWarning className="w-4 h-4" />
          Set top-up rule
        </CommandLabel>
      </CommandPillList>

      <TopUpRuleFormRoot
        onSubmit={handleSubmit}
        loading={mutation.isPending}
        className="flex flex-col"
      >
        <div className="p-4 flex-1">
          <TopUpRuleFormFields />
          <TopUpRuleFormErrors />
        </div>
        <CanistersCommandDialogFooter>
          <TopUpRuleFormSubmit className="text-base">Save</TopUpRuleFormSubmit>
        </CanistersCommandDialogFooter>
      </TopUpRuleFormRoot>
    </CanistersCommandDialog>
  );
}
