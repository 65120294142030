import { useQuery } from "@tanstack/react-query";

interface CMCMetricsResponse {
  timestampInMs?: number;
  cmc_limiter_cycles?: string;
  cmc_cycles_limit?: string;
  cmc_icp_xdr_conversion_rate?: number;
}

export interface CMCMetricsData {
  remainingCycles?: bigint;
  cyclesLimit?: bigint;
  timestamp?: number;
  icpXdrRate?: number;
}

async function fetchCMCMetrics(): Promise<CMCMetricsData> {
  try {
    const response = await fetch("https://api.cycleops.dev/metrics/cmc/latest");

    if (!response.ok) {
      console.warn("Failed to fetch CMC metrics, returning empty data");
      return {};
    }

    const data = (await response.json()) as CMCMetricsResponse;

    const cyclesLimit = (() => {
      if (!data.cmc_cycles_limit) return undefined;
      return BigInt(data.cmc_cycles_limit);
    })();
    const cyclesMintedLastHour = (() => {
      if (!data.cmc_limiter_cycles) return undefined;
      return BigInt(data.cmc_limiter_cycles);
    })();
    const remainingCycles = (() => {
      if (!cyclesLimit || !cyclesMintedLastHour) return undefined;
      return cyclesLimit - cyclesMintedLastHour;
    })();

    return {
      remainingCycles,
      cyclesLimit,
      timestamp: data.timestampInMs,
      icpXdrRate: data.cmc_icp_xdr_conversion_rate,
    };
  } catch (error) {
    console.warn("Error fetching CMC metrics:", error);
    return {};
  }
}

export function useCMCMetricsQuery() {
  return useQuery({
    queryKey: ["cmc-metrics"],
    queryFn: fetchCMCMetrics,
    refetchInterval: 60000, // Refresh every minute
    staleTime: 60000, // Keep data for 1 minute
  });
}
